const boxShadowStyles = {
  borderBottom: 'inset 0px -1px 0px #EEEEEE',
  borderTop: 'inset 0px 1px 0px #EEEEEE',
}

type boxShadowType = keyof typeof boxShadowStyles

export const boxShadow = (type: boxShadowType) => ({
  boxShadow: boxShadowStyles[type],
})
