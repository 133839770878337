import { mediaQuery } from '@styles/media-query'
import { useMemo } from 'react'

export type Spaces = number | number[] | undefined

export interface UseSpaceProps {
  spaceTop: Spaces
  spaceBottom: Spaces
}

export const useSpace = ({ spaceTop, spaceBottom }: UseSpaceProps) => {
  const marginTop = useMemo(() => {
    if (!spaceTop) return {}

    if (typeof spaceTop === 'number') {
      return {
        marginTop: spaceTop + 'px',
      }
    }

    const [mobile, tablet, desktop] = spaceTop
    return {
      marginTop: mobile + 'px',
      [mediaQuery.TABLET]: {
        marginTop: tablet + 'px',
      },
      [mediaQuery.DESKTOP]: {
        marginTop: desktop + 'px',
      },
    }
  }, [spaceTop])

  const marginBottom = useMemo(() => {
    if (!spaceBottom) return {}

    if (typeof spaceBottom === 'number') {
      return {
        marginBottom: spaceBottom + 'px',
      }
    }

    const [mobile, tablet, desktop] = spaceBottom
    return {
      marginBottom: mobile + 'px',
      [mediaQuery.TABLET]: {
        marginBottom: tablet + 'px',
      },
      [mediaQuery.DESKTOP]: {
        marginBottom: desktop + 'px',
      },
    }
  }, [spaceBottom])

  return { marginTop, marginBottom }
}
