import { forwardRef } from 'react'
import { LabelLarge, LabelMedium, LabelSmall } from 'baseui/typography'
import { TypographyProps } from './typography.interface'
import { colors } from '@styles/colors'

export const LabelL = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <LabelLarge
      font="LabelL"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </LabelLarge>
  )
)

export const LabelM = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <LabelMedium
      font="LabelM"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </LabelMedium>
  )
)

export const LabelS = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <LabelSmall
      font="LabelS"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </LabelSmall>
  )
)

export const LabelXS = forwardRef<HTMLDivElement, TypographyProps>(
  (
    {
      children,
      weight,
      align,
      color = colors.gray700,
      whiteSpace,
      $style,
      ...props
    },
    ref
  ) => (
    <LabelSmall
      font="LabelXS"
      $style={{ fontWeight: weight, textAlign: align, whiteSpace, ...$style }}
      color={color}
      {...props}
      ref={ref}
    >
      {children}
    </LabelSmall>
  )
)
