import {
  borderColor,
  borderRadius as radius,
  borderStyle,
  borderWidth,
} from 'polished'
import { ColorType } from './colors'

export const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const borderRadius = (size: number) => ({
  ...radius('top', size + 'px'),
  ...radius('bottom', size + 'px'),
})

export const rounded = (size: number) => ({
  ...radius('top', size + 'px'),
  ...radius('bottom', size + 'px'),
})

export const border = (
  color: ColorType,
  width: (string | number | void | null)[] = ['1px'],
  style: string = 'solid'
) => ({
  ...borderWidth(...width),
  ...borderStyle(style),
  ...borderColor(color),
})

export const transition = (
  property: string = 'all',
  duration: string = '0.3s',
  timingFunction: string = 'ease-out'
) => ({
  transitionProperty: property,
  transitionDuration: duration,
  transitionTimingFunction: timingFunction,
})
