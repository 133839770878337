export const Routes = {
  ROOT: '/',
  HOME: '/home',

  // 인증
  AUTH_LOGIN: '/auth/login',
  AUTH_SIGNUP: '/auth/signup',
  AUTH_PASSWORD_RESET: '/auth/password-reset',
  AUTH_FORBIDDEN: '/auth/forbidden',
  AUTH_BLOCKED: '/auth/blocked',

  // 검사지 업로드
  UPLOAD: '/upload',
  UPLOAD_DETAILS: '/upload/:examinationId',
  UPLOAD_COMPLETE: '/upload/complete',

  // 환자 관련 페이지
  PATIENT_HOME: '/patient/home',
  PATIENT_EXAMINATIONS: '/patient/examinations',
  PATIENT_EXAMINATIONS_DETAIL: '/patient/examinations/:id',
  PATIENT_EXAMINATIONS_EDIT: '/patient/examinations/:id/edit',
  PATIENT_MEDICAL_INFO: '/patient/medical-info',
  PATIENT_INFORMATION_SET_DISEASES: '/patient/information/set-diseases',
  PATIENT_INFORMATION_SET_RELATIONSHIP: '/patient/information/set-relationship',

  // 의료인 관련 페이지
  DOCTOR_HOME: '/doctor/home',
  DOCTOR_PROFILE: '/doctor/profile',
  DOCTOR_REGISTRATION: '/doctor/registration',
  DOCTOR_REGISTRATION_COMPLETE: '/doctor/registration/complete',
  DOCTOR_EXAMINATIONS: '/doctor/examinations',
  DOCTOR_INTERPRETATIONS: '/doctor/interpretations',
  DOCTOR_INTERPRETATIONS_DETAIL: '/doctor/interpretations/:examinationId',
  DOCTOR_INTERPRETATION_WRITE_COMPLETE: '/doctor/interpretations/complete',
  DOCTOR_EARNINGS: '/doctor/earnings',
  DOCTOR_EARNINGS_DETAIL: '/doctor/earnings/:settlementId',

  // 고객센터
  SUPPORT_NOTICE: '/support/notice',
  SUPPORT_NOTICE_DETAIL: '/support/notice/:id',
  SUPPORT_INQUIRY_WRITE: '/support/inquiry/write',
  SUPPORT_FAQ: '/support/faq',

  // 설정
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_ACCOUNT_WITHDRAWAL: '/settings/account/withdrawal',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_INQUIRIES: '/settings/inquiries',
  SETTINGS_INQUIRIES_DETAIL: '/settings/inquiries/:id',

  // 기타
  LEGAL_PRIVACY_POLICY: '/legal/privacy-policy',
  LEGAL_TERMS_OF_SERVICE: '/legal/terms-of-service',
  LEGAL_TERMS_OF_USE_FOR_PAID_SERVICES: '/legal/terms-of-use-for-paid-services',

  // 노션
  NOTION_EARNINGS_INSTRUCTION:
    'https://tesser.notion.site/a9d9af8c69e04bbaa72331e4e4eb6976',
  NOTION_INTERPRETATION_GUIDE:
    'https://tesser.notion.site/8c610d742ae24273b80d400d5cdf096c',

  // 앱스토어
  APP_STORE:
    'https://apps.apple.com/kr/app/%EB%8B%A5%ED%84%B0%EC%98%A8%ED%86%A8/id1610327897',
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=kr.co.tesser.ontol',
  TESSER: 'https://tesser.co.kr',
  TESSER_CONTACT: 'https://www.tesser.co.kr/contact',

  /** 이전 온톨 다운로드 버튼 링크 */
  // APPSTORE_AUTO_REDIRECT: 'https://redirect.ontol.com/go_web.html',
  APPSTORE_DYNAMIC_LINK: 'https://link.ontol.com/ontol_link',
} as const

export const AdminRoutes = {
  ROOT: '/admin',
  HOME: '/admin/home',
  AUTH_LOGIN: '/admin/auth/login',
  USERS: '/admin/users',
  USERS_DETAIL: '/admin/users/:id',
  PATIENTS: '/admin/patients',
  PATIENTS_DETAIL: '/admin/patients/:userId',
  PATIENTS_EXAMINATIONS: '/admin/patients/examinations/:userId',
  PROFESSIONALS: '/admin/professionals',
  PROFESSIONALS_DETAIL: '/admin/professionals/:userId',
  PROFESSIONALS_INTERPRETATIONS: '/admin/professionals/interpretations/:id',
  // Administrators
  ADMINISTRATORS: '/admin/administrators',
  ADMINISTRATORS_DETAIL: '/admin/administrators/:userId',
  // EXAMINATIONS: '/admin/examinations',
  EXAMINATIONS_PENDING: '/admin/examinations/pending',
  EXAMINATIONS_PENDING_DETAIL: '/admin/examinations/pending/:examinationId',
  EXAMINATIONS_INTERPRETING: '/admin/examinations/interpreting',
  EXAMINATIONS_INTERPRETING_SCORE:
    '/admin/examinations/interpreting/score/:interpretationId',
  EXAMINATIONS_INTERPRETING_DETAIL:
    '/admin/examinations/interpreting/:examinationId',
  EXAMINATIONS_DONE: '/admin/examinations/done',
  EXAMINATIONS_DONE_DETAIL: '/admin/examinations/done/:examinationId',
  REGISTRATIONS: '/admin/registrations',
  REGISTRATIONS_DETAIL: '/admin/registrations/:userId',
  INQUIRIES: '/admin/inquiries',
  INQUIRIES_DETAIL: '/admin/inquiries/:id',
  NOTICES: '/admin/notices',
  NOTICES_WRITE: '/admin/notices/write',
  NOTICES_DETAIL: '/admin/notices/:id',
  NOTICES_EDIT: '/admin/notices/edit/:id',
  FAQ: '/admin/faq',
  FAQ_WRITE: '/admin/faq/write',
  FAQ_DETAIL: '/admin/faq/:id',
  FAQ_EDIT: '/admin/faq/edit/:id',
  SETTINGS: '/admin/settings',
  SETTINGS_NEW_ACCOUNT: '/admin/settings/new-account',
  EARNINGS: '/admin/earnings',
  EARNINGS_DETAIL: '/admin/earnings/detail',
  EARNINGS_SETTLEMENT_DETAIL: '/admin/earnings/:settlementId',
}

export type RouteType = (typeof Routes)[keyof typeof Routes]
