import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any
  /** The `Upload` scalar type represents a file upload. */
  Upload: any
}

/** 관리자 권한 */
export enum AdminRole {
  /** 기본 권한 */
  Basic = 'Basic',
  /** 전체 접근 권한 */
  FullAccess = 'FullAccess',
  /** 개인정보 접근 권한 */
  PrivacyAccess = 'PrivacyAccess',
  /** 읽기 전용 */
  ReadOnly = 'ReadOnly',
}

/** [Entity] 관리자 */
export type Administrator = {
  __typename?: 'Administrator'
  /** 승인한 검사지 목록 */
  certificates?: Maybe<Array<Certificate>>
  createdAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  /** 답변 작성한 1대1 문의 목록 */
  inquiries?: Maybe<Array<Inquiry>>
  /** 해석 검수 목록 */
  inspections?: Maybe<Array<Certificate>>
  /** 작성한 공지사항 목록 */
  notices?: Maybe<Array<Notice>>
  /** 관리자 권한 */
  role?: Maybe<AdminRole>
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

export type AdministratorInputType = {
  /** 승인한 검사지 목록 */
  certificates?: InputMaybe<Array<CertificateInputType>>
  createdAt: Scalars['DateTime']
  deletedAt?: InputMaybe<Scalars['DateTime']>
  id: Scalars['String']
  /** 답변 작성한 1대1 문의 목록 */
  inquiries?: InputMaybe<Array<InquiryInputType>>
  /** 해석 검수 목록 */
  inspections?: InputMaybe<Array<CertificateInputType>>
  /** 작성한 공지사항 목록 */
  notices?: InputMaybe<Array<NoticeInputType>>
  /** 관리자 권한 */
  role?: InputMaybe<AdminRole>
  updatedAt: Scalars['DateTime']
  user?: InputMaybe<UserInputType>
  userId: Scalars['String']
}

/** 모두 필수 */
export type ApproveCertificateInput = {
  id: Scalars['String']
}

/** 모두 필수 */
export type ApproveInterpretationInput = {
  id: Scalars['String']
}

/** [Entity] 은행 */
export type Bank = {
  __typename?: 'Bank'
  bankAccounts: Array<BankAccount>
  /** 은행 코드 */
  code: Scalars['String']
  id: Scalars['Int']
  /** 주요 은행 여부 */
  isMajor: Scalars['Boolean']
  /** 은행 이름 */
  name: Scalars['String']
}

/** [Entity] 은행 계좌 */
export type BankAccount = {
  __typename?: 'BankAccount'
  /** 예금주 */
  accountHolder: Scalars['String']
  /** 계좌 번호 */
  accountNumber: Scalars['String']
  /** 은행 정보 */
  bank: Bank
  bankId: Scalars['Int']
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

export type BankAccountInputType = {
  /** 예금주 */
  accountHolder: Scalars['String']
  /** 계좌 번호 */
  accountNumber: Scalars['String']
  /** 은행 정보 */
  bank: BankInputType
  bankId: Scalars['Int']
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: UserInputType
  userId: Scalars['String']
}

export type BankInputType = {
  bankAccounts: Array<BankAccountInputType>
  /** 은행 코드 */
  code: Scalars['String']
  id: Scalars['Int']
  /** 주요 은행 여부 */
  isMajor: Scalars['Boolean']
  /** 은행 이름 */
  name: Scalars['String']
}

/** [Entity] 의료인 증명서 */
export type Certificate = {
  __typename?: 'Certificate'
  /** 증명서와 연결된 첨부 파일 목록 */
  certificateToFiles: Array<CertificateToFile>
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  /** 증명서를 업로드한 의료인 */
  professional: Professional
  professionalId: Scalars['String']
  /** 의료인 승인 거절 사유 */
  rejectionReason?: Maybe<Scalars['String']>
  /** 증명서 승인 상태 */
  status: CertificateStatus
  updatedAt: Scalars['DateTime']
  /** 검수 승인날짜 */
  validatedAt?: Maybe<Scalars['DateTime']>
  /** 증명서를 인증한 관리자 */
  validator?: Maybe<Administrator>
  validatorId?: Maybe<Scalars['String']>
}

export type CertificateInputType = {
  /** 증명서와 연결된 첨부 파일 목록 */
  certificateToFiles: Array<CertificateToFileInputType>
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  /** 증명서를 업로드한 의료인 */
  professional: ProfessionalInputType
  professionalId: Scalars['String']
  /** 의료인 승인 거절 사유 */
  rejectionReason?: InputMaybe<Scalars['String']>
  /** 증명서 승인 상태 */
  status: CertificateStatus
  updatedAt: Scalars['DateTime']
  /** 검수 승인날짜 */
  validatedAt?: InputMaybe<Scalars['DateTime']>
  /** 증명서를 인증한 관리자 */
  validator?: InputMaybe<AdministratorInputType>
  validatorId?: InputMaybe<Scalars['String']>
}

/** 의료인 증명서의 승인 상태를 나타냅니다. */
export enum CertificateStatus {
  /** 증명서 승인 완료 */
  Certified = 'Certified',
  /** 증명서 승인 거부 */
  Rejected = 'Rejected',
  /** 증명서 승인 대기 중 */
  Waiting = 'Waiting',
}

/** [Entity] 의료인 증명서 첨부파일 */
export type CertificateToFile = {
  __typename?: 'CertificateToFile'
  certificate: Certificate
  certificateId: Scalars['String']
  createdAt: Scalars['DateTime']
  file: File
  fileId: Scalars['String']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type CertificateToFileInputType = {
  certificate: CertificateInputType
  certificateId: Scalars['String']
  createdAt: Scalars['DateTime']
  file: FileInputType
  fileId: Scalars['String']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** 모두 필수 */
export type CheckBankAccountInput = {
  /** 예금주 */
  accountHolder: Scalars['String']
  /** 계좌 번호 */
  accountNumber: Scalars['String']
  /** 은행 코드 */
  bankCode: Scalars['String']
}

/** 필수: */
export type CompleteSettlementTransactionInput = {
  id: Scalars['String']
  nextDueDate?: InputMaybe<Scalars['DateTime']>
}

/** 모두 필수 */
export type ConfirmEmailVerificationCodeInput = {
  email: Scalars['String']
  verificationCode: Scalars['String']
}

/** 이메일 인증 코드 확인 Output */
export type ConfirmEmailVerificationCodeOutput = {
  __typename?: 'ConfirmEmailVerificationCodeOutput'
  /** 에러 메시지 */
  error?: Maybe<Scalars['String']>
  /** 성공 여부 */
  ok: Scalars['Boolean']
}

/** 모두 필수 */
export type ConfirmPhoneVerificationCodeInput = {
  phoneNumber: Scalars['String']
  verificationCode: Scalars['String']
}

/** 휴대폰 번호 인증 코드 확인 Output */
export type ConfirmPhoneVerificationCodeOutput = {
  __typename?: 'ConfirmPhoneVerificationCodeOutput'
  /** 에러 메시지 */
  error?: Maybe<Scalars['String']>
  /** 성공 여부 */
  ok: Scalars['Boolean']
}

/** 기본 Output DTO */
export type CoreOutput = {
  __typename?: 'CoreOutput'
  /** 에러 메시지 */
  error?: Maybe<Scalars['String']>
  /** 성공 여부 */
  ok: Scalars['Boolean']
}

/**
 * 필수: email, name, password, promotionSmsNotification, promotionEmailNotification
 *
 * 선택: role (Default: Basic)
 */
export type CreateAdministratorInput = {
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  /** 마케팅 이메일 알림 설정 */
  promotionEmailNotification: Scalars['Boolean']
  /** 마케팅 SMS 알림 설정 */
  promotionSmsNotification: Scalars['Boolean']
  /** 관리자 권한 */
  role?: InputMaybe<AdminRole>
}

/** 모두 필수 */
export type CreateAnswerInput = {
  /** 답변 */
  answer?: InputMaybe<Scalars['String']>
  answererId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
}

/** 모두 필수 */
export type CreateBankAccountInput = {
  /** 예금주 */
  accountHolder: Scalars['String']
  /** 계좌 번호 */
  accountNumber: Scalars['String']
  bankId: Scalars['Int']
  userId: Scalars['String']
}

/** 모두 필수 */
export type CreateCertificateInput = {
  fileIds: Array<Scalars['String']>
  professionalId: Scalars['String']
}

/** 모두 필수 */
export type CreateExaminationInput = {
  /** 파일 객체 리스트 */
  files: Array<Scalars['Upload']>
}

/** 모두 필수 */
export type CreateFaqInput = {
  /** FAQ 카테고리 */
  categoryId: Scalars['Int']
  /** 상세 내용 */
  description: Scalars['String']
  /** 제목 */
  title: Scalars['String']
}

/**
 * 필수: key, originName, size
 *
 * 선택: isPrivate (Default: false)
 */
export type CreateFileInput = {
  /** 비공개 여부 */
  isPrivate?: InputMaybe<Scalars['Boolean']>
  /** 파일 키 (S3 파일 경로) */
  key: Scalars['String']
  /** 원본 파일 이름 */
  originName: Scalars['String']
  /** 파일 크기 */
  size?: InputMaybe<Scalars['Int']>
}

/** 모두 필수 */
export type CreateIdentificationInput = {
  userId: Scalars['String']
}

/**
 * 필수: title, question
 *
 * 선택: email, fileIds
 */
export type CreateInquiryInput = {
  email?: InputMaybe<Scalars['String']>
  /** 파일 객체 리스트 */
  files: Array<Scalars['Upload']>
  /** 문의 내용 */
  question: Scalars['String']
  /** 문의 제목 */
  title: Scalars['String']
}

/** 모두 필수 */
export type CreateInspectionInput = {
  /** 검수자로부터 수정된 해석 내용 */
  correctedContent?: InputMaybe<Scalars['String']>
  /** 검수자로부터 수정된 해석 내용 (HTML태그 제거됨) */
  correctedPlainContent?: InputMaybe<Scalars['String']>
  inspectorId: Scalars['String']
  interpretationId: Scalars['String']
  /** 검수자 노트 */
  note?: InputMaybe<Scalars['String']>
}

/** 모두 필수 */
export type CreateInterpretationInput = {
  examinationId: Scalars['String']
  professionalId: Scalars['String']
}

/**
 *
 *   필수: title, content, type, authorId
 *
 * 선택: fileIds
 *
 */
export type CreateNoticeInput = {
  authorId?: InputMaybe<Scalars['String']>
  /** 공지사항 내용 */
  content: Scalars['String']
  fileIds?: InputMaybe<Array<Scalars['String']>>
  /** 공지사항 제목 */
  title: Scalars['String']
  /** 공지사항 유형 */
  type: NoticeType
}

/** 필수: userId, groupId, name, files */
export type CreateProfessionalInput = {
  /** 파일 객체 리스트 */
  files: Array<Scalars['Upload']>
  groupId?: InputMaybe<Scalars['Int']>
  hospitalName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  schoolName?: InputMaybe<Scalars['String']>
  spec?: InputMaybe<ProfessionalSpecInputType>
  userId: Scalars['String']
}

/**
 * 필수: email, password, promotionSmsNotification, promotionEmailNotification
 *
 * 선택: name, nickname, role, publicId
 *
 */
export type CreateUserInput = {
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  nickname?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  /** 마케팅 이메일 알림 설정 */
  promotionEmailNotification: Scalars['Boolean']
  /** 마케팅 SMS 알림 설정 */
  promotionSmsNotification: Scalars['Boolean']
  role?: InputMaybe<UserRole>
}

/** 커서 기반 페이지네이션 정보 */
export type CursorPageInfo = {
  __typename?: 'CursorPageInfo'
  /** 이전 데이터 수 */
  countBefore: Scalars['Int']
  /** 현재 페이지 데이터 수 */
  countCurrent: Scalars['Int']
  /** 다음 데이터 수 */
  countNext: Scalars['Int']
  /** 전체 페이지 데이터 수 */
  countTotal: Scalars['Int']
  /** 종료 커서 */
  endCursor: Scalars['String']
  /** 다음 페이지 여부 */
  hasNextPage: Scalars['Boolean']
  /** 이전 페이지 여부 */
  hasPreviousPage: Scalars['Boolean']
  /** 시작 커서 */
  startCursor: Scalars['String']
}

/** 커서 기반 페이지네이션 처리된 정산 목록 */
export type CursorPaginatedEarnings = {
  __typename?: 'CursorPaginatedEarnings'
  /** 데이터 */
  edges?: Maybe<Array<EarningCursorEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<CursorPageInfo>
}

/** 커서 기반 페이지네이션 처리된 정산 목록 */
export type CursorPaginatedEarningsTotalAmount = {
  __typename?: 'CursorPaginatedEarningsTotalAmount'
  /** 데이터 */
  edges?: Maybe<Array<GetEarningsTotalAmountOutputCursorEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<CursorPageInfo>
}

/** 모두 필수 */
export type DeleteExaminationInput = {
  id: Scalars['String']
  /** 환자 ID */
  patientId: Scalars['String']
}

/** 검사지 삭제 Output */
export type DeleteExaminationOutput = {
  __typename?: 'DeleteExaminationOutput'
  /** 에러 메시지 */
  error?: Maybe<Scalars['String']>
  /** 성공 여부 */
  ok: Scalars['Boolean']
}

/** [Entity] 질병 */
export type Disease = {
  __typename?: 'Disease'
  /** 검사지와 연결된 질병 목록 */
  examinationToDiseases: Array<ExaminationToDisease>
  id: Scalars['Int']
  image?: Maybe<Scalars['String']>
  /** 주요 질환 여부 */
  isMajor: Scalars['Boolean']
  name: Scalars['String']
  /** 유저와 연결된 질병 목록(관심 질환) */
  userToDiseases: Array<ExaminationToDisease>
}

/** [필터] 모두 선택 사항 */
export type DiseaseFilter = {
  /** 주요 질환 여부 */
  isMajor?: InputMaybe<Scalars['Boolean']>
  /** 검색어 */
  keyword?: InputMaybe<Scalars['String']>
}

export type DiseaseInputType = {
  /** 검사지와 연결된 질병 목록 */
  examinationToDiseases: Array<ExaminationToDiseaseInputType>
  id: Scalars['Int']
  image?: InputMaybe<Scalars['String']>
  /** 주요 질환 여부 */
  isMajor: Scalars['Boolean']
  name: Scalars['String']
  /** 유저와 연결된 질병 목록(관심 질환) */
  userToDiseases: Array<ExaminationToDiseaseInputType>
}

/** DiseaseOffsetEdge */
export type DiseaseOffsetEdge = {
  __typename?: 'DiseaseOffsetEdge'
  node: Disease
}

/** 질병 목록을 정렬할 때 사용할 수 있는 정렬 옵션입니다. */
export enum DiseaseSortBy {
  /** 오름차순으로 생성일자 정렬 */
  CreatedAtAsc = 'CreatedAtAsc',
  /** 내림차순으로 생성일자 정렬 */
  CreatedAtDesc = 'CreatedAtDesc',
  /** 오름차순으로 ID 정렬 */
  IdAsc = 'IdAsc',
  /** 내림차순으로 ID 정렬 */
  IdDesc = 'IdDesc',
  /** 오름차순으로 이름 정렬 */
  NameAsc = 'NameAsc',
  /** 내림차순으로 이름 정렬 */
  NameDesc = 'NameDesc',
}

/** 질병별 검사지 수 통계 */
export type DiseaseStatisticsOutput = {
  __typename?: 'DiseaseStatisticsOutput'
  /** 수치 */
  count: Scalars['Int']
  /** ID */
  id: Scalars['Int']
  /** 진단명 */
  name: Scalars['String']
}

/** 정산 내역 다운로드 */
export type DownloadProfessionalEarningsOutput = {
  __typename?: 'DownloadProfessionalEarningsOutput'
  /** download url */
  downloadUrl: Scalars['String']
}

/** [Entity] 정산 */
export type Earning = {
  __typename?: 'Earning'
  /** 계좌 은행 */
  accountBank?: Maybe<Scalars['String']>
  /** 계좌 본인 이름 */
  accountName?: Maybe<Scalars['String']>
  /** 계좌 번호 */
  accountNumber?: Maybe<Scalars['String']>
  /** 정산 금액 */
  amounts: Scalars['Int']
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  interpretation: Interpretation
  interpretationId: Scalars['String']
  professional: Professional
  professionalId: Scalars['String']
  settlement?: Maybe<Settlement>
  settlementId?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

/** EarningCursorEdge */
export type EarningCursorEdge = {
  __typename?: 'EarningCursorEdge'
  cursor: Scalars['String']
  node: Earning
}

/** [필터] 모두 선택 사항 */
export type EarningFilter = {
  /** 해당 날짜 이전 */
  lessThanDate?: InputMaybe<Scalars['DateTime']>
  /** 해당 날짜 이후 */
  moreThanDate?: InputMaybe<Scalars['DateTime']>
  professionalId?: InputMaybe<Scalars['String']>
  settlementId?: InputMaybe<Scalars['String']>
}

export type EarningInputType = {
  /** 계좌 은행 */
  accountBank?: InputMaybe<Scalars['String']>
  /** 계좌 본인 이름 */
  accountName?: InputMaybe<Scalars['String']>
  /** 계좌 번호 */
  accountNumber?: InputMaybe<Scalars['String']>
  /** 정산 금액 */
  amounts: Scalars['Int']
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  interpretation: InterpretationInputType
  interpretationId: Scalars['String']
  professional: ProfessionalInputType
  professionalId: Scalars['String']
  settlement?: InputMaybe<SettlementInputType>
  settlementId?: InputMaybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

/** EarningOffsetEdge */
export type EarningOffsetEdge = {
  __typename?: 'EarningOffsetEdge'
  node: Earning
}

export enum EarningSortKey {
  CreatedAt = 'CreatedAt',
}

/** [Entity] 검사 결과 */
export type Examination = {
  __typename?: 'Examination'
  createdAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  /** 검사지 상세 설명 */
  description?: Maybe<Scalars['String']>
  /** 검사 날짜 */
  examinationDate?: Maybe<Scalars['DateTime']>
  /** 검사지와 연결된 질병 목록 */
  examinationToDiseases: Array<ExaminationToDisease>
  /** 검사지와 연결된 파일 목록 */
  examinationToFiles: Array<ExaminationToFile>
  /** 검사지와 연결된 의료영상 종류 목록 */
  examinationToMedicalImagingTypes: Array<ExaminationToMedicalImagingType>
  /** 검사 병원 */
  hospital?: Maybe<Hospital>
  hospitalId?: Maybe<Scalars['String']>
  id: Scalars['String']
  /** 검사지와 연결된 해석 */
  interpretation?: Maybe<Interpretation>
  /** 1차 검수 거절 날짜 */
  invalidatedAt?: Maybe<Scalars['DateTime']>
  /** 1차 검수 거절 사유 */
  invalidationReason?: Maybe<Scalars['String']>
  patient?: Maybe<User>
  /** 환자 ID */
  patientId: Scalars['String']
  /** 유저에게 공개되는 아이디 */
  publicId: Scalars['Int']
  /** 검사지 진행 상태 */
  status: ExaminationStatus
  /** 검사지 제목 */
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  /** 1차 검수 승인날짜 */
  validatedAt?: Maybe<Scalars['DateTime']>
}

/** 검사지 진행 상태 통계 */
export type ExaminationCounts = {
  __typename?: 'ExaminationCounts'
  /** 해석 완료 검사지 수 */
  done: Scalars['Int']
  /** 해석중 검사지 수 */
  interpreting: Scalars['Int']
  /** 1차 검수 거절 검사지 수 */
  invalid: Scalars['Int']
  /** 1차 검수 대기중 검사지 수 */
  pending: Scalars['Int']
  /** 전체 검사지 수 */
  total: Scalars['Int']
  /** 해석 대기중 검사지 수 */
  waiting: Scalars['Int']
}

/** [필터] 모두 선택 사항 */
export type ExaminationFilter = {
  /** 질병 ID 배열 */
  diseaseIds?: InputMaybe<Array<Scalars['Int']>>
  /** 해당 검사 날짜 이전 */
  examinationDateLt?: InputMaybe<Scalars['DateTime']>
  /** 해석 진행 상태 */
  interpretationStatus?: InputMaybe<Array<InterpretationStatus>>
  keyword?: InputMaybe<Scalars['String']>
  /** 의료영상 타입 ID 배열 */
  medicalImagingTypeIds?: InputMaybe<Array<Scalars['Int']>>
  /** 환자 ID */
  patientId?: InputMaybe<Scalars['String']>
  /** 의료인 ID */
  professionalId?: InputMaybe<Scalars['String']>
  /** 진행 상태 */
  status?: InputMaybe<Array<ExaminationStatus>>
}

export type ExaminationInputType = {
  createdAt: Scalars['DateTime']
  deletedAt?: InputMaybe<Scalars['DateTime']>
  /** 검사지 상세 설명 */
  description?: InputMaybe<Scalars['String']>
  /** 검사 날짜 */
  examinationDate?: InputMaybe<Scalars['DateTime']>
  /** 검사지와 연결된 질병 목록 */
  examinationToDiseases: Array<ExaminationToDiseaseInputType>
  /** 검사지와 연결된 파일 목록 */
  examinationToFiles: Array<ExaminationToFileInputType>
  /** 검사지와 연결된 의료영상 종류 목록 */
  examinationToMedicalImagingTypes: Array<ExaminationToMedicalImagingTypeInputType>
  /** 검사 병원 */
  hospital?: InputMaybe<HospitalInputType>
  hospitalId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  /** 검사지와 연결된 해석 */
  interpretation?: InputMaybe<InterpretationInputType>
  /** 1차 검수 거절 날짜 */
  invalidatedAt?: InputMaybe<Scalars['DateTime']>
  /** 1차 검수 거절 사유 */
  invalidationReason?: InputMaybe<Scalars['String']>
  patient?: InputMaybe<UserInputType>
  /** 환자 ID */
  patientId: Scalars['String']
  /** 유저에게 공개되는 아이디 */
  publicId: Scalars['Int']
  /** 검사지 진행 상태 */
  status: ExaminationStatus
  /** 검사지 제목 */
  title?: InputMaybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  /** 1차 검수 승인날짜 */
  validatedAt?: InputMaybe<Scalars['DateTime']>
}

/** ExaminationOffsetEdge */
export type ExaminationOffsetEdge = {
  __typename?: 'ExaminationOffsetEdge'
  node: Examination
}

/** 검사지 목록을 정렬할 때 사용할 수 있는 정렬 옵션입니다. */
export enum ExaminationSortBy {
  /** 오름차순으로 생성일 정렬 */
  CreatedAtAsc = 'CreatedAtAsc',
  /** 내림차순으로 생성일 정렬 */
  CreatedAtDesc = 'CreatedAtDesc',
  /** 오름차순으로 사진 수 정렬 */
  FileCountAsc = 'FileCountAsc',
  /** 내림차순으로 사진 수 정렬 */
  FileCountDesc = 'FileCountDesc',
  /** 오름차순으로 검수인 닉네임 정렬 */
  InspectorNicknameAsc = 'InspectorNicknameAsc',
  /** 내림차순으로 검수인 닉네임 정렬 */
  InspectorNicknameDesc = 'InspectorNicknameDesc',
  /** 오름차순으로 해석인 닉네임 정렬 */
  InterpreterNicknameAsc = 'InterpreterNicknameAsc',
  /** 내림차순으로 해석인 닉네임 정렬 */
  InterpreterNicknameDesc = 'InterpreterNicknameDesc',
  /** 오름차순으로 환자 닉네임 정렬 */
  PatientNicknameAsc = 'PatientNicknameAsc',
  /** 내림차순으로 환자 닉네임 정렬 */
  PatientNicknameDesc = 'PatientNicknameDesc',
  /** 오름차순으로 환자 Public ID 정렬 */
  PatientPublicIdAsc = 'PatientPublicIdAsc',
  /** 내림차순으로 환자 Public ID 정렬 */
  PatientPublicIdDesc = 'PatientPublicIdDesc',
  /** 오름차순으로 Public ID 정렬 */
  PublicIdAsc = 'PublicIdAsc',
  /** 내림차순으로 Public ID 정렬 */
  PublicIdDesc = 'PublicIdDesc',
  /** 오름차순으로 제목 정렬 */
  TitleAsc = 'TitleAsc',
  /** 내림차순으로 제목 정렬 */
  TitleDesc = 'TitleDesc',
}

/** 결과지 해석 진행 상태를 나타냅니다. */
export enum ExaminationStatus {
  /** 관리자로부터 해석이 승인되고 모든 과정이 완료됨 */
  Done = 'Done',
  /** 의료인이 해석할 결과지를 선택하여 해석이 진행중인 상태 */
  Interpreting = 'Interpreting',
  /** 환자가 업로드한 결과지가 양식에 맞지 않는 경우 */
  Invalid = 'Invalid',
  /** 환자가 업로드한 결과지의 승인을 기다리는 상태 */
  Pending = 'Pending',
  /** 환자가 업로드한 결과지가 승인된 후 해석을 기다리는 상태 */
  Waiting = 'Waiting',
}

/** [Entity] 검사지와 연결된 질병 */
export type ExaminationToDisease = {
  __typename?: 'ExaminationToDisease'
  createdAt: Scalars['DateTime']
  disease: Disease
  diseaseId: Scalars['Int']
  examination: Examination
  examinationId: Scalars['String']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type ExaminationToDiseaseInputType = {
  createdAt: Scalars['DateTime']
  disease: DiseaseInputType
  diseaseId: Scalars['Int']
  examination: ExaminationInputType
  examinationId: Scalars['String']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** [Entity] 검사지와 연결된 파일 */
export type ExaminationToFile = {
  __typename?: 'ExaminationToFile'
  createdAt: Scalars['DateTime']
  examination: Examination
  examinationId: Scalars['String']
  file: File
  fileId: Scalars['String']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type ExaminationToFileInputType = {
  createdAt: Scalars['DateTime']
  examination: ExaminationInputType
  examinationId: Scalars['String']
  file: FileInputType
  fileId: Scalars['String']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** [Entity] 검사지와 연결된 의료영상 종류 */
export type ExaminationToMedicalImagingType = {
  __typename?: 'ExaminationToMedicalImagingType'
  createdAt: Scalars['DateTime']
  examination: Examination
  examinationId: Scalars['String']
  id: Scalars['String']
  medicalImagingType: MedicalImagingType
  medicalImagingTypeId: Scalars['Int']
  updatedAt: Scalars['DateTime']
}

export type ExaminationToMedicalImagingTypeInputType = {
  createdAt: Scalars['DateTime']
  examination: ExaminationInputType
  examinationId: Scalars['String']
  id: Scalars['String']
  medicalImagingType: MedicalImagingTypeInputType
  medicalImagingTypeId: Scalars['Int']
  updatedAt: Scalars['DateTime']
}

/** 의료영상 종류 별 검사지 수 Output */
export type ExaminationToMedicalImagingTypesCountOutput = {
  __typename?: 'ExaminationToMedicalImagingTypesCountOutput'
  count: Scalars['Int']
  id: Scalars['Int']
  name: Scalars['String']
}

/** 오프셋 기반 페이지네이션 처리된 검사지 목록 */
export type ExaminationsCountsInfo = {
  __typename?: 'ExaminationsCountsInfo'
  waiting: Scalars['Int']
}

/** 모두 필수 */
export type ExtendInterpretationDeadlineInput = {
  id: Scalars['String']
}

/** [Entity] FAQ(자주 묻는 질문) */
export type Faq = {
  __typename?: 'Faq'
  category: FaqCategory
  /** FAQ 카테고리 */
  categoryId: Scalars['Int']
  createdAt: Scalars['DateTime']
  /** 상세 내용 */
  description: Scalars['String']
  id: Scalars['String']
  /** 제목 */
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** [Entity] FAQ 카테고리 */
export type FaqCategory = {
  __typename?: 'FaqCategory'
  faqs?: Maybe<Array<Faq>>
  id: Scalars['Int']
  name: Scalars['String']
}

/** FaqOffsetEdge */
export type FaqOffsetEdge = {
  __typename?: 'FaqOffsetEdge'
  node: Faq
}

/** FAQ 목록을 정렬할 때 사용할 수 있는 정렬 옵션입니다. */
export enum FaqSortBy {
  /** 오름차순으로 생성일자 정렬 */
  CreatedAtAsc = 'CreatedAtAsc',
  /** 내림차순으로 생성일자 정렬 */
  CreatedAtDesc = 'CreatedAtDesc',
}

/** [Entity] 파일 */
export type File = {
  __typename?: 'File'
  /** 증명서와 연결된 파일 */
  certificateToFiles: Array<CertificateToFile>
  createdAt: Scalars['DateTime']
  /** 검사지와 연결된 파일 */
  examinationToFiles: Array<ExaminationToFile>
  id: Scalars['String']
  /** 1대1 문의와 연결된 파일 */
  inquiryToFiles: Array<InquiryToFile>
  /** 비공개 여부 */
  isPrivate: Scalars['Boolean']
  /** 파일 키 (S3 파일 경로) */
  key: Scalars['String']
  /** 공지사항과 연결된 파일 */
  noticeToFiles: Array<NoticeToFile>
  /** 원본 파일 이름 */
  originName: Scalars['String']
  /** 파일 소유자 */
  owner?: Maybe<User>
  ownerId?: Maybe<Scalars['String']>
  /** 파일 크기 */
  size?: Maybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
}

export type FileInputType = {
  /** 증명서와 연결된 파일 */
  certificateToFiles: Array<CertificateToFileInputType>
  createdAt: Scalars['DateTime']
  /** 검사지와 연결된 파일 */
  examinationToFiles: Array<ExaminationToFileInputType>
  id: Scalars['String']
  /** 1대1 문의와 연결된 파일 */
  inquiryToFiles: Array<InquiryToFileInputType>
  /** 비공개 여부 */
  isPrivate: Scalars['Boolean']
  /** 파일 키 (S3 파일 경로) */
  key: Scalars['String']
  /** 공지사항과 연결된 파일 */
  noticeToFiles: Array<NoticeToFileInputType>
  /** 원본 파일 이름 */
  originName: Scalars['String']
  /** 파일 소유자 */
  owner?: InputMaybe<UserInputType>
  ownerId?: InputMaybe<Scalars['String']>
  /** 파일 크기 */
  size?: InputMaybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
}

/** 모두 필수 */
export type GenerateUploadPathInput = {
  /** 원본 파일 이름 */
  originName: Scalars['String']
  type: UploadType
}

/** S3 Signed URL Output */
export type GenerateUploadPathOutput = {
  __typename?: 'GenerateUploadPathOutput'
  /** S3 file key */
  key: Scalars['String']
  /** S3 Signed URL */
  signedUrl: Scalars['String']
}

/** 의료인 등록 진행 상태 통계 */
export type GetCertificateStatisticsOutput = {
  __typename?: 'GetCertificateStatisticsOutput'
  /** 승인 완료 등록 수 */
  certified: Scalars['Int']
  /** 승인 거절 등록 수 */
  rejected: Scalars['Int']
  /** 전체 등록 수 */
  total: Scalars['Int']
  /** 승인 대기중 등록 수 */
  waiting: Scalars['Int']
}

/** 해석 수익 총액 조회 결과값 */
export type GetEarningTotalAmountOutput = {
  __typename?: 'GetEarningTotalAmountOutput'
  /** 수익 총액 */
  totalAmount: Scalars['Int']
}

/** 관리자 정산 관리 페이지 통계 */
export type GetEarningsStatisticsForAdminOutput = {
  __typename?: 'GetEarningsStatisticsForAdminOutput'
  /** 다음 정산일 */
  nextSettlementDate?: Maybe<Scalars['DateTime']>
  /** 누적 해석 금액 */
  paidAmount?: Maybe<Scalars['Int']>
  /** 정산 예정 금액 */
  unpaidAmount?: Maybe<Scalars['Int']>
}

/** 정산 관리 통계 */
export type GetEarningsStatisticsOutput = {
  __typename?: 'GetEarningsStatisticsOutput'
  /** 이번 달의 해석 수 */
  monthCount: Scalars['Int']
  /** 다음 정산일 */
  nextSettlementDate?: Maybe<Scalars['DateTime']>
  /** 누적 해석 금액 */
  paidAmount: Scalars['Int']
  /** 정산 건수 */
  settlementCount: Scalars['Int']
  /** 누적 정산(해석) 건수 */
  totalCount: Scalars['Int']
  /** 정산 예정 금액 */
  unpaidAmount: Scalars['Int']
  /** 다음 정산 건수 */
  unpaidCount: Scalars['Int']
  /** 이번 주의 해석 수 */
  weekCount: Scalars['Int']
}

export type GetEarningsTotalAmountOutput = {
  __typename?: 'GetEarningsTotalAmountOutput'
  /** 정산 예정일 */
  dueDate?: Maybe<Scalars['DateTime']>
  /** 정산 종료 기준일 */
  endDate: Scalars['DateTime']
  /** 정산 지급일 */
  paidAt?: Maybe<Scalars['DateTime']>
  settlementId: Scalars['String']
  /** 정산 시작 기준일 */
  startDate: Scalars['DateTime']
  /** 총 지불 금액 */
  totalAmount?: Maybe<Scalars['Int']>
  /** 정산 지급 대상 해석 수 */
  totalCount?: Maybe<Scalars['Int']>
}

/** GetEarningsTotalAmountOutputCursorEdge */
export type GetEarningsTotalAmountOutputCursorEdge = {
  __typename?: 'GetEarningsTotalAmountOutputCursorEdge'
  cursor: Scalars['String']
  node: GetEarningsTotalAmountOutput
}

/** 검사지 진행 상태 통계 */
export type GetExaminationStatisticsOutput = {
  __typename?: 'GetExaminationStatisticsOutput'
  /** 해석 완료 검사지 수 */
  done: Scalars['Int']
  /** 마감 기한 초과 검사지 수 */
  exceeded: Scalars['Int']
  /** 해석 검수 대기중 검사지 수 */
  interpretingFinished: Scalars['Int']
  /** 해석중 검사지 수 */
  interpretingStarted: Scalars['Int']
  /** 1차 검수 실패 검사지 수 */
  invalid: Scalars['Int']
  /** 10일간 등록된 검사 결과지 수 */
  newExaminations: Array<Examination>
  /** 10일 간 등록된 날짜별 검사지 수 */
  newExaminationsCount: Scalars['Int']
  /** 1차 검수 대기중 검사지 수 */
  pending: Scalars['Int']
  /** 10일 간 검사지 등록 상승률 */
  rateOfRise: Scalars['Int']
  /** 오늘 올라온 검사결과지 수 */
  todaysNewExaminationsCount: Scalars['Int']
  /** 전체 검사지 수 */
  total: Scalars['Int']
  /** 해석 대기중 검사지 수 */
  waiting: Scalars['Int']
  /** 어제 올라온 검사결과지 수 */
  yesterdaysNewExaminationsCount: Scalars['Int']
}

/** 1대1 문의 진행 상태 통계 */
export type GetInquiryStatisticsOutput = {
  __typename?: 'GetInquiryStatisticsOutput'
  /** 전체 문의 수 */
  total: Scalars['Int']
  /** 답변 대기중 문의 수 */
  waiting: Scalars['Int']
}

/** 모두 필수 */
export type GetSavedInterpretationsInput = {
  professionalId: Scalars['String']
}

/** 사용자 통계 */
export type GetUserStatisticsOutput = {
  __typename?: 'GetUserStatisticsOutput'
  /** 10일 간 가입된 날짜별 사용자 수 */
  newUsers: Array<User>
  /** 환자 사용자 수 */
  patientCount: Scalars['Int']
  /** 의료인 사용자 수 */
  professionalCount: Scalars['Int']
  /** 지난 10일 대비 가입자 상승률 */
  rateOfRise: Scalars['Int']
  /** 오늘 가입한 사용자 수 */
  todaysNewUsersCount: Scalars['Int']
  /** 전체 사용자 수 */
  total: Scalars['Int']
  /** 어제 가입한 사용자 수 */
  yesterdaysNewUsersCount: Scalars['Int']
}

/** [Entity] 병원 */
export type Hospital = {
  __typename?: 'Hospital'
  createdAt: Scalars['DateTime']
  /** 검사 병원 목록 */
  examinations: Array<Examination>
  id: Scalars['String']
  image?: Maybe<Scalars['String']>
  /** 주요 병원 여부 */
  isMajor: Scalars['Boolean']
  /** 병원 이름 */
  name: Scalars['String']
  /** 소속된 의료인 목록 */
  professionals: Array<Professional>
  updatedAt: Scalars['DateTime']
}

/** [필터] 모두 선택 사항 */
export type HospitalFilter = {
  /** 주요 병원 여부 */
  isMajor?: InputMaybe<Scalars['Boolean']>
  /** 검색어 */
  keyword?: InputMaybe<Scalars['String']>
}

export type HospitalInputType = {
  createdAt: Scalars['DateTime']
  /** 검사 병원 목록 */
  examinations: Array<ExaminationInputType>
  id: Scalars['String']
  image?: InputMaybe<Scalars['String']>
  /** 주요 병원 여부 */
  isMajor: Scalars['Boolean']
  /** 병원 이름 */
  name: Scalars['String']
  /** 소속된 의료인 목록 */
  professionals: Array<ProfessionalInputType>
  updatedAt: Scalars['DateTime']
}

/** HospitalOffsetEdge */
export type HospitalOffsetEdge = {
  __typename?: 'HospitalOffsetEdge'
  node: Hospital
}

/** [Entity] 본인 인증 */
export type Identification = {
  __typename?: 'Identification'
  /** 생년월일 */
  birthday?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  /** 인증일자 */
  identifiedAt?: Maybe<Scalars['DateTime']>
  /** 아임포트 UID */
  impUid?: Maybe<Scalars['String']>
  /** 인증 여부 */
  isIdentified?: Maybe<Scalars['Boolean']>
  /** 인증된 이름 */
  name?: Maybe<Scalars['String']>
  /** 인증된 휴대폰 번호 */
  phoneNumber?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

export type IdentificationInputType = {
  /** 생년월일 */
  birthday?: InputMaybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  deletedAt?: InputMaybe<Scalars['DateTime']>
  id: Scalars['String']
  /** 인증일자 */
  identifiedAt?: InputMaybe<Scalars['DateTime']>
  /** 아임포트 UID */
  impUid?: InputMaybe<Scalars['String']>
  /** 인증 여부 */
  isIdentified?: InputMaybe<Scalars['Boolean']>
  /** 인증된 이름 */
  name?: InputMaybe<Scalars['String']>
  /** 인증된 휴대폰 번호 */
  phoneNumber?: InputMaybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  user: UserInputType
  userId: Scalars['String']
}

/** [Entity] 1대1 문의 */
export type Inquiry = {
  __typename?: 'Inquiry'
  /** 답변 */
  answer?: Maybe<Scalars['String']>
  /** 답변일자 */
  answeredAt?: Maybe<Scalars['DateTime']>
  /** 답변자 */
  answerer?: Maybe<Administrator>
  answererId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  email?: Maybe<Scalars['String']>
  id: Scalars['String']
  /** 문의와 연결된 첨부파일 */
  inquiryToFiles?: Maybe<Array<InquiryToFile>>
  /** 회원 여부 */
  isMember: Scalars['Boolean']
  /** 문의 내용 */
  question: Scalars['String']
  /** 문의자 */
  questioner?: Maybe<User>
  questionerId?: Maybe<Scalars['String']>
  /** 문의 제목 */
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** [필터] 모두 선택 사항 */
export type InquiryFilter = {
  /** 답변 여부 */
  isAnswered?: InputMaybe<Scalars['Boolean']>
  /** 회원 여부 */
  isMember?: InputMaybe<Scalars['Boolean']>
  /** 질문자 ID */
  questionerId?: InputMaybe<Scalars['String']>
}

export type InquiryInputType = {
  /** 답변 */
  answer?: InputMaybe<Scalars['String']>
  /** 답변일자 */
  answeredAt?: InputMaybe<Scalars['DateTime']>
  /** 답변자 */
  answerer?: InputMaybe<AdministratorInputType>
  answererId?: InputMaybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  email?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  /** 문의와 연결된 첨부파일 */
  inquiryToFiles?: InputMaybe<Array<InquiryToFileInputType>>
  /** 회원 여부 */
  isMember: Scalars['Boolean']
  /** 문의 내용 */
  question: Scalars['String']
  /** 문의자 */
  questioner?: InputMaybe<UserInputType>
  questionerId?: InputMaybe<Scalars['String']>
  /** 문의 제목 */
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** InquiryOffsetEdge */
export type InquiryOffsetEdge = {
  __typename?: 'InquiryOffsetEdge'
  node: Inquiry
}

/** 1대1 문의 목록을 정렬할 때 사용할 수 있는 정렬 옵션입니다. */
export enum InquirySortBy {
  /** 오름차순으로 생성일자 정렬 */
  CreatedAtAsc = 'CreatedAtAsc',
  /** 내림차순으로 생성일자 정렬 */
  CreatedAtDesc = 'CreatedAtDesc',
  /** 오름차순으로 이메일 정렬 */
  EmailAsc = 'EmailAsc',
  /** 내림차순으로 이메일 정렬 */
  EmailDesc = 'EmailDesc',
}

/** [Entity] 1대1 문의와 연결된 첨부파일 */
export type InquiryToFile = {
  __typename?: 'InquiryToFile'
  createdAt: Scalars['DateTime']
  file: File
  fileId: Scalars['String']
  id: Scalars['String']
  inquiry: Inquiry
  inquiryId: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type InquiryToFileInputType = {
  createdAt: Scalars['DateTime']
  file: FileInputType
  fileId: Scalars['String']
  id: Scalars['String']
  inquiry: InquiryInputType
  inquiryId: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** [Entity] 해석 검수 */
export type Inspection = {
  __typename?: 'Inspection'
  /** 검수 정확도 점수 */
  accuracyScore: Scalars['Int']
  /** 평균 점수 */
  averageScore?: Maybe<Scalars['Float']>
  /** 규정 준수 점수 */
  complianceScore: Scalars['Int']
  createdAt: Scalars['DateTime']
  /** 표현 점수 */
  expressionScore: Scalars['Int']
  /** 정직성 점수 */
  honestyScore: Scalars['Int']
  id: Scalars['String']
  /** 검수자(관리자) */
  inspector: Administrator
  inspectorId: Scalars['String']
  interpretation: Interpretation
  interpretationId: Scalars['String']
  /** 검수자 노트 */
  note?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type InspectionInputType = {
  /** 검수 정확도 점수 */
  accuracyScore: Scalars['Int']
  /** 평균 점수 */
  averageScore?: InputMaybe<Scalars['Float']>
  /** 규정 준수 점수 */
  complianceScore: Scalars['Int']
  createdAt: Scalars['DateTime']
  /** 표현 점수 */
  expressionScore: Scalars['Int']
  /** 정직성 점수 */
  honestyScore: Scalars['Int']
  id: Scalars['String']
  /** 검수자(관리자) */
  inspector: AdministratorInputType
  inspectorId: Scalars['String']
  interpretation: InterpretationInputType
  interpretationId: Scalars['String']
  /** 검수자 노트 */
  note?: InputMaybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

/** [Entity] 해석 */
export type Interpretation = {
  __typename?: 'Interpretation'
  /** 해석 승인 시간 */
  approvedAt?: Maybe<Scalars['DateTime']>
  /** 해석 내용 */
  content?: Maybe<Scalars['String']>
  /** 해석 내용의 글자수 */
  contentLength?: Maybe<Scalars['Int']>
  /** 검수자로부터 수정된 해석 내용 */
  correctedContent?: Maybe<Scalars['String']>
  /** 수정된 해석 내용의 글자수 */
  correctedContentLength?: Maybe<Scalars['Int']>
  /** 검수자로부터 수정된 해석 내용 (HTML태그 제거됨) */
  correctedPlainContent?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  /** 마감기한 */
  deadline?: Maybe<Scalars['DateTime']>
  earning?: Maybe<Earning>
  examination: Examination
  examinationId: Scalars['String']
  /** 해석 작성 완료 시간 */
  finishedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  inspection?: Maybe<Inspection>
  /** 해석 내용(HTML태그 제거됨) */
  plainContent?: Maybe<Scalars['String']>
  professional: Professional
  professionalId: Scalars['String']
  /** 해석 승인 거절 시간 */
  rejectedAt?: Maybe<Scalars['DateTime']>
  /** 해석 승인 거절 사유 */
  rejectionReason?: Maybe<Scalars['String']>
  /** 해석 저장 시간 */
  savedAt?: Maybe<Scalars['DateTime']>
  /** 해석 시작 시간 */
  startedAt: Scalars['DateTime']
  /** 해석 진행 상태 */
  status: InterpretationStatus
  updatedAt: Scalars['DateTime']
}

/** [필터] 모두 선택 사항 */
export type InterpretationFilter = {
  diseaseIds?: InputMaybe<Array<Scalars['Int']>>
  medicalImagingTypeIds?: InputMaybe<Array<Scalars['Int']>>
  status?: InputMaybe<Array<InterpretationStatus>>
}

export type InterpretationInputType = {
  /** 해석 승인 시간 */
  approvedAt?: InputMaybe<Scalars['DateTime']>
  /** 해석 내용 */
  content?: InputMaybe<Scalars['String']>
  /** 해석 내용의 글자수 */
  contentLength?: InputMaybe<Scalars['Int']>
  /** 검수자로부터 수정된 해석 내용 */
  correctedContent?: InputMaybe<Scalars['String']>
  /** 수정된 해석 내용의 글자수 */
  correctedContentLength?: InputMaybe<Scalars['Int']>
  /** 검수자로부터 수정된 해석 내용 (HTML태그 제거됨) */
  correctedPlainContent?: InputMaybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  /** 마감기한 */
  deadline?: InputMaybe<Scalars['DateTime']>
  earning?: InputMaybe<EarningInputType>
  examination: ExaminationInputType
  examinationId: Scalars['String']
  /** 해석 작성 완료 시간 */
  finishedAt?: InputMaybe<Scalars['DateTime']>
  id: Scalars['String']
  inspection?: InputMaybe<InspectionInputType>
  /** 해석 내용(HTML태그 제거됨) */
  plainContent?: InputMaybe<Scalars['String']>
  professional: ProfessionalInputType
  professionalId: Scalars['String']
  /** 해석 승인 거절 시간 */
  rejectedAt?: InputMaybe<Scalars['DateTime']>
  /** 해석 승인 거절 사유 */
  rejectionReason?: InputMaybe<Scalars['String']>
  /** 해석 저장 시간 */
  savedAt?: InputMaybe<Scalars['DateTime']>
  /** 해석 시작 시간 */
  startedAt: Scalars['DateTime']
  /** 해석 진행 상태 */
  status: InterpretationStatus
  updatedAt: Scalars['DateTime']
}

/** InterpretationOffsetEdge */
export type InterpretationOffsetEdge = {
  __typename?: 'InterpretationOffsetEdge'
  node: Interpretation
}

/** 해석 목록을 정렬할 때 사용할 수 있는 정렬 옵션입니다. */
export enum InterpretationSortBy {
  /** 오름차순으로 해석 승인일자 정렬 */
  ApprovedAtAsc = 'ApprovedAtAsc',
  /** 내림차순으로 해석 승인일자 정렬 */
  ApprovedAtDesc = 'ApprovedAtDesc',
  /** 오름차순으로 생성일자 정렬 */
  CreatedAtAsc = 'CreatedAtAsc',
  /** 내림차순으로 생성일자 정렬 */
  CreatedAtDesc = 'CreatedAtDesc',
  /** 오름차순으로 해석 종료일자 정렬 */
  FinishedAtAsc = 'FinishedAtAsc',
  /** 내림차순으로 해석 종료일자 정렬 */
  FinishedAtDesc = 'FinishedAtDesc',
  /** 오름차순으로 환자 ID 정렬 */
  PatientIdAsc = 'PatientIdAsc',
  /** 내림차순으로 환자 ID 정렬 */
  PatientIdDesc = 'PatientIdDesc',
  /** 오름차순으로 의료인 ID 정렬 */
  ProfessionalIdAsc = 'ProfessionalIdAsc',
  /** 내림차순으로 의료인 ID 정렬 */
  ProfessionalIdDesc = 'ProfessionalIdDesc',
  /** 오름차순으로 해석 승인거절일자 정렬 */
  RejectedAtAsc = 'RejectedAtAsc',
  /** 내림차순으로 해석 승인거절일자 정렬 */
  RejectedAtDesc = 'RejectedAtDesc',
  /** 오름차순으로 해석 시작일자 정렬 */
  StartedAtAsc = 'StartedAtAsc',
  /** 내림차순으로 해석 시작일자 정렬 */
  StartedAtDesc = 'StartedAtDesc',
}

/** 해석 진행 상태 통계 */
export type InterpretationStatistics = {
  __typename?: 'InterpretationStatistics'
  /** 평균 해석 평가 점수 */
  averageRating?: Maybe<Scalars['Float']>
  /** 완료된 해석 수 */
  finished: Scalars['Int']
  /** 진행중인 해석 수 */
  inProgress: Scalars['Int']
  /** 최근 해석 시작 시간 */
  latestDate?: Maybe<Scalars['DateTime']>
  /** 전체 해석 수 */
  total: Scalars['Int']
}

/** 의료인의 해석 진행 상태를 나타냅니다. */
export enum InterpretationStatus {
  /** 관리자로부터 해석이 승인됨 */
  Approved = 'Approved',
  /** 의료인이 해석을 마치고 관리자의 승인을 기다리는 중 */
  Finished = 'Finished',
  /** 의료인의 해석을 관리자가 거부함 */
  Rejected = 'Rejected',
  /** 의료인이 해석할 결과지를 선택하여 해석이 진행중 */
  Started = 'Started',
}

/** 모두 필수 */
export type InvalidateExaminationInput = {
  id: Scalars['String']
  /** 1차 검수 거절 사유 */
  invalidationReason?: InputMaybe<Scalars['String']>
}

/** 모두 필수 */
export type LoginInput = {
  email?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}

/** 로그인 Output. */
export type LoginOutput = {
  __typename?: 'LoginOutput'
  accessToken: Scalars['String']
  publicId: Scalars['Int']
  refreshToken: Scalars['String']
}

/** 로그아웃 Output */
export type LogoutOutput = {
  __typename?: 'LogoutOutput'
  /** 에러 메시지 */
  error?: Maybe<Scalars['String']>
  /** 성공 여부 */
  ok: Scalars['Boolean']
}

/** [Entity] 의료영상 종류 */
export type MedicalImagingType = {
  __typename?: 'MedicalImagingType'
  examinationToMedicalImagingTypes: Array<ExaminationToMedicalImagingType>
  id: Scalars['Int']
  image?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type MedicalImagingTypeInputType = {
  examinationToMedicalImagingTypes: Array<ExaminationToMedicalImagingTypeInputType>
  id: Scalars['Int']
  image?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** [관리자] 의료인 등록 신청을 승인합니다. 승인 시 의료인에게 알림이 발송됩니다. */
  approveCertificate: Certificate
  /** [관리자] 해석을 승인합니다. 해석 상태는 Approved로, 검사지 상태는 Done으로 변경되고 환자와 의료인에게 해석완료 알림이 발송됩니다. */
  approveInterpretation: Interpretation
  /** 계정을 정지합니다. */
  blockUser: User
  /** 해석을 취소합니다. 해석을 삭제하고 검사지 상태를 Wating으로 되돌립니다. */
  cancelInterpretation: CoreOutput
  /** 새로운 비밀번호로 변경합니다 */
  changePassword: CoreOutput
  /** 비밀번호 변경 전 현재 비밀번호를 확인합니다 */
  checkPassword: CoreOutput
  completeSettlement: Settlement
  /** 이메일 인증 코드가 올바른지 확인합니다. */
  confirmEmailVerificationCode: ConfirmEmailVerificationCodeOutput
  /** 휴대폰 인증 코드가 올바른지 확인합니다. */
  confirmPhoneVerificationCode: ConfirmPhoneVerificationCodeOutput
  /** 새로운 관리자를 생성합니다. */
  createAdministrator: User
  /** 1대1 문의에 대한 답변을 생성합니다. */
  createAnswer: Inquiry
  createBankAccount: BankAccount
  /** 의료인 등록을 위한 의료인 증명서를 생성합니다. */
  createCertificate: Certificate
  /** 검사 이미지 파일들을 받아 검사지를 생성합니다. */
  createExamination: Examination
  /** [관리자] 새 FAQ를 생성합니다. */
  createFaq: Faq
  /** S3에 저장된 파일 정보(key, size, origin name 등)를 DB에 저장합니다. */
  createFile: File
  /** 비회원으로부터 새 파일을 생성합니다. */
  createFileByNonMember: File
  /** 새 본인인증 정보를 생성합니다. */
  createIdentification: Identification
  /** 가입된 회원이 1대1 문의를 생성합니다. */
  createInquiryByMember: Inquiry
  /** 가입하지 않은 회원이 1대1 문의를 생성합니다. */
  createInquiryByNonMember: Inquiry
  /** [관리자] 해석 검수를 생성합니다. */
  createInspection: Inspection
  /** 해석 신청을 통해 새 해석을 생성합니다. 검사지의 상태가 Wating 상태이면서 다른 사람의 해석 신청이 없는 경우에만 해석을 생성합니다. 검사지의 상태는 Interpreting 상태가 됩니다. */
  createInterpretation: Interpretation
  /** [관리자] 새 공지사항을 생성합니다. */
  createNotice: Notice
  /** 의료인을 생성합니다. */
  createProfessional: Professional
  /** 새로운 유저를 생성합니다 */
  createUser: User
  /** 검사지를 삭제합니다. */
  deleteExamination: DeleteExaminationOutput
  /** [관리자] FAQ를 삭제합니다. */
  deleteFaq: CoreOutput
  /** [관리자] 공지사항을 삭제합니다. */
  deleteNotice: CoreOutput
  /** 유저를 삭제합니다(soft delete) */
  deleteUser: CoreOutput
  /** 해석의 마감시간을 연장합니다. */
  extendInterpretationDeadline: Interpretation
  /** 다운로드를 위한 S3 Signed URL을 생성합니다. */
  generateDownloadPath: Scalars['String']
  /** S3 업로드를 위한 Signed URL & Key를 생성합니다. */
  generateUploadPath: GenerateUploadPathOutput
  /** 비회원의 파일 업로드를 위한 S3 Signed URL을 생성합니다. */
  generateUploadPathByNonMember: GenerateUploadPathOutput
  /** 검사지 1차 검수에서 유효하지 않은 검사지를 거절합니다. */
  invalidateExamination: Examination
  /** 이메일과 비밀번호로 로그인합니다. */
  login: LoginOutput
  /** Access token & Refresh token 쿠키를 삭제하고 로그아웃합니다. */
  logout: LogoutOutput
  /** refresh token으로 새로운 access token과 refresh token을 발급 받습니다. */
  regenerateToken: RegenerateTokenOutput
  /** 의료인 증명서를 거절합니다. 신청인에게 거절 사유가 전달됩니다. */
  rejectCertificate: Certificate
  /** [관리자] 해석을 거절합니다. 해석 상태는 Rejected로 변경되고 거절 사유가 의료인에게 발송됩니다. */
  rejectInterpretation: Interpretation
  removeBankAccount: CoreOutput
  /** 이메일 인증 코드를 요청합니다. */
  requestEmailVerificationCode: RequestEmailVerificationCodeOutput
  /** 휴대폰 인증 코드를 요청합니다. */
  requestPhoneVerificationCode: RequestPhoneVerificationCodeOutput
  /** 의료인 증명서를 초기화합니다. */
  resetCertificate: Certificate
  /** 비밀번호를 재설정합니다 */
  resetPassword: ResetPasswordOutput
  saveIdentifiedUser: Identification
  /** 해석을 임시 저장합니다. Started 상태의 해석만 저장 가능합니다. */
  saveInterpretation: Interpretation
  /** [관리자] 슬랙 테스트 메시지를 보냅니다. */
  sendTestSlackMessage: Scalars['Boolean']
  /** 기존 유저를 관리자로 변경합니다. */
  setAdministrator: User
  /** 프로필 이미지를 업로드합니다. 이미지는 1개만 업로드할 수 있습니다. */
  setProfileImage: User
  /** 보호자 유저가 환자와의 관계를 등록합니다. 관계 이름이 기존 Relationship 테이블에 존재하지 않는 경우 새로 생성하여 User와 연결합니다. */
  setRelationshipWithUser: User
  setUserToDiseases: Array<UserToDisease>
  /** 작성을 마친 해석을 제출합니다. 해석 상태가 Started에서 Finished로 변경되면서 관리자의 승인을 기다리게 됩니다. */
  submitInterpretation: Interpretation
  /** 관리자의 역할을 변경합니다. */
  updateAdministratorRole: Administrator
  updateBankAccount: BankAccount
  /** 검사지를 업데이트합니다. */
  updateExamination: Examination
  /** [관리자] FAQ를 수정합니다. */
  updateFaq: Faq
  /** [관리자] 해석 점수를 기록합니다. 해석 상태는 Approved로, 검사지 상태는 Done으로 변경되고 환자와 의료인에게 해석완료 알림이 발송됩니다. */
  updateInspectionScore: Inspection
  /** 해석 내용을 수정합니다 */
  updateInterpretation: Interpretation
  /** [관리자] 공지사항을 수정합니다. */
  updateNotice: Notice
  updateSettlementDueDate: Settlement
  /** 유저의 정보를 변경합니다 */
  updateUser: User
  /** 유저 설정을 수정합니다. */
  updateUserSettings: UserSetting
  /** 파일을 받아 S3에 업로드하고, 파일 정보를 DB에 저장합니다. */
  uploadAndCreateFile: Array<File>
  upsertBankAccount: BankAccount
  /** 검사지 1차 검수를 승인합니다. 검사지의 상태가 Pending에서 Wating으로 변경되고 의료인이 조회할 수 있는 상태가 됩니다. */
  validateExamination: Examination
}

export type MutationApproveCertificateArgs = {
  input: ApproveCertificateInput
}

export type MutationApproveInterpretationArgs = {
  input: ApproveInterpretationInput
}

export type MutationBlockUserArgs = {
  userId: Scalars['String']
}

export type MutationCancelInterpretationArgs = {
  id: Scalars['String']
}

export type MutationChangePasswordArgs = {
  password: Scalars['String']
}

export type MutationCheckPasswordArgs = {
  password: Scalars['String']
}

export type MutationCompleteSettlementArgs = {
  input: CompleteSettlementTransactionInput
}

export type MutationConfirmEmailVerificationCodeArgs = {
  input: ConfirmEmailVerificationCodeInput
}

export type MutationConfirmPhoneVerificationCodeArgs = {
  input: ConfirmPhoneVerificationCodeInput
}

export type MutationCreateAdministratorArgs = {
  input: CreateAdministratorInput
}

export type MutationCreateAnswerArgs = {
  input: CreateAnswerInput
}

export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput
}

export type MutationCreateCertificateArgs = {
  input: CreateCertificateInput
}

export type MutationCreateExaminationArgs = {
  input: CreateExaminationInput
}

export type MutationCreateFaqArgs = {
  input: CreateFaqInput
}

export type MutationCreateFileArgs = {
  input: CreateFileInput
}

export type MutationCreateFileByNonMemberArgs = {
  input: CreateFileInput
}

export type MutationCreateIdentificationArgs = {
  input: CreateIdentificationInput
}

export type MutationCreateInquiryByMemberArgs = {
  input: CreateInquiryInput
}

export type MutationCreateInquiryByNonMemberArgs = {
  input: CreateInquiryInput
}

export type MutationCreateInspectionArgs = {
  input: CreateInspectionInput
}

export type MutationCreateInterpretationArgs = {
  input: CreateInterpretationInput
}

export type MutationCreateNoticeArgs = {
  input: CreateNoticeInput
}

export type MutationCreateProfessionalArgs = {
  input: CreateProfessionalInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeleteExaminationArgs = {
  input: DeleteExaminationInput
}

export type MutationDeleteFaqArgs = {
  id: Scalars['String']
}

export type MutationDeleteNoticeArgs = {
  id: Scalars['String']
}

export type MutationDeleteUserArgs = {
  id: Scalars['String']
}

export type MutationExtendInterpretationDeadlineArgs = {
  input: ExtendInterpretationDeadlineInput
}

export type MutationGenerateDownloadPathArgs = {
  key: Scalars['String']
}

export type MutationGenerateUploadPathArgs = {
  input: GenerateUploadPathInput
}

export type MutationGenerateUploadPathByNonMemberArgs = {
  input: GenerateUploadPathInput
}

export type MutationInvalidateExaminationArgs = {
  input: InvalidateExaminationInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationRegenerateTokenArgs = {
  refreshToken: Scalars['String']
}

export type MutationRejectCertificateArgs = {
  input: RejectCertificateInput
}

export type MutationRejectInterpretationArgs = {
  input: RejectInterpretationInput
}

export type MutationRemoveBankAccountArgs = {
  id: Scalars['String']
}

export type MutationRequestEmailVerificationCodeArgs = {
  input: RequestEmailVerificationCodeInput
}

export type MutationRequestPhoneVerificationCodeArgs = {
  input: RequestPhoneVerificationCodeInput
}

export type MutationResetCertificateArgs = {
  input: ResetCertificateInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationSaveIdentifiedUserArgs = {
  input: SaveIdentifiedUserInput
}

export type MutationSaveInterpretationArgs = {
  input: SaveInterpretationInput
}

export type MutationSetAdministratorArgs = {
  input: SetAdministratorInput
}

export type MutationSetProfileImageArgs = {
  input: SetProfileImageInput
}

export type MutationSetRelationshipWithUserArgs = {
  input: SetRelationshipWithUserInput
}

export type MutationSetUserToDiseasesArgs = {
  input: SetUserToDiseasesInput
}

export type MutationSubmitInterpretationArgs = {
  input: SubmitInterpretationInput
}

export type MutationUpdateAdministratorRoleArgs = {
  input: UpdateAdministratorRoleInput
}

export type MutationUpdateBankAccountArgs = {
  input: UpdateBankAccountInput
}

export type MutationUpdateExaminationArgs = {
  input: UpdateExaminationInput
}

export type MutationUpdateFaqArgs = {
  input: UpdateFaqInput
}

export type MutationUpdateInspectionScoreArgs = {
  input: UpdateInspectionScoreInput
}

export type MutationUpdateInterpretationArgs = {
  input: UpdateInterpretationInput
}

export type MutationUpdateNoticeArgs = {
  input: UpdateNoticeInput
}

export type MutationUpdateSettlementDueDateArgs = {
  input: UpdateSettlementDueDateInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput
}

export type MutationUploadAndCreateFileArgs = {
  input: UploadFileToS3Input
}

export type MutationUpsertBankAccountArgs = {
  input: CreateBankAccountInput
}

export type MutationValidateExaminationArgs = {
  id: Scalars['String']
}

/** [Entity] 공지사항 */
export type Notice = {
  __typename?: 'Notice'
  /** 작성자 */
  author?: Maybe<Administrator>
  authorId?: Maybe<Scalars['String']>
  /** 공지사항 내용 */
  content: Scalars['String']
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  /** 공지사항과 연결된 첨부파일 */
  noticeToFiles?: Maybe<Array<NoticeToFile>>
  /** 공지사항 제목 */
  title: Scalars['String']
  /** 공지사항 유형 */
  type: NoticeType
  updatedAt: Scalars['DateTime']
}

export type NoticeInputType = {
  /** 작성자 */
  author?: InputMaybe<AdministratorInputType>
  authorId?: InputMaybe<Scalars['String']>
  /** 공지사항 내용 */
  content: Scalars['String']
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  /** 공지사항과 연결된 첨부파일 */
  noticeToFiles?: InputMaybe<Array<NoticeToFileInputType>>
  /** 공지사항 제목 */
  title: Scalars['String']
  /** 공지사항 유형 */
  type: NoticeType
  updatedAt: Scalars['DateTime']
}

/** NoticeOffsetEdge */
export type NoticeOffsetEdge = {
  __typename?: 'NoticeOffsetEdge'
  node: Notice
}

/** 공지사항 목록을 정렬할 때 사용할 수 있는 정렬 옵션입니다. */
export enum NoticeSortBy {
  /** 오름차순으로 생성일자 정렬 */
  CreatedAtAsc = 'CreatedAtAsc',
  /** 내림차순으로 생성일자 정렬 */
  CreatedAtDesc = 'CreatedAtDesc',
}

/** [Entity] 공지사항과 연결된 첨부파일 */
export type NoticeToFile = {
  __typename?: 'NoticeToFile'
  createdAt: Scalars['DateTime']
  file: File
  fileId: Scalars['String']
  id: Scalars['String']
  notice: Notice
  noticeId: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type NoticeToFileInputType = {
  createdAt: Scalars['DateTime']
  file: FileInputType
  fileId: Scalars['String']
  id: Scalars['String']
  notice: NoticeInputType
  noticeId: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** 공지사항의 타입을 나타냅니다. */
export enum NoticeType {
  /** 이벤트 */
  Event = 'Event',
  /** 뉴스 */
  News = 'News',
  /** 공지사항 */
  Notice = 'Notice',
}

/** 오프셋 기반 페이지네이션 정보 */
export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo'
  /** 이전 데이터 수 */
  countBefore: Scalars['Int']
  /** 현재 페이지 데이터 수 */
  countCurrent: Scalars['Int']
  /** 다음 데이터 수 */
  countNext: Scalars['Int']
  /** 전체 페이지 데이터 수 */
  countTotal: Scalars['Int']
  /** 다음 페이지 여부 */
  hasNextPage: Scalars['Boolean']
  /** 이전 페이지 여부 */
  hasPreviousPage: Scalars['Boolean']
}

/** 오프셋 기반 페이지네이션 처리된 질병 목록 */
export type OffsetPaginatedDiseases = {
  __typename?: 'OffsetPaginatedDiseases'
  /** 데이터 */
  edges?: Maybe<Array<DiseaseOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 정산 목록 */
export type OffsetPaginatedEarnings = {
  __typename?: 'OffsetPaginatedEarnings'
  /** 데이터 */
  edges?: Maybe<Array<EarningOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 검사지 목록 */
export type OffsetPaginatedExaminations = {
  __typename?: 'OffsetPaginatedExaminations'
  counts?: Maybe<ExaminationsCountsInfo>
  /** 데이터 */
  edges?: Maybe<Array<ExaminationOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 FAQ 목록 */
export type OffsetPaginatedFaqs = {
  __typename?: 'OffsetPaginatedFaqs'
  /** 데이터 */
  edges?: Maybe<Array<FaqOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 병원 목록 */
export type OffsetPaginatedHospitals = {
  __typename?: 'OffsetPaginatedHospitals'
  /** 데이터 */
  edges?: Maybe<Array<HospitalOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 1대1 문의 목록 */
export type OffsetPaginatedInquiries = {
  __typename?: 'OffsetPaginatedInquiries'
  /** 데이터 */
  edges?: Maybe<Array<InquiryOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 해석 목록 */
export type OffsetPaginatedInterpretations = {
  __typename?: 'OffsetPaginatedInterpretations'
  /** 데이터 */
  edges?: Maybe<Array<InterpretationOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 공지사항 목록 */
export type OffsetPaginatedNotices = {
  __typename?: 'OffsetPaginatedNotices'
  /** 데이터 */
  edges?: Maybe<Array<NoticeOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 의료인 목록 */
export type OffsetPaginatedProfessionals = {
  __typename?: 'OffsetPaginatedProfessionals'
  /** 데이터 */
  edges?: Maybe<Array<ProfessionalOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 환자와의 관계 리스트 */
export type OffsetPaginatedRelationships = {
  __typename?: 'OffsetPaginatedRelationships'
  /** 데이터 */
  edges?: Maybe<Array<RelationshipOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 정산 목록 */
export type OffsetPaginatedSettlements = {
  __typename?: 'OffsetPaginatedSettlements'
  /** 데이터 */
  edges?: Maybe<Array<SettlementOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** 오프셋 기반 페이지네이션 처리된 사용자 목록 */
export type OffsetPaginatedUsers = {
  __typename?: 'OffsetPaginatedUsers'
  /** 데이터 */
  edges?: Maybe<Array<UserOffsetEdge>>
  /** 페이지 정보 */
  pageInfo?: Maybe<OffsetPageInfo>
}

/** [Entity] 의료인 */
export type Professional = {
  __typename?: 'Professional'
  certificate?: Maybe<Certificate>
  createdAt: Scalars['DateTime']
  earnings?: Maybe<Array<Earning>>
  /** 의료인 그룹 (의사, 대학생, 간호사 등) */
  group?: Maybe<ProfessionalGroup>
  groupId?: Maybe<Scalars['Int']>
  /** 소속 병원 */
  hospital?: Maybe<Hospital>
  hospitalId?: Maybe<Scalars['String']>
  id: Scalars['String']
  interpretations: Array<Interpretation>
  /** 최근 작성한 해석 */
  latestInterpretation?: Maybe<Interpretation>
  /** 소속 학교 */
  school?: Maybe<School>
  schoolId?: Maybe<Scalars['String']>
  /** 지난 7일 간 작성한 해석 수 */
  sevenDaysInterpretationCount?: Maybe<Scalars['Int']>
  spec?: Maybe<ProfessionalSpec>
  /** 정산 금액 합계 */
  totalEarnings?: Maybe<Scalars['Int']>
  /** 작성한 해석 수 */
  totalInterpretationCount?: Maybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

/** [필터] 모두 필수 */
export type ProfessionalEarningsFilter = {
  settlementId: Scalars['String']
}

/** [Entity] 의료인 그룹(직군) */
export type ProfessionalGroup = {
  __typename?: 'ProfessionalGroup'
  id: Scalars['Int']
  name: Scalars['String']
  professionals: Array<Professional>
}

export type ProfessionalGroupInputType = {
  id: Scalars['Int']
  name: Scalars['String']
  professionals: Array<ProfessionalInputType>
}

export type ProfessionalInputType = {
  certificate?: InputMaybe<CertificateInputType>
  createdAt: Scalars['DateTime']
  earnings?: InputMaybe<Array<EarningInputType>>
  /** 의료인 그룹 (의사, 대학생, 간호사 등) */
  group?: InputMaybe<ProfessionalGroupInputType>
  groupId?: InputMaybe<Scalars['Int']>
  /** 소속 병원 */
  hospital?: InputMaybe<HospitalInputType>
  hospitalId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  interpretations: Array<InterpretationInputType>
  /** 소속 학교 */
  school?: InputMaybe<SchoolInputType>
  schoolId?: InputMaybe<Scalars['String']>
  spec?: InputMaybe<ProfessionalSpecInputType>
  updatedAt: Scalars['DateTime']
  user: UserInputType
  userId: Scalars['String']
}

/** ProfessionalOffsetEdge */
export type ProfessionalOffsetEdge = {
  __typename?: 'ProfessionalOffsetEdge'
  node: Professional
}

/** [필터] 모두 선택 사항 */
export type ProfessionalRegistrationFilter = {
  keyword?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<CertificateStatus>>
}

export type ProfessionalSpec = {
  __typename?: 'ProfessionalSpec'
  /** [의사 - 전공의/전문의] 전공 진료과 */
  doctorMajor?: Maybe<Scalars['String']>
  /** [의사 - 전문의] 직책(펠로우, 교수 등) */
  doctorPosition?: Maybe<Scalars['String']>
  /** [의사] 면허 분류 - 일반의, 인턴, 전공의, 전문의 */
  doctorType?: Maybe<Scalars['String']>
  /** [간호사] 직무(임상 간호사/연구간호사(CRC) */
  nurseType?: Maybe<Scalars['String']>
  /** [기타] 직종 */
  otherJob?: Maybe<Scalars['String']>
  /** [의과 대학생]: 학년(본과 1-4학년, 의전원 2-4학년) */
  studentGrade?: Maybe<Scalars['String']>
  /** [간호사] 근속 년차 */
  yearsOfService?: Maybe<Scalars['String']>
}

export type ProfessionalSpecInputType = {
  /** [의사 - 전공의/전문의] 전공 진료과 */
  doctorMajor?: InputMaybe<Scalars['String']>
  /** [의사 - 전문의] 직책(펠로우, 교수 등) */
  doctorPosition?: InputMaybe<Scalars['String']>
  /** [의사] 면허 분류 - 일반의, 인턴, 전공의, 전문의 */
  doctorType?: InputMaybe<Scalars['String']>
  /** [간호사] 직무(임상 간호사/연구간호사(CRC) */
  nurseType?: InputMaybe<Scalars['String']>
  /** [기타] 직종 */
  otherJob?: InputMaybe<Scalars['String']>
  /** [의과 대학생]: 학년(본과 1-4학년, 의전원 2-4학년) */
  studentGrade?: InputMaybe<Scalars['String']>
  /** [간호사] 근속 년차 */
  yearsOfService?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  checkBankAccount: CoreOutput
  /** 유저의 로그인 상태를 확인합니다. 쿠키 혹은 HTTP Header에 담긴 Access Token의 유효성을 검사합니다. */
  checkLoggedIn: CoreOutput
  downloadProfessionalEarningsExcel: DownloadProfessionalEarningsOutput
  getAllBanks: Array<Bank>
  /** 모든 진단명 목록을 조회합니다. */
  getAllDiseases: Array<Disease>
  /** 모든 FAQ 목록을 카테고리 별로 그룹화하여 조회합니다. */
  getAllFaqCategories: Array<FaqCategory>
  /** 모든 의료영상 타입을 조회합니다. */
  getAllMedicalImagingTypes: Array<MedicalImagingType>
  getAllProfessionalGroups: Array<ProfessionalGroup>
  getBankAccountByUserId: BankAccount
  /** 의료인 등록 정보를 조회합니다. */
  getCertificate: Certificate
  /** professionalId로 의료인 등록 정보를 조회합니다. */
  getCertificateByProfessionalId: Certificate
  /** [관리자] 의료인 등록 진행 상태에 대한 통계를 조회합니다. (total, certified, rejected, wating) */
  getCertificateStatistics: GetCertificateStatisticsOutput
  /** [관리자] 질병별 검사지 수 통계를 조회합니다. */
  getDiseaseStatistics: Array<DiseaseStatisticsOutput>
  /** 질병 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getDiseasesByOffset: OffsetPaginatedDiseases
  getEarningTotalAmount: GetEarningTotalAmountOutput
  getEarningTotalAmountsByCursor: CursorPaginatedEarningsTotalAmount
  getEarningsByCursor: CursorPaginatedEarnings
  getEarningsByOffset: OffsetPaginatedEarnings
  getEarningsStatistics: GetEarningsStatisticsOutput
  getEarningsStatisticsForAdmin: GetEarningsStatisticsForAdminOutput
  /** 검사지를 조회합니다. */
  getExamination: Examination
  /** 유저가 등록한 모든 검사지의 진행 상태 통계를 조회합니다. (total, pending, wating 등) */
  getExaminationCountsByUserId: ExaminationCounts
  /** [관리자] 전체 유저가 등록한 검사지에 관한 통계를 조회합니다. (진행상태 별 수치, 10일 간 등록된 검사지 수 등) */
  getExaminationStatistics: GetExaminationStatisticsOutput
  /** 검사지 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getExaminationsByOffset: OffsetPaginatedExaminations
  /** 진행중인 검사결과지 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getExaminationsInProgressByOffset: OffsetPaginatedExaminations
  /** FAQ를 조회합니다. */
  getFaq: Faq
  /** 등록된 FAQ 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getFaqsByOffset: OffsetPaginatedFaqs
  /** 병원 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getHospitalsByOffset: OffsetPaginatedHospitals
  /** 1대1 문의를 오프셋 기반 페이지네이션으로 조회합니다. */
  getInquiriesByOffset: OffsetPaginatedInquiries
  /** 1대1 문의를 조회합니다. */
  getInquiry: Inquiry
  /** 1대1 문의 진행 상태에 대한 통계를 조회합니다. (total, wating 등) */
  getInquiryStatistics: GetInquiryStatisticsOutput
  getInspection: Inspection
  /** 해석을 조회합니다. */
  getInterpretation: Interpretation
  /** 해석 진행 상태에 관한 통계를 가져옵니다. (total, finished, latestDate 등) */
  getInterpretationCounts: InterpretationStatistics
  /** 해석 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getInterpretationsByOffset: OffsetPaginatedInterpretations
  getLatestSettlement: Settlement
  /** [관리자] 의료영상 타입 별 검사지 수 통계를 조회합니다. */
  getMedicalImagingTypeStatistics: Array<ExaminationToMedicalImagingTypesCountOutput>
  /** 공지사항을 조회합니다. */
  getNotice: Notice
  /** 등록된 공지사항 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getNoticesByOffset: OffsetPaginatedNotices
  /** 정산 지급 예정인 유저 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getProfessionalEarningsByOffset: OffsetPaginatedProfessionals
  /** 의료인 등록 요청 리스트를 오프셋 기반 페이지네이션으로 조회합니다. */
  getProfessionalRegistrationsByOffset: OffsetPaginatedUsers
  /** 질병 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getRelationshipsByOffset: OffsetPaginatedRelationships
  /** 저장한 해석을 조회합니다. */
  getSavedInterpretations: Array<Interpretation>
  getSettlement: Settlement
  getSettlementsByOffset: OffsetPaginatedSettlements
  /** 유저 수를 조회합니다. */
  getTotalUserCount: Scalars['Int']
  /** 유저를 조회합니다. */
  getUser: User
  /** 유저 설정을 조회합니다. */
  getUserSetting: UserSetting
  /** 유저 통계를 조회합니다. (전체 유저 수, 10일 간 생성된 유저 수 등) */
  getUserStatistics: GetUserStatisticsOutput
  /** 유저 목록을 오프셋 기반 페이지네이션으로 조회합니다. */
  getUsersByOffset: OffsetPaginatedUsers
  /** 현재 로그인된 유저의 정보를 조회합니다. */
  me: User
  sendSms: CoreOutput
  /** [관리자] 슬랙 테스트 메시지를 보냅니다. */
  sendTestEmail: Scalars['Boolean']
}

export type QueryCheckBankAccountArgs = {
  input: CheckBankAccountInput
}

export type QueryDownloadProfessionalEarningsExcelArgs = {
  settlementId: Scalars['String']
}

export type QueryGetBankAccountByUserIdArgs = {
  userId: Scalars['String']
}

export type QueryGetCertificateArgs = {
  id: Scalars['String']
}

export type QueryGetCertificateByProfessionalIdArgs = {
  professionalId: Scalars['String']
}

export type QueryGetDiseasesByOffsetArgs = {
  filter?: InputMaybe<DiseaseFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<DiseaseSortBy>
}

export type QueryGetEarningTotalAmountArgs = {
  filter?: InputMaybe<EarningFilter>
}

export type QueryGetEarningTotalAmountsByCursorArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EarningFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortKey?: InputMaybe<EarningSortKey>
}

export type QueryGetEarningsByCursorArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EarningFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortKey?: InputMaybe<EarningSortKey>
}

export type QueryGetEarningsByOffsetArgs = {
  filter?: InputMaybe<EarningFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryGetEarningsStatisticsArgs = {
  professionalId: Scalars['String']
}

export type QueryGetExaminationArgs = {
  id: Scalars['String']
}

export type QueryGetExaminationCountsByUserIdArgs = {
  userId: Scalars['String']
}

export type QueryGetExaminationsByOffsetArgs = {
  filter?: InputMaybe<ExaminationFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<ExaminationSortBy>
}

export type QueryGetExaminationsInProgressByOffsetArgs = {
  filter?: InputMaybe<ExaminationFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<ExaminationSortBy>
}

export type QueryGetFaqArgs = {
  id: Scalars['String']
}

export type QueryGetFaqsByOffsetArgs = {
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<FaqSortBy>
}

export type QueryGetHospitalsByOffsetArgs = {
  filter?: InputMaybe<HospitalFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryGetInquiriesByOffsetArgs = {
  filter?: InputMaybe<InquiryFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<InquirySortBy>
}

export type QueryGetInquiryArgs = {
  id: Scalars['String']
}

export type QueryGetInspectionArgs = {
  id: Scalars['String']
}

export type QueryGetInterpretationArgs = {
  id: Scalars['String']
}

export type QueryGetInterpretationCountsArgs = {
  professionalId: Scalars['String']
}

export type QueryGetInterpretationsByOffsetArgs = {
  filter?: InputMaybe<InterpretationFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<InterpretationSortBy>
}

export type QueryGetNoticeArgs = {
  id: Scalars['String']
}

export type QueryGetNoticesByOffsetArgs = {
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<NoticeSortBy>
}

export type QueryGetProfessionalEarningsByOffsetArgs = {
  filter: ProfessionalEarningsFilter
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryGetProfessionalRegistrationsByOffsetArgs = {
  filter?: InputMaybe<ProfessionalRegistrationFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<UserSortBy>
}

export type QueryGetRelationshipsByOffsetArgs = {
  filter?: InputMaybe<RelationshipFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<RelationshipSortBy>
}

export type QueryGetSavedInterpretationsArgs = {
  input: GetSavedInterpretationsInput
}

export type QueryGetSettlementArgs = {
  filter: SettlementFilter
}

export type QueryGetSettlementsByOffsetArgs = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryGetUserArgs = {
  id: Scalars['String']
}

export type QueryGetUsersByOffsetArgs = {
  filter?: InputMaybe<UserFilter>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<UserSortBy>
}

export type QuerySendSmsArgs = {
  message: Scalars['String']
  to: Scalars['String']
}

/** 모두 필수 */
export type RegenerateTokenOutput = {
  __typename?: 'RegenerateTokenOutput'
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
}

/** 모두 필수 */
export type RejectCertificateInput = {
  id: Scalars['String']
  rejectionReason: Scalars['String']
}

/** 모두 필수 */
export type RejectInterpretationInput = {
  id: Scalars['String']
  /** 해석 승인 거절 사유 */
  rejectionReason?: InputMaybe<Scalars['String']>
}

/** [Entity] 환자와의 관계 */
export type Relationship = {
  __typename?: 'Relationship'
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  /** 주요 관계 여부 */
  isMajor: Scalars['Boolean']
  name: Scalars['String']
  updatedAt: Scalars['DateTime']
  users?: Maybe<Array<User>>
}

/** [필터] 모두 선택 사항 */
export type RelationshipFilter = {
  /** 주요 관계 여부 */
  isMajor?: InputMaybe<Scalars['Boolean']>
  /** 검색어 */
  keyword?: InputMaybe<Scalars['String']>
}

export type RelationshipInputType = {
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  /** 주요 관계 여부 */
  isMajor: Scalars['Boolean']
  name: Scalars['String']
  updatedAt: Scalars['DateTime']
  users?: InputMaybe<Array<UserInputType>>
}

/** RelationshipOffsetEdge */
export type RelationshipOffsetEdge = {
  __typename?: 'RelationshipOffsetEdge'
  node: Relationship
}

/** 환자와의 관계를 정렬할 때 사용할 수 있는 옵션입니다. */
export enum RelationshipSortBy {
  /** 오름차순으로 생성일자 정렬 */
  CreatedAtAsc = 'CreatedAtAsc',
  /** 내림차순으로 생성일자 정렬 */
  CreatedAtDesc = 'CreatedAtDesc',
  /** 오름차순으로 ID 정렬 */
  IdAsc = 'IdAsc',
  /** 내림차순으로 ID 정렬 */
  IdDesc = 'IdDesc',
  /** 오름차순으로 이름 정렬 */
  NameAsc = 'NameAsc',
  /** 내림차순으로 이름 정렬 */
  NameDesc = 'NameDesc',
}

/** 모두 필수 */
export type RequestEmailVerificationCodeInput = {
  email: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

/** 이메일 인증 코드 요청 Output */
export type RequestEmailVerificationCodeOutput = {
  __typename?: 'RequestEmailVerificationCodeOutput'
  /** 에러 메시지 */
  error?: Maybe<Scalars['String']>
  /** 성공 여부 */
  ok: Scalars['Boolean']
}

/** 모두 필수 */
export type RequestPhoneVerificationCodeInput = {
  phoneNumber: Scalars['String']
}

/** 휴대폰 인증 코드 요청 Output */
export type RequestPhoneVerificationCodeOutput = {
  __typename?: 'RequestPhoneVerificationCodeOutput'
  /** 에러 메시지 */
  error?: Maybe<Scalars['String']>
  /** 성공 여부 */
  ok: Scalars['Boolean']
}

/** 모두 필수 */
export type ResetCertificateInput = {
  id: Scalars['String']
}

/** 모두 필수 */
export type ResetPasswordInput = {
  email?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}

/** 비밀번호 재설정 Output */
export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput'
  /** 에러 메시지 */
  error?: Maybe<Scalars['String']>
  /** 성공 여부 */
  ok: Scalars['Boolean']
}

/** 모두 필수 */
export type SaveIdentifiedUserInput = {
  id: Scalars['String']
  /** 아임포트 UID */
  impUid?: InputMaybe<Scalars['String']>
  userId: Scalars['String']
}

/** 모두 필수 */
export type SaveInterpretationInput = {
  /** 해석 내용 */
  content?: InputMaybe<Scalars['String']>
  id: Scalars['String']
}

/** [Entity] 학교 */
export type School = {
  __typename?: 'School'
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  image?: Maybe<Scalars['String']>
  name: Scalars['String']
  professionals: Array<Professional>
  updatedAt: Scalars['DateTime']
}

export type SchoolInputType = {
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  image?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  professionals: Array<ProfessionalInputType>
  updatedAt: Scalars['DateTime']
}

/**
 * 필수: email
 *
 * 선택: role (Default: Basic)
 */
export type SetAdministratorInput = {
  email?: InputMaybe<Scalars['String']>
  /** 관리자 권한 */
  role?: InputMaybe<AdminRole>
}

/** 모두 필수 */
export type SetProfileImageInput = {
  /** 프로필 이미지 파일 객체 */
  file: Scalars['Upload']
}

/** 모두 필수 */
export type SetRelationshipWithUserInput = {
  name: Scalars['String']
}

/** 모두 필수 */
export type SetUserToDiseasesInput = {
  diseaseIds: Array<Scalars['Int']>
}

/** [Entity] 정산금 결산 내역 */
export type Settlement = {
  __typename?: 'Settlement'
  createdAt: Scalars['DateTime']
  /** 정산 예정일 */
  dueDate?: Maybe<Scalars['DateTime']>
  earnings: Array<Earning>
  /** 정산 종료 기준일 */
  endDate: Scalars['DateTime']
  id: Scalars['String']
  /** 정산 회차 */
  numberOfTimes?: Maybe<Scalars['Int']>
  /** 정산 지급일 */
  paidAt?: Maybe<Scalars['DateTime']>
  /** 정산 시작 기준일 */
  startDate: Scalars['DateTime']
  /** 총 지불 금액 */
  totalAmount?: Maybe<Scalars['Int']>
  /** 정산 지급 대상 해석 수 */
  totalCount?: Maybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
}

/** [필터] 모두 선택 사항 */
export type SettlementFilter = {
  professionalId?: InputMaybe<Scalars['String']>
  settlementId?: InputMaybe<Scalars['String']>
}

export type SettlementInputType = {
  createdAt: Scalars['DateTime']
  /** 정산 예정일 */
  dueDate?: InputMaybe<Scalars['DateTime']>
  earnings?: InputMaybe<Array<EarningInputType>>
  /** 정산 종료 기준일 */
  endDate: Scalars['DateTime']
  id: Scalars['String']
  /** 정산 회차 */
  numberOfTimes?: InputMaybe<Scalars['Int']>
  /** 정산 지급일 */
  paidAt?: InputMaybe<Scalars['DateTime']>
  /** 정산 시작 기준일 */
  startDate: Scalars['DateTime']
  /** 총 지불 금액 */
  totalAmount?: InputMaybe<Scalars['Int']>
  /** 정산 지급 대상 해석 수 */
  totalCount?: InputMaybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
}

/** SettlementOffsetEdge */
export type SettlementOffsetEdge = {
  __typename?: 'SettlementOffsetEdge'
  node: Settlement
}

/** 모두 필수 */
export type SubmitInterpretationInput = {
  /** 해석 내용 */
  content?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  /** 해석 내용(HTML태그 제거됨) */
  plainContent?: InputMaybe<Scalars['String']>
}

/** 모두 필수 */
export type UpdateAdministratorRoleInput = {
  email?: InputMaybe<Scalars['String']>
  /** 관리자 권한 */
  role?: InputMaybe<AdminRole>
}

/** 모두 필수 */
export type UpdateBankAccountInput = {
  /** 예금주 */
  accountHolder: Scalars['String']
  /** 계좌 번호 */
  accountNumber: Scalars['String']
  bankId: Scalars['Int']
  id: Scalars['String']
}

/**
 * 필수: id, patientId
 *
 * 선택: description, examinationDate, diseaseIds, medicalImagingTypeIds, fileIds
 */
export type UpdateExaminationInput = {
  /** 검사지 상세 설명 */
  description?: InputMaybe<Scalars['String']>
  diseaseIds?: InputMaybe<Array<Scalars['Int']>>
  /** 검사 날짜 */
  examinationDate?: InputMaybe<Scalars['DateTime']>
  /** 파일 객체 리스트 */
  files?: InputMaybe<Array<Scalars['Upload']>>
  hospitalName?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  medicalImagingTypeIds?: InputMaybe<Array<Scalars['Int']>>
  /** 검사지 제목 */
  title?: InputMaybe<Scalars['String']>
}

/** 모두 필수 */
export type UpdateFaqInput = {
  /** FAQ 카테고리 */
  categoryId: Scalars['Int']
  /** 상세 내용 */
  description: Scalars['String']
  id: Scalars['String']
  /** 제목 */
  title: Scalars['String']
}

/** 모두 필수 */
export type UpdateInspectionScoreInput = {
  /** 검수 정확도 점수 */
  accuracyScore: Scalars['Int']
  /** 규정 준수 점수 */
  complianceScore: Scalars['Int']
  /** 표현 점수 */
  expressionScore: Scalars['Int']
  /** 정직성 점수 */
  honestyScore: Scalars['Int']
  id: Scalars['String']
}

/** 모두 필수 */
export type UpdateInterpretationInput = {
  /** 해석 내용 */
  content?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  /** 해석 내용(HTML태그 제거됨) */
  plainContent?: InputMaybe<Scalars['String']>
}

/** 모두 필수 */
export type UpdateNoticeInput = {
  authorId?: InputMaybe<Scalars['String']>
  /** 공지사항 내용 */
  content: Scalars['String']
  id: Scalars['String']
  /** 공지사항 제목 */
  title: Scalars['String']
  /** 공지사항 유형 */
  type: NoticeType
}

export type UpdateSettlementDueDateInput = {
  /** 정산 예정일 */
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['String']
}

/**
 *
 *   필수: id
 *   선택: name, nickname, email, phoneNumber, profileImage, role
 *
 */
export type UpdateUserInput = {
  /** 계정 정지된 시간 */
  blockedAt?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  /** 마지막 로그인 시간 */
  lastLoginAt?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  nickname?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  /** 프로필 이미지 */
  profileImage?: InputMaybe<FileInputType>
  role?: InputMaybe<UserRole>
}

/**
 *
 *   필수: id
 *
 *   선택: promotionEmailNotification, promotionSmsNotification, serviceEmailNotification, serviceSmsNotification
 *
 */
export type UpdateUserSettingsInput = {
  id: Scalars['String']
  /** 마케팅 이메일 알림 설정 */
  promotionEmailNotification?: InputMaybe<Scalars['Boolean']>
  /** 마케팅 SMS 알림 설정 */
  promotionSmsNotification?: InputMaybe<Scalars['Boolean']>
  /** 서비스 이용 이메일 알림 설정 */
  serviceEmailNotification?: InputMaybe<Scalars['Boolean']>
  /** 서비스 이용 SMS 알림 설정 */
  serviceSmsNotification?: InputMaybe<Scalars['Boolean']>
}

/** 모두 필수 */
export type UploadFileToS3Input = {
  files: Array<Scalars['Upload']>
  type: UploadType
}

/** S3 스토리지에 업로드할 파일의 타입을 나타냅니다. */
export enum UploadType {
  /** 의료인 증명서 */
  Certificate = 'Certificate',
  /** 검사지 자료 */
  ExaminationResource = 'ExaminationResource',
  /** 회원용 1대1 문의 첨부자료 */
  InquiryByMember = 'InquiryByMember',
  /** 비회원용 1대1 문의 첨부자료 */
  InquiryByNonMember = 'InquiryByNonMember',
  /** 공지사항 */
  Notice = 'Notice',
  /** 프로필 이미지 */
  ProfileImage = 'ProfileImage',
}

/** [Entity] 사용자 */
export type User = {
  __typename?: 'User'
  /** 관리자 */
  administrator?: Maybe<Administrator>
  /** 은행 계좌 */
  bankAccount?: Maybe<BankAccount>
  /** 계정 정지된 시간 */
  blockedAt?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  email?: Maybe<Scalars['String']>
  /** 환자가 등록한 검사지 */
  examinations: Array<Examination>
  /** 유저가 업로드한 파일(검사지 사진, 첨부파일, 프로필 사진 등) */
  files: Array<File>
  id: Scalars['String']
  /** 본인 인증 */
  identification?: Maybe<Identification>
  /** 1대1 문의 */
  inquiries?: Maybe<Array<Inquiry>>
  /** 마지막 로그인 시간 */
  lastLoginAt?: Maybe<Scalars['DateTime']>
  /** 최근 검사지 */
  latestExamination?: Maybe<Examination>
  name?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  /** 의료인 */
  professional?: Maybe<Professional>
  professionalId?: Maybe<Scalars['String']>
  /** 프로필 이미지 */
  profileImage?: Maybe<File>
  profileImageId?: Maybe<Scalars['String']>
  /** 유저에게 공개되는 아이디 */
  publicId: Scalars['Int']
  /** 환자와의 관계 */
  relationship?: Maybe<Relationship>
  relationshipId?: Maybe<Scalars['String']>
  role: UserRole
  /** 설정 */
  setting?: Maybe<UserSetting>
  updatedAt: Scalars['DateTime']
  /** 관심 질환 */
  userToDiseases?: Maybe<Array<UserToDisease>>
}

/** [필터] 모두 선택 사항 */
export type UserFilter = {
  /** 관련 질병 */
  diseaseIds?: InputMaybe<Array<Scalars['Int']>>
  /** 환자/보호자 */
  hasRelationship?: InputMaybe<Scalars['Boolean']>
  keyword?: InputMaybe<Scalars['String']>
  /** 유저 역할 */
  roles?: InputMaybe<Array<UserRole>>
  /** 탈퇴한 회원 포함 여부 */
  withDeleted?: InputMaybe<Scalars['Boolean']>
}

export type UserInputType = {
  /** 관리자 */
  administrator?: InputMaybe<AdministratorInputType>
  /** 은행 계좌 */
  bankAccount?: InputMaybe<BankAccountInputType>
  /** 계정 정지된 시간 */
  blockedAt?: InputMaybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  deletedAt?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  /** 환자가 등록한 검사지 */
  examinations: Array<ExaminationInputType>
  /** 유저가 업로드한 파일(검사지 사진, 첨부파일, 프로필 사진 등) */
  files: Array<FileInputType>
  id: Scalars['String']
  /** 본인 인증 */
  identification?: InputMaybe<IdentificationInputType>
  /** 1대1 문의 */
  inquiries?: InputMaybe<Array<InquiryInputType>>
  /** 마지막 로그인 시간 */
  lastLoginAt?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  nickname?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  /** 의료인 */
  professional?: InputMaybe<ProfessionalInputType>
  /** 프로필 이미지 */
  profileImage?: InputMaybe<FileInputType>
  profileImageId?: InputMaybe<Scalars['String']>
  /** 유저에게 공개되는 아이디 */
  publicId: Scalars['Int']
  /** 환자와의 관계 */
  relationship?: InputMaybe<RelationshipInputType>
  relationshipId?: InputMaybe<Scalars['String']>
  role: UserRole
  /** 설정 */
  setting?: InputMaybe<UserSettingType>
  updatedAt: Scalars['DateTime']
  /** 관심 질환 */
  userToDiseases?: InputMaybe<Array<UserToDiseasesInput>>
}

/** UserOffsetEdge */
export type UserOffsetEdge = {
  __typename?: 'UserOffsetEdge'
  node: User
}

/** 사용자의 역할을 나타냅니다. */
export enum UserRole {
  /** 관리자 */
  Admin = 'Admin',
  /** 환자 */
  Patient = 'Patient',
  /** 의료인 */
  Professional = 'Professional',
}

/** [Entity] 사용자 설정 정보 */
export type UserSetting = {
  __typename?: 'UserSetting'
  id: Scalars['String']
  /** 마케팅 이메일 알림 설정 */
  promotionEmailNotification: Scalars['Boolean']
  /** 마케팅 SMS 알림 설정 */
  promotionSmsNotification: Scalars['Boolean']
  /** 서비스 이용 이메일 알림 설정 */
  serviceEmailNotification: Scalars['Boolean']
  /** 서비스 이용 SMS 알림 설정 */
  serviceSmsNotification: Scalars['Boolean']
  user: User
  userId: Scalars['String']
}

export type UserSettingType = {
  id: Scalars['String']
  /** 마케팅 이메일 알림 설정 */
  promotionEmailNotification: Scalars['Boolean']
  /** 마케팅 SMS 알림 설정 */
  promotionSmsNotification: Scalars['Boolean']
  /** 서비스 이용 이메일 알림 설정 */
  serviceEmailNotification: Scalars['Boolean']
  /** 서비스 이용 SMS 알림 설정 */
  serviceSmsNotification: Scalars['Boolean']
  user?: InputMaybe<UserInputType>
  userId: Scalars['String']
}

/** 사용자를 정렬할 때 사용할 수 있는 정렬 옵션입니다. */
export enum UserSortBy {
  /** 오름차순으로 생성일자 정렬 */
  CreatedAtAsc = 'CreatedAtAsc',
  /** 내림차순으로 생성일자 정렬 */
  CreatedAtDesc = 'CreatedAtDesc',
  /** 오름차순으로 이메일 정렬 */
  EmailAsc = 'EmailAsc',
  /** 내림차순으로 이메일 정렬 */
  EmailDesc = 'EmailDesc',
  /** 오름차순으로 group(분류) 정렬 */
  GroupAsc = 'GroupAsc',
  /** 내림차순으로 group(분류) 정렬 */
  GroupDesc = 'GroupDesc',
  /** 오름차순으로 해석 수 정렬 */
  InterpretationCountAsc = 'InterpretationCountAsc',
  /** 내림차순으로 해석 수 정렬 */
  InterpretationCountDesc = 'InterpretationCountDesc',
  /** 오름차순으로 해석 수 정렬 */
  InterpretationSevenDaysCountAsc = 'InterpretationSevenDaysCountAsc',
  /** 내림차순으로 해석 수 정렬 */
  InterpretationSevenDaysCountDesc = 'InterpretationSevenDaysCountDesc',
  /** 내림차순으로 마지막 해석일 정렬 */
  LastInterpretationAtAsc = 'LastInterpretationAtAsc',
  /** 내림차순으로 마지막 해석일 정렬 */
  LastInterpretationAtDesc = 'LastInterpretationAtDesc',
  /** 오름차순으로 마지막 로그인 정렬 */
  LastLoginAtAsc = 'LastLoginAtAsc',
  /** 내림차순으로 마지막 로그인 정렬 */
  LastLoginAtDesc = 'LastLoginAtDesc',
  /** 오름차순으로 Nickname 정렬 */
  NicknameAsc = 'NicknameAsc',
  /** 내림차순으로 Nickname 정렬 */
  NicknameDesc = 'NicknameDesc',
  /** 오름차순으로 Public ID 정렬 */
  PublicIdAsc = 'PublicIdAsc',
  /** 내림차순으로 Public ID 정렬 */
  PublicIdDesc = 'PublicIdDesc',
  /** 오름차순으로 환자와의 관계 정렬 */
  RelationshipAsc = 'RelationshipAsc',
  /** 내림차순으로 환자와의 관계 정렬 */
  RelationshipDesc = 'RelationshipDesc',
  /** 오름차순으로 신청일 정렬 */
  RequestedAtAsc = 'RequestedAtAsc',
  /** 내림차순으로 신청일 정렬 */
  RequestedAtDesc = 'RequestedAtDesc',
  SevenDaysCountAsc = 'SevenDaysCountAsc',
  SevenDaysCountDesc = 'SevenDaysCountDesc',
  /** 오름차순으로 상태 정렬 */
  StatusAsc = 'StatusAsc',
  /** 내림차순으로 상태 정렬 */
  StatusDesc = 'StatusDesc',
  TotalCountAsc = 'TotalCountAsc',
  TotalCountDesc = 'TotalCountDesc',
  /** 오름차순으로 총 정산 금액 정렬 */
  TotalEarningsAsc = 'TotalEarningsAsc',
  /** 내림차순으로 총 정산 금액 정렬 */
  TotalEarningsDesc = 'TotalEarningsDesc',
}

/** 유저의 관심 질환 */
export type UserToDisease = {
  __typename?: 'UserToDisease'
  createdAt: Scalars['DateTime']
  disease: Disease
  diseaseId: Scalars['Int']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

export type UserToDiseasesInput = {
  createdAt: Scalars['DateTime']
  disease: DiseaseInputType
  diseaseId: Scalars['Int']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: UserInputType
  userId: Scalars['String']
}

export type AdminPartsFragment = {
  __typename?: 'Administrator'
  id: string
  role?: AdminRole | null
}

export type CreateAdministratorMutationVariables = Exact<{
  input: CreateAdministratorInput
}>

export type CreateAdministratorMutation = {
  __typename?: 'Mutation'
  createAdministrator: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    administrator?: {
      __typename?: 'Administrator'
      id: string
      role?: AdminRole | null
    } | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type SetAdministratorMutationVariables = Exact<{
  input: SetAdministratorInput
}>

export type SetAdministratorMutation = {
  __typename?: 'Mutation'
  setAdministrator: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    administrator?: {
      __typename?: 'Administrator'
      id: string
      role?: AdminRole | null
    } | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type UpdateAdministratorRoleMutationVariables = Exact<{
  input: UpdateAdministratorRoleInput
}>

export type UpdateAdministratorRoleMutation = {
  __typename?: 'Mutation'
  updateAdministratorRole: {
    __typename?: 'Administrator'
    id: string
    role?: AdminRole | null
  }
}

export type LoginMutationVariables = Exact<{
  input: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: { __typename?: 'LoginOutput'; accessToken: string }
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = {
  __typename?: 'Mutation'
  logout: { __typename?: 'LogoutOutput'; ok: boolean }
}

export type BankAccountPartsFragment = {
  __typename?: 'BankAccount'
  id: string
  accountHolder: string
  accountNumber: string
  bankId: number
  createdAt: any
  updatedAt: any
  bank: { __typename?: 'Bank'; id: number; code: string; name: string }
}

export type GetBankAccountByUserIdQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetBankAccountByUserIdQuery = {
  __typename?: 'Query'
  bankAccount: {
    __typename?: 'BankAccount'
    id: string
    accountHolder: string
    accountNumber: string
    bankId: number
    createdAt: any
    updatedAt: any
    bank: { __typename?: 'Bank'; id: number; code: string; name: string }
  }
}

export type CreateBankAccountMutationVariables = Exact<{
  input: CreateBankAccountInput
}>

export type CreateBankAccountMutation = {
  __typename?: 'Mutation'
  createBankAccount: {
    __typename?: 'BankAccount'
    id: string
    accountHolder: string
    accountNumber: string
    bankId: number
    createdAt: any
    updatedAt: any
    bank: { __typename?: 'Bank'; id: number; code: string; name: string }
  }
}

export type UpdateBankAccountMutationVariables = Exact<{
  input: UpdateBankAccountInput
}>

export type UpdateBankAccountMutation = {
  __typename?: 'Mutation'
  updateBankAccount: {
    __typename?: 'BankAccount'
    id: string
    accountHolder: string
    accountNumber: string
    bankId: number
    createdAt: any
    updatedAt: any
    bank: { __typename?: 'Bank'; id: number; code: string; name: string }
  }
}

export type RemoveBankAccountMutationVariables = Exact<{
  removeBankAccountId: Scalars['String']
}>

export type RemoveBankAccountMutation = {
  __typename?: 'Mutation'
  removeBankAccount: { __typename?: 'CoreOutput'; ok: boolean }
}

export type UpsertBankAccountMutationVariables = Exact<{
  input: CreateBankAccountInput
}>

export type UpsertBankAccountMutation = {
  __typename?: 'Mutation'
  upsertBankAccount: {
    __typename?: 'BankAccount'
    id: string
    accountHolder: string
    accountNumber: string
    bankId: number
    createdAt: any
    updatedAt: any
    bank: { __typename?: 'Bank'; id: number; code: string; name: string }
  }
}

export type GetAllBanksQueryVariables = Exact<{ [key: string]: never }>

export type GetAllBanksQuery = {
  __typename?: 'Query'
  banks: Array<{ __typename?: 'Bank'; id: number; code: string; name: string }>
}

export type CertificatePartsFragment = {
  __typename?: 'Certificate'
  id: string
  status: CertificateStatus
  createdAt: any
  updatedAt: any
  validatedAt?: any | null
  rejectionReason?: string | null
  validator?: {
    __typename?: 'Administrator'
    id: string
    user: {
      __typename?: 'User'
      id: string
      nickname?: string | null
      email?: string | null
    }
  } | null
  certificateToFiles: Array<{
    __typename?: 'CertificateToFile'
    id: string
    file: {
      __typename?: 'File'
      id: string
      key: string
      originName: string
      size?: number | null
    }
  }>
}

export type ApproveCertificateMutationVariables = Exact<{
  input: ApproveCertificateInput
}>

export type ApproveCertificateMutation = {
  __typename?: 'Mutation'
  approveCertificate: {
    __typename?: 'Certificate'
    id: string
    status: CertificateStatus
    validatorId?: string | null
    validatedAt?: any | null
    validator?: {
      __typename?: 'Administrator'
      id: string
      user: {
        __typename?: 'User'
        id: string
        email?: string | null
        nickname?: string | null
      }
    } | null
  }
}

export type RejectCertificateMutationVariables = Exact<{
  input: RejectCertificateInput
}>

export type RejectCertificateMutation = {
  __typename?: 'Mutation'
  rejectCertificate: {
    __typename?: 'Certificate'
    id: string
    status: CertificateStatus
  }
}

export type ResetCertificateMutationVariables = Exact<{
  input: ResetCertificateInput
}>

export type ResetCertificateMutation = {
  __typename?: 'Mutation'
  resetCertificate: {
    __typename?: 'Certificate'
    id: string
    status: CertificateStatus
  }
}

export type GetCertificateByProfessionalIdQueryVariables = Exact<{
  professionalId: Scalars['String']
}>

export type GetCertificateByProfessionalIdQuery = {
  __typename?: 'Query'
  getCertificateByProfessionalId: {
    __typename?: 'Certificate'
    id: string
    createdAt: any
    status: CertificateStatus
  }
}

export type DiseasePartsFragment = {
  __typename?: 'Disease'
  id: number
  name: string
  image?: string | null
  isMajor: boolean
}

export type GetAllDiseasesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllDiseasesQuery = {
  __typename?: 'Query'
  allDiseases: Array<{
    __typename?: 'Disease'
    id: number
    name: string
    image?: string | null
    isMajor: boolean
  }>
}

export type GetDiseasesByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  filter?: InputMaybe<DiseaseFilter>
  offset: Scalars['Int']
  sortBy?: InputMaybe<DiseaseSortBy>
}>

export type GetDiseasesByOffsetQuery = {
  __typename?: 'Query'
  diseases: {
    __typename?: 'OffsetPaginatedDiseases'
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
    edges?: Array<{
      __typename?: 'DiseaseOffsetEdge'
      node: {
        __typename?: 'Disease'
        id: number
        name: string
        image?: string | null
        isMajor: boolean
      }
    }> | null
  }
}

export type EarningPartsFragment = {
  __typename?: 'Earning'
  id: string
  amounts: number
  interpretationId: string
  professionalId: string
  createdAt: any
  updatedAt: any
  settlement?: {
    __typename?: 'Settlement'
    id: string
    paidAt?: any | null
  } | null
  interpretation: {
    __typename?: 'Interpretation'
    id: string
    status: InterpretationStatus
    contentLength?: number | null
    correctedContentLength?: number | null
    examination: {
      __typename?: 'Examination'
      id: string
      publicId: number
      status: ExaminationStatus
      title?: string | null
      createdAt: any
      patient?: {
        __typename?: 'User'
        id: string
        publicId: number
        nickname?: string | null
      } | null
      examinationToFiles: Array<{
        __typename?: 'ExaminationToFile'
        id: string
      }>
      examinationToDiseases: Array<{
        __typename?: 'ExaminationToDisease'
        id: string
        examinationId: string
        diseaseId: number
        createdAt: any
        updatedAt: any
        disease: {
          __typename?: 'Disease'
          id: number
          name: string
          image?: string | null
          isMajor: boolean
        }
      }>
      examinationToMedicalImagingTypes: Array<{
        __typename?: 'ExaminationToMedicalImagingType'
        id: string
        examinationId: string
        medicalImagingTypeId: number
        createdAt: any
        updatedAt: any
        medicalImagingType: {
          __typename?: 'MedicalImagingType'
          id: number
          name: string
          image?: string | null
        }
      }>
    }
  }
  professional: {
    __typename?: 'Professional'
    id: string
    user: {
      __typename?: 'User'
      id: string
      email?: string | null
      nickname?: string | null
      publicId: number
    }
  }
}

export type GetEarningssByCursorQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EarningFilter>
}>

export type GetEarningssByCursorQuery = {
  __typename?: 'Query'
  getEarningsByCursor: {
    __typename?: 'CursorPaginatedEarnings'
    pageInfo?: {
      __typename?: 'CursorPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
      endCursor: string
      startCursor: string
    } | null
    edges?: Array<{
      __typename?: 'EarningCursorEdge'
      cursor: string
      node: {
        __typename?: 'Earning'
        id: string
        amounts: number
        interpretationId: string
        professionalId: string
        createdAt: any
        updatedAt: any
        settlement?: {
          __typename?: 'Settlement'
          id: string
          paidAt?: any | null
        } | null
        interpretation: {
          __typename?: 'Interpretation'
          id: string
          status: InterpretationStatus
          contentLength?: number | null
          correctedContentLength?: number | null
          examination: {
            __typename?: 'Examination'
            id: string
            publicId: number
            status: ExaminationStatus
            title?: string | null
            createdAt: any
            patient?: {
              __typename?: 'User'
              id: string
              publicId: number
              nickname?: string | null
            } | null
            examinationToFiles: Array<{
              __typename?: 'ExaminationToFile'
              id: string
            }>
            examinationToDiseases: Array<{
              __typename?: 'ExaminationToDisease'
              id: string
              examinationId: string
              diseaseId: number
              createdAt: any
              updatedAt: any
              disease: {
                __typename?: 'Disease'
                id: number
                name: string
                image?: string | null
                isMajor: boolean
              }
            }>
            examinationToMedicalImagingTypes: Array<{
              __typename?: 'ExaminationToMedicalImagingType'
              id: string
              examinationId: string
              medicalImagingTypeId: number
              createdAt: any
              updatedAt: any
              medicalImagingType: {
                __typename?: 'MedicalImagingType'
                id: number
                name: string
                image?: string | null
              }
            }>
          }
        }
        professional: {
          __typename?: 'Professional'
          id: string
          user: {
            __typename?: 'User'
            id: string
            email?: string | null
            nickname?: string | null
            publicId: number
          }
        }
      }
    }> | null
  }
}

export type GetEarningTotalAmountsByCursorQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
  after?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EarningFilter>
  sortKey?: InputMaybe<EarningSortKey>
}>

export type GetEarningTotalAmountsByCursorQuery = {
  __typename?: 'Query'
  getEarningTotalAmountsByCursor: {
    __typename?: 'CursorPaginatedEarningsTotalAmount'
    pageInfo?: {
      __typename?: 'CursorPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
      endCursor: string
      startCursor: string
    } | null
    edges?: Array<{
      __typename?: 'GetEarningsTotalAmountOutputCursorEdge'
      cursor: string
      node: {
        __typename?: 'GetEarningsTotalAmountOutput'
        totalCount?: number | null
        totalAmount?: number | null
        settlementId: string
        paidAt?: any | null
        startDate: any
        endDate: any
        dueDate?: any | null
      }
    }> | null
  }
}

export type GetEarningTotalAmountQueryVariables = Exact<{
  filter?: InputMaybe<EarningFilter>
}>

export type GetEarningTotalAmountQuery = {
  __typename?: 'Query'
  getEarningTotalAmount: {
    __typename?: 'GetEarningTotalAmountOutput'
    totalAmount: number
  }
}

export type GetEarningsStatisticsQueryVariables = Exact<{
  professionalId: Scalars['String']
}>

export type GetEarningsStatisticsQuery = {
  __typename?: 'Query'
  getEarningsStatistics: {
    __typename?: 'GetEarningsStatisticsOutput'
    totalCount: number
    paidAmount: number
    unpaidAmount: number
    nextSettlementDate?: any | null
    unpaidCount: number
    weekCount: number
    monthCount: number
    settlementCount: number
  }
}

export type GetEarningsStatisticsForAdminQueryVariables = Exact<{
  [key: string]: never
}>

export type GetEarningsStatisticsForAdminQuery = {
  __typename?: 'Query'
  getEarningsStatisticsForAdmin: {
    __typename?: 'GetEarningsStatisticsForAdminOutput'
    nextSettlementDate?: any | null
    paidAmount?: number | null
    unpaidAmount?: number | null
  }
}

export type GetEarningsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  filter?: InputMaybe<EarningFilter>
  offset: Scalars['Int']
}>

export type GetEarningsByOffsetQuery = {
  __typename?: 'Query'
  earnings: {
    __typename?: 'OffsetPaginatedEarnings'
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
    edges?: Array<{
      __typename?: 'EarningOffsetEdge'
      node: {
        __typename?: 'Earning'
        id: string
        amounts: number
        interpretationId: string
        professionalId: string
        createdAt: any
        updatedAt: any
        settlement?: {
          __typename?: 'Settlement'
          id: string
          paidAt?: any | null
        } | null
        interpretation: {
          __typename?: 'Interpretation'
          id: string
          status: InterpretationStatus
          contentLength?: number | null
          correctedContentLength?: number | null
          examination: {
            __typename?: 'Examination'
            id: string
            publicId: number
            status: ExaminationStatus
            title?: string | null
            createdAt: any
            patient?: {
              __typename?: 'User'
              id: string
              publicId: number
              nickname?: string | null
            } | null
            examinationToFiles: Array<{
              __typename?: 'ExaminationToFile'
              id: string
            }>
            examinationToDiseases: Array<{
              __typename?: 'ExaminationToDisease'
              id: string
              examinationId: string
              diseaseId: number
              createdAt: any
              updatedAt: any
              disease: {
                __typename?: 'Disease'
                id: number
                name: string
                image?: string | null
                isMajor: boolean
              }
            }>
            examinationToMedicalImagingTypes: Array<{
              __typename?: 'ExaminationToMedicalImagingType'
              id: string
              examinationId: string
              medicalImagingTypeId: number
              createdAt: any
              updatedAt: any
              medicalImagingType: {
                __typename?: 'MedicalImagingType'
                id: number
                name: string
                image?: string | null
              }
            }>
          }
        }
        professional: {
          __typename?: 'Professional'
          id: string
          user: {
            __typename?: 'User'
            id: string
            email?: string | null
            nickname?: string | null
            publicId: number
          }
        }
      }
    }> | null
  }
}

export type ExaminationToDiseasePartsFragment = {
  __typename?: 'ExaminationToDisease'
  id: string
  examinationId: string
  diseaseId: number
  createdAt: any
  updatedAt: any
  disease: {
    __typename?: 'Disease'
    id: number
    name: string
    image?: string | null
    isMajor: boolean
  }
}

export type ExaminationToMedicalImagingTypePartsFragment = {
  __typename?: 'ExaminationToMedicalImagingType'
  id: string
  examinationId: string
  medicalImagingTypeId: number
  createdAt: any
  updatedAt: any
  medicalImagingType: {
    __typename?: 'MedicalImagingType'
    id: number
    name: string
    image?: string | null
  }
}

export type ExaminationPartsFragment = {
  __typename?: 'Examination'
  id: string
  publicId: number
  createdAt: any
  updatedAt: any
  title?: string | null
  description?: string | null
  examinationDate?: any | null
  deletedAt?: any | null
  status: ExaminationStatus
  patientId: string
  validatedAt?: any | null
  invalidatedAt?: any | null
  invalidationReason?: string | null
  hospitalId?: string | null
  hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
  examinationToDiseases: Array<{
    __typename?: 'ExaminationToDisease'
    id: string
    examinationId: string
    diseaseId: number
    createdAt: any
    updatedAt: any
    disease: {
      __typename?: 'Disease'
      id: number
      name: string
      image?: string | null
      isMajor: boolean
    }
  }>
  examinationToMedicalImagingTypes: Array<{
    __typename?: 'ExaminationToMedicalImagingType'
    id: string
    examinationId: string
    medicalImagingTypeId: number
    createdAt: any
    updatedAt: any
    medicalImagingType: {
      __typename?: 'MedicalImagingType'
      id: number
      name: string
      image?: string | null
    }
  }>
  examinationToFiles: Array<{
    __typename?: 'ExaminationToFile'
    file: {
      __typename?: 'File'
      id: string
      originName: string
      key: string
      size?: number | null
    }
  }>
  patient?: {
    __typename?: 'User'
    id: string
    publicId: number
    nickname?: string | null
    profileImage?: {
      __typename?: 'File'
      id: string
      originName: string
      key: string
      size?: number | null
    } | null
  } | null
  interpretation?: {
    __typename?: 'Interpretation'
    id: string
    content?: string | null
    contentLength?: number | null
    correctedContent?: string | null
    correctedContentLength?: number | null
    status: InterpretationStatus
    startedAt: any
    finishedAt?: any | null
    savedAt?: any | null
    approvedAt?: any | null
    professionalId: string
    examinationId: string
    createdAt: any
    updatedAt: any
    rejectedAt?: any | null
    inspection?: {
      __typename?: 'Inspection'
      id: string
      note?: string | null
      accuracyScore: number
      expressionScore: number
      complianceScore: number
      honestyScore: number
      averageScore?: number | null
      createdAt: any
      inspector: {
        __typename?: 'Administrator'
        id: string
        user: {
          __typename?: 'User'
          nickname?: string | null
          publicId: number
        }
      }
    } | null
    professional: {
      __typename?: 'Professional'
      id: string
      user: {
        __typename?: 'User'
        id: string
        nickname?: string | null
        email?: string | null
        publicId: number
        profileImage?: { __typename?: 'File'; id: string; key: string } | null
      }
    }
    earning?: { __typename?: 'Earning'; id: string; amounts: number } | null
  } | null
}

export type CreateExaminationMutationVariables = Exact<{
  input: CreateExaminationInput
}>

export type CreateExaminationMutation = {
  __typename?: 'Mutation'
  createExamination: {
    __typename?: 'Examination'
    id: string
    publicId: number
    createdAt: any
    updatedAt: any
    title?: string | null
    description?: string | null
    examinationDate?: any | null
    deletedAt?: any | null
    status: ExaminationStatus
    patientId: string
    validatedAt?: any | null
    invalidatedAt?: any | null
    invalidationReason?: string | null
    hospitalId?: string | null
    hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
    examinationToDiseases: Array<{
      __typename?: 'ExaminationToDisease'
      id: string
      examinationId: string
      diseaseId: number
      createdAt: any
      updatedAt: any
      disease: {
        __typename?: 'Disease'
        id: number
        name: string
        image?: string | null
        isMajor: boolean
      }
    }>
    examinationToMedicalImagingTypes: Array<{
      __typename?: 'ExaminationToMedicalImagingType'
      id: string
      examinationId: string
      medicalImagingTypeId: number
      createdAt: any
      updatedAt: any
      medicalImagingType: {
        __typename?: 'MedicalImagingType'
        id: number
        name: string
        image?: string | null
      }
    }>
    examinationToFiles: Array<{
      __typename?: 'ExaminationToFile'
      file: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      }
    }>
    patient?: {
      __typename?: 'User'
      id: string
      publicId: number
      nickname?: string | null
      profileImage?: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      } | null
    } | null
    interpretation?: {
      __typename?: 'Interpretation'
      id: string
      content?: string | null
      contentLength?: number | null
      correctedContent?: string | null
      correctedContentLength?: number | null
      status: InterpretationStatus
      startedAt: any
      finishedAt?: any | null
      savedAt?: any | null
      approvedAt?: any | null
      professionalId: string
      examinationId: string
      createdAt: any
      updatedAt: any
      rejectedAt?: any | null
      inspection?: {
        __typename?: 'Inspection'
        id: string
        note?: string | null
        accuracyScore: number
        expressionScore: number
        complianceScore: number
        honestyScore: number
        averageScore?: number | null
        createdAt: any
        inspector: {
          __typename?: 'Administrator'
          id: string
          user: {
            __typename?: 'User'
            nickname?: string | null
            publicId: number
          }
        }
      } | null
      professional: {
        __typename?: 'Professional'
        id: string
        user: {
          __typename?: 'User'
          id: string
          nickname?: string | null
          email?: string | null
          publicId: number
          profileImage?: { __typename?: 'File'; id: string; key: string } | null
        }
      }
      earning?: { __typename?: 'Earning'; id: string; amounts: number } | null
    } | null
  }
}

export type UpdateExaminationMutationVariables = Exact<{
  input: UpdateExaminationInput
}>

export type UpdateExaminationMutation = {
  __typename?: 'Mutation'
  updateExamination: {
    __typename?: 'Examination'
    id: string
    publicId: number
    createdAt: any
    updatedAt: any
    title?: string | null
    description?: string | null
    examinationDate?: any | null
    deletedAt?: any | null
    status: ExaminationStatus
    patientId: string
    validatedAt?: any | null
    invalidatedAt?: any | null
    invalidationReason?: string | null
    hospitalId?: string | null
    hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
    examinationToDiseases: Array<{
      __typename?: 'ExaminationToDisease'
      id: string
      examinationId: string
      diseaseId: number
      createdAt: any
      updatedAt: any
      disease: {
        __typename?: 'Disease'
        id: number
        name: string
        image?: string | null
        isMajor: boolean
      }
    }>
    examinationToMedicalImagingTypes: Array<{
      __typename?: 'ExaminationToMedicalImagingType'
      id: string
      examinationId: string
      medicalImagingTypeId: number
      createdAt: any
      updatedAt: any
      medicalImagingType: {
        __typename?: 'MedicalImagingType'
        id: number
        name: string
        image?: string | null
      }
    }>
    examinationToFiles: Array<{
      __typename?: 'ExaminationToFile'
      file: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      }
    }>
    patient?: {
      __typename?: 'User'
      id: string
      publicId: number
      nickname?: string | null
      profileImage?: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      } | null
    } | null
    interpretation?: {
      __typename?: 'Interpretation'
      id: string
      content?: string | null
      contentLength?: number | null
      correctedContent?: string | null
      correctedContentLength?: number | null
      status: InterpretationStatus
      startedAt: any
      finishedAt?: any | null
      savedAt?: any | null
      approvedAt?: any | null
      professionalId: string
      examinationId: string
      createdAt: any
      updatedAt: any
      rejectedAt?: any | null
      inspection?: {
        __typename?: 'Inspection'
        id: string
        note?: string | null
        accuracyScore: number
        expressionScore: number
        complianceScore: number
        honestyScore: number
        averageScore?: number | null
        createdAt: any
        inspector: {
          __typename?: 'Administrator'
          id: string
          user: {
            __typename?: 'User'
            nickname?: string | null
            publicId: number
          }
        }
      } | null
      professional: {
        __typename?: 'Professional'
        id: string
        user: {
          __typename?: 'User'
          id: string
          nickname?: string | null
          email?: string | null
          publicId: number
          profileImage?: { __typename?: 'File'; id: string; key: string } | null
        }
      }
      earning?: { __typename?: 'Earning'; id: string; amounts: number } | null
    } | null
  }
}

export type DeleteExaminationMutationVariables = Exact<{
  input: DeleteExaminationInput
}>

export type DeleteExaminationMutation = {
  __typename?: 'Mutation'
  deleteExamination: { __typename?: 'DeleteExaminationOutput'; ok: boolean }
}

export type ExaminationCountPartsFragment = {
  __typename?: 'ExaminationCounts'
  total: number
  pending: number
  waiting: number
  interpreting: number
  done: number
  invalid: number
}

export type GetExaminationsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  filter?: InputMaybe<ExaminationFilter>
  sortBy?: InputMaybe<ExaminationSortBy>
}>

export type GetExaminationsByOffsetQuery = {
  __typename?: 'Query'
  examinations: {
    __typename?: 'OffsetPaginatedExaminations'
    edges?: Array<{
      __typename?: 'ExaminationOffsetEdge'
      node: {
        __typename?: 'Examination'
        id: string
        publicId: number
        createdAt: any
        updatedAt: any
        title?: string | null
        description?: string | null
        examinationDate?: any | null
        deletedAt?: any | null
        status: ExaminationStatus
        patientId: string
        validatedAt?: any | null
        invalidatedAt?: any | null
        invalidationReason?: string | null
        hospitalId?: string | null
        hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
        examinationToDiseases: Array<{
          __typename?: 'ExaminationToDisease'
          id: string
          examinationId: string
          diseaseId: number
          createdAt: any
          updatedAt: any
          disease: {
            __typename?: 'Disease'
            id: number
            name: string
            image?: string | null
            isMajor: boolean
          }
        }>
        examinationToMedicalImagingTypes: Array<{
          __typename?: 'ExaminationToMedicalImagingType'
          id: string
          examinationId: string
          medicalImagingTypeId: number
          createdAt: any
          updatedAt: any
          medicalImagingType: {
            __typename?: 'MedicalImagingType'
            id: number
            name: string
            image?: string | null
          }
        }>
        examinationToFiles: Array<{
          __typename?: 'ExaminationToFile'
          file: {
            __typename?: 'File'
            id: string
            originName: string
            key: string
            size?: number | null
          }
        }>
        patient?: {
          __typename?: 'User'
          id: string
          publicId: number
          nickname?: string | null
          profileImage?: {
            __typename?: 'File'
            id: string
            originName: string
            key: string
            size?: number | null
          } | null
        } | null
        interpretation?: {
          __typename?: 'Interpretation'
          id: string
          content?: string | null
          contentLength?: number | null
          correctedContent?: string | null
          correctedContentLength?: number | null
          status: InterpretationStatus
          startedAt: any
          finishedAt?: any | null
          savedAt?: any | null
          approvedAt?: any | null
          professionalId: string
          examinationId: string
          createdAt: any
          updatedAt: any
          rejectedAt?: any | null
          inspection?: {
            __typename?: 'Inspection'
            id: string
            note?: string | null
            accuracyScore: number
            expressionScore: number
            complianceScore: number
            honestyScore: number
            averageScore?: number | null
            createdAt: any
            inspector: {
              __typename?: 'Administrator'
              id: string
              user: {
                __typename?: 'User'
                nickname?: string | null
                publicId: number
              }
            }
          } | null
          professional: {
            __typename?: 'Professional'
            id: string
            user: {
              __typename?: 'User'
              id: string
              nickname?: string | null
              email?: string | null
              publicId: number
              profileImage?: {
                __typename?: 'File'
                id: string
                key: string
              } | null
            }
          }
          earning?: {
            __typename?: 'Earning'
            id: string
            amounts: number
          } | null
        } | null
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
  }
}

export type GetExaminationsInProgressByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  filter?: InputMaybe<ExaminationFilter>
  sortBy?: InputMaybe<ExaminationSortBy>
}>

export type GetExaminationsInProgressByOffsetQuery = {
  __typename?: 'Query'
  examinations: {
    __typename?: 'OffsetPaginatedExaminations'
    edges?: Array<{
      __typename?: 'ExaminationOffsetEdge'
      node: {
        __typename?: 'Examination'
        id: string
        publicId: number
        createdAt: any
        updatedAt: any
        title?: string | null
        description?: string | null
        examinationDate?: any | null
        deletedAt?: any | null
        status: ExaminationStatus
        patientId: string
        validatedAt?: any | null
        invalidatedAt?: any | null
        invalidationReason?: string | null
        hospitalId?: string | null
        hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
        examinationToDiseases: Array<{
          __typename?: 'ExaminationToDisease'
          id: string
          examinationId: string
          diseaseId: number
          createdAt: any
          updatedAt: any
          disease: {
            __typename?: 'Disease'
            id: number
            name: string
            image?: string | null
            isMajor: boolean
          }
        }>
        examinationToMedicalImagingTypes: Array<{
          __typename?: 'ExaminationToMedicalImagingType'
          id: string
          examinationId: string
          medicalImagingTypeId: number
          createdAt: any
          updatedAt: any
          medicalImagingType: {
            __typename?: 'MedicalImagingType'
            id: number
            name: string
            image?: string | null
          }
        }>
        examinationToFiles: Array<{
          __typename?: 'ExaminationToFile'
          file: {
            __typename?: 'File'
            id: string
            originName: string
            key: string
            size?: number | null
          }
        }>
        patient?: {
          __typename?: 'User'
          id: string
          publicId: number
          nickname?: string | null
          profileImage?: {
            __typename?: 'File'
            id: string
            originName: string
            key: string
            size?: number | null
          } | null
        } | null
        interpretation?: {
          __typename?: 'Interpretation'
          id: string
          content?: string | null
          contentLength?: number | null
          correctedContent?: string | null
          correctedContentLength?: number | null
          status: InterpretationStatus
          startedAt: any
          finishedAt?: any | null
          savedAt?: any | null
          approvedAt?: any | null
          professionalId: string
          examinationId: string
          createdAt: any
          updatedAt: any
          rejectedAt?: any | null
          inspection?: {
            __typename?: 'Inspection'
            id: string
            note?: string | null
            accuracyScore: number
            expressionScore: number
            complianceScore: number
            honestyScore: number
            averageScore?: number | null
            createdAt: any
            inspector: {
              __typename?: 'Administrator'
              id: string
              user: {
                __typename?: 'User'
                nickname?: string | null
                publicId: number
              }
            }
          } | null
          professional: {
            __typename?: 'Professional'
            id: string
            user: {
              __typename?: 'User'
              id: string
              nickname?: string | null
              email?: string | null
              publicId: number
              profileImage?: {
                __typename?: 'File'
                id: string
                key: string
              } | null
            }
          }
          earning?: {
            __typename?: 'Earning'
            id: string
            amounts: number
          } | null
        } | null
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
    counts?: { __typename?: 'ExaminationsCountsInfo'; waiting: number } | null
  }
}

export type GetExaminationQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetExaminationQuery = {
  __typename?: 'Query'
  examination: {
    __typename?: 'Examination'
    id: string
    publicId: number
    createdAt: any
    updatedAt: any
    title?: string | null
    description?: string | null
    examinationDate?: any | null
    deletedAt?: any | null
    status: ExaminationStatus
    patientId: string
    validatedAt?: any | null
    invalidatedAt?: any | null
    invalidationReason?: string | null
    hospitalId?: string | null
    hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
    examinationToDiseases: Array<{
      __typename?: 'ExaminationToDisease'
      id: string
      examinationId: string
      diseaseId: number
      createdAt: any
      updatedAt: any
      disease: {
        __typename?: 'Disease'
        id: number
        name: string
        image?: string | null
        isMajor: boolean
      }
    }>
    examinationToMedicalImagingTypes: Array<{
      __typename?: 'ExaminationToMedicalImagingType'
      id: string
      examinationId: string
      medicalImagingTypeId: number
      createdAt: any
      updatedAt: any
      medicalImagingType: {
        __typename?: 'MedicalImagingType'
        id: number
        name: string
        image?: string | null
      }
    }>
    examinationToFiles: Array<{
      __typename?: 'ExaminationToFile'
      file: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      }
    }>
    patient?: {
      __typename?: 'User'
      id: string
      publicId: number
      nickname?: string | null
      profileImage?: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      } | null
    } | null
    interpretation?: {
      __typename?: 'Interpretation'
      id: string
      content?: string | null
      contentLength?: number | null
      correctedContent?: string | null
      correctedContentLength?: number | null
      status: InterpretationStatus
      startedAt: any
      finishedAt?: any | null
      savedAt?: any | null
      approvedAt?: any | null
      professionalId: string
      examinationId: string
      createdAt: any
      updatedAt: any
      rejectedAt?: any | null
      inspection?: {
        __typename?: 'Inspection'
        id: string
        note?: string | null
        accuracyScore: number
        expressionScore: number
        complianceScore: number
        honestyScore: number
        averageScore?: number | null
        createdAt: any
        inspector: {
          __typename?: 'Administrator'
          id: string
          user: {
            __typename?: 'User'
            nickname?: string | null
            publicId: number
          }
        }
      } | null
      professional: {
        __typename?: 'Professional'
        id: string
        user: {
          __typename?: 'User'
          id: string
          nickname?: string | null
          email?: string | null
          publicId: number
          profileImage?: { __typename?: 'File'; id: string; key: string } | null
        }
      }
      earning?: { __typename?: 'Earning'; id: string; amounts: number } | null
    } | null
  }
}

export type GetExaminationOptionsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetExaminationOptionsQuery = {
  __typename?: 'Query'
  allDiseases: Array<{
    __typename?: 'Disease'
    id: number
    name: string
    image?: string | null
  }>
  allMedicalImagingTypes: Array<{
    __typename?: 'MedicalImagingType'
    id: number
    name: string
    image?: string | null
  }>
}

export type ValidateExaminationMutationVariables = Exact<{
  id: Scalars['String']
}>

export type ValidateExaminationMutation = {
  __typename?: 'Mutation'
  validateExamination: {
    __typename?: 'Examination'
    id: string
    status: ExaminationStatus
    validatedAt?: any | null
    invalidatedAt?: any | null
    invalidationReason?: string | null
    updatedAt: any
  }
}

export type InvalidateExaminationMutationVariables = Exact<{
  input: InvalidateExaminationInput
}>

export type InvalidateExaminationMutation = {
  __typename?: 'Mutation'
  invalidateExamination: {
    __typename?: 'Examination'
    id: string
    status: ExaminationStatus
    validatedAt?: any | null
    invalidatedAt?: any | null
    invalidationReason?: string | null
    updatedAt: any
  }
}

export type GetExaminationCountsByUserIdQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetExaminationCountsByUserIdQuery = {
  __typename?: 'Query'
  examinationCounts: {
    __typename?: 'ExaminationCounts'
    total: number
    pending: number
    waiting: number
    interpreting: number
    done: number
    invalid: number
  }
}

export type FaqPartsFragment = {
  __typename?: 'Faq'
  id: string
  title: string
  description: string
  categoryId: number
  createdAt: any
  updatedAt: any
  category: { __typename?: 'FaqCategory'; id: number; name: string }
}

export type GetAllFaqCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllFaqCategoriesQuery = {
  __typename?: 'Query'
  allFaqs: Array<{
    __typename?: 'FaqCategory'
    id: number
    name: string
    faqs?: Array<{
      __typename?: 'Faq'
      id: string
      title: string
      description: string
      categoryId: number
      createdAt: any
      updatedAt: any
      category: { __typename?: 'FaqCategory'; id: number; name: string }
    }> | null
  }>
}

export type GetFaqsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<FaqSortBy>
}>

export type GetFaqsByOffsetQuery = {
  __typename?: 'Query'
  faqs: {
    __typename?: 'OffsetPaginatedFaqs'
    edges?: Array<{
      __typename?: 'FaqOffsetEdge'
      node: {
        __typename?: 'Faq'
        id: string
        title: string
        description: string
        categoryId: number
        createdAt: any
        updatedAt: any
        category: { __typename?: 'FaqCategory'; id: number; name: string }
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
  }
}

export type GetFaqQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetFaqQuery = {
  __typename?: 'Query'
  faq: {
    __typename?: 'Faq'
    id: string
    title: string
    description: string
    categoryId: number
    createdAt: any
    updatedAt: any
    category: { __typename?: 'FaqCategory'; id: number; name: string }
  }
}

export type CreateFaqMutationVariables = Exact<{
  input: CreateFaqInput
}>

export type CreateFaqMutation = {
  __typename?: 'Mutation'
  createFaq: {
    __typename?: 'Faq'
    id: string
    title: string
    description: string
    categoryId: number
    createdAt: any
    updatedAt: any
    category: { __typename?: 'FaqCategory'; id: number; name: string }
  }
}

export type UpdateFaqMutationVariables = Exact<{
  input: UpdateFaqInput
}>

export type UpdateFaqMutation = {
  __typename?: 'Mutation'
  updateFaq: {
    __typename?: 'Faq'
    id: string
    title: string
    description: string
    categoryId: number
  }
}

export type DeleteFaqMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteFaqMutation = {
  __typename?: 'Mutation'
  deleteFaq: { __typename?: 'CoreOutput'; ok: boolean; error?: string | null }
}

export type FilePartsFragment = {
  __typename?: 'File'
  id: string
  originName: string
  key: string
  size?: number | null
}

export type GenerateUploadPathMutationVariables = Exact<{
  input: GenerateUploadPathInput
}>

export type GenerateUploadPathMutation = {
  __typename?: 'Mutation'
  generateUploadPath: {
    __typename?: 'GenerateUploadPathOutput'
    signedUrl: string
    key: string
  }
}

export type CreateFileMutationVariables = Exact<{
  input: CreateFileInput
}>

export type CreateFileMutation = {
  __typename?: 'Mutation'
  file: {
    __typename?: 'File'
    id: string
    originName: string
    key: string
    size?: number | null
  }
}

export type GenerateDownloadPathMutationVariables = Exact<{
  key: Scalars['String']
}>

export type GenerateDownloadPathMutation = {
  __typename?: 'Mutation'
  generateDownloadPath: string
}

export type CreateFileByNonMemberMutationVariables = Exact<{
  input: CreateFileInput
}>

export type CreateFileByNonMemberMutation = {
  __typename?: 'Mutation'
  file: {
    __typename?: 'File'
    id: string
    originName: string
    key: string
    size?: number | null
  }
}

export type GenerateUploadPathByNonMemberMutationVariables = Exact<{
  input: GenerateUploadPathInput
}>

export type GenerateUploadPathByNonMemberMutation = {
  __typename?: 'Mutation'
  generateUploadPath: {
    __typename?: 'GenerateUploadPathOutput'
    signedUrl: string
    key: string
  }
}

export type GetHospitalsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  filter?: InputMaybe<HospitalFilter>
}>

export type GetHospitalsByOffsetQuery = {
  __typename?: 'Query'
  hospitals: {
    __typename?: 'OffsetPaginatedHospitals'
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
    edges?: Array<{
      __typename?: 'HospitalOffsetEdge'
      node: {
        __typename?: 'Hospital'
        id: string
        name: string
        image?: string | null
        isMajor: boolean
        createdAt: any
        updatedAt: any
      }
    }> | null
  }
}

export type CreateIdentificationMutationVariables = Exact<{
  input: CreateIdentificationInput
}>

export type CreateIdentificationMutation = {
  __typename?: 'Mutation'
  createIdentification: {
    __typename?: 'Identification'
    id: string
    userId: string
  }
}

export type SaveIdentifiedUserMutationVariables = Exact<{
  input: SaveIdentifiedUserInput
}>

export type SaveIdentifiedUserMutation = {
  __typename?: 'Mutation'
  saveIdentifiedUser: {
    __typename?: 'Identification'
    id: string
    impUid?: string | null
    isIdentified?: boolean | null
    name?: string | null
    birthday?: string | null
    phoneNumber?: string | null
    identifiedAt?: any | null
    user: {
      __typename?: 'User'
      id: string
      name?: string | null
      phoneNumber?: string | null
    }
  }
}

export type InquiryPartsFragment = {
  __typename?: 'Inquiry'
  id: string
  isMember: boolean
  email?: string | null
  question: string
  title: string
  answer?: string | null
  answeredAt?: any | null
  createdAt: any
  updatedAt: any
  questionerId?: string | null
  answererId?: string | null
  questioner?: {
    __typename?: 'User'
    id: string
    email?: string | null
    publicId: number
    phoneNumber?: string | null
    name?: string | null
    role: UserRole
    nickname?: string | null
  } | null
  answerer?: {
    __typename?: 'Administrator'
    id: string
    createdAt: any
    user: {
      __typename?: 'User'
      id: string
      email?: string | null
      publicId: number
      nickname?: string | null
    }
  } | null
  inquiryToFiles?: Array<{
    __typename?: 'InquiryToFile'
    id: string
    file: {
      __typename?: 'File'
      id: string
      originName: string
      key: string
      size?: number | null
    }
  }> | null
}

export type GetInquiriesByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  filter?: InputMaybe<InquiryFilter>
  sortBy?: InputMaybe<InquirySortBy>
}>

export type GetInquiriesByOffsetQuery = {
  __typename?: 'Query'
  inquiries: {
    __typename?: 'OffsetPaginatedInquiries'
    edges?: Array<{
      __typename?: 'InquiryOffsetEdge'
      node: {
        __typename?: 'Inquiry'
        id: string
        isMember: boolean
        email?: string | null
        question: string
        title: string
        answer?: string | null
        answeredAt?: any | null
        createdAt: any
        updatedAt: any
        questionerId?: string | null
        answererId?: string | null
        questioner?: {
          __typename?: 'User'
          id: string
          email?: string | null
          publicId: number
          phoneNumber?: string | null
          name?: string | null
          role: UserRole
          nickname?: string | null
        } | null
        answerer?: {
          __typename?: 'Administrator'
          id: string
          createdAt: any
          user: {
            __typename?: 'User'
            id: string
            email?: string | null
            publicId: number
            nickname?: string | null
          }
        } | null
        inquiryToFiles?: Array<{
          __typename?: 'InquiryToFile'
          id: string
          file: {
            __typename?: 'File'
            id: string
            originName: string
            key: string
            size?: number | null
          }
        }> | null
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
  }
}

export type GetInquiryQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetInquiryQuery = {
  __typename?: 'Query'
  inqiury: {
    __typename?: 'Inquiry'
    id: string
    isMember: boolean
    email?: string | null
    question: string
    title: string
    answer?: string | null
    answeredAt?: any | null
    createdAt: any
    updatedAt: any
    questionerId?: string | null
    answererId?: string | null
    questioner?: {
      __typename?: 'User'
      id: string
      email?: string | null
      publicId: number
      phoneNumber?: string | null
      name?: string | null
      role: UserRole
      nickname?: string | null
    } | null
    answerer?: {
      __typename?: 'Administrator'
      id: string
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        email?: string | null
        publicId: number
        nickname?: string | null
      }
    } | null
    inquiryToFiles?: Array<{
      __typename?: 'InquiryToFile'
      id: string
      file: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      }
    }> | null
  }
}

export type CreateAnswerMutationVariables = Exact<{
  input: CreateAnswerInput
}>

export type CreateAnswerMutation = {
  __typename?: 'Mutation'
  createAnswer: {
    __typename?: 'Inquiry'
    id: string
    email?: string | null
    answer?: string | null
    answeredAt?: any | null
    answererId?: string | null
    answerer?: {
      __typename?: 'Administrator'
      id: string
      user: {
        __typename?: 'User'
        id: string
        publicId: number
        email?: string | null
      }
    } | null
  }
}

export type CreateInquiryByMemberMutationVariables = Exact<{
  input: CreateInquiryInput
}>

export type CreateInquiryByMemberMutation = {
  __typename?: 'Mutation'
  createInquiryByMember: {
    __typename?: 'Inquiry'
    id: string
    isMember: boolean
    email?: string | null
    question: string
    title: string
    answer?: string | null
    answeredAt?: any | null
    createdAt: any
    updatedAt: any
    questionerId?: string | null
    answererId?: string | null
    questioner?: {
      __typename?: 'User'
      id: string
      email?: string | null
      publicId: number
      phoneNumber?: string | null
      name?: string | null
      role: UserRole
      nickname?: string | null
    } | null
    answerer?: {
      __typename?: 'Administrator'
      id: string
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        email?: string | null
        publicId: number
        nickname?: string | null
      }
    } | null
    inquiryToFiles?: Array<{
      __typename?: 'InquiryToFile'
      id: string
      file: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      }
    }> | null
  }
}

export type CreateInquiryByNonMemberMutationVariables = Exact<{
  input: CreateInquiryInput
}>

export type CreateInquiryByNonMemberMutation = {
  __typename?: 'Mutation'
  createInquiryByNonMember: {
    __typename?: 'Inquiry'
    id: string
    isMember: boolean
    email?: string | null
    question: string
    title: string
    answer?: string | null
    answeredAt?: any | null
    createdAt: any
    updatedAt: any
    questionerId?: string | null
    answererId?: string | null
    questioner?: {
      __typename?: 'User'
      id: string
      email?: string | null
      publicId: number
      phoneNumber?: string | null
      name?: string | null
      role: UserRole
      nickname?: string | null
    } | null
    answerer?: {
      __typename?: 'Administrator'
      id: string
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        email?: string | null
        publicId: number
        nickname?: string | null
      }
    } | null
    inquiryToFiles?: Array<{
      __typename?: 'InquiryToFile'
      id: string
      file: {
        __typename?: 'File'
        id: string
        originName: string
        key: string
        size?: number | null
      }
    }> | null
  }
}

export type InspectionPartsFragment = {
  __typename?: 'Inspection'
  id: string
  inspectorId: string
  interpretationId: string
  note?: string | null
  createdAt: any
  updatedAt: any
  averageScore?: number | null
  accuracyScore: number
  complianceScore: number
  expressionScore: number
  honestyScore: number
}

export type CreateInspectionMutationVariables = Exact<{
  input: CreateInspectionInput
}>

export type CreateInspectionMutation = {
  __typename?: 'Mutation'
  createInspection: {
    __typename?: 'Inspection'
    id: string
    inspectorId: string
    interpretationId: string
    note?: string | null
    createdAt: any
    updatedAt: any
    averageScore?: number | null
    accuracyScore: number
    complianceScore: number
    expressionScore: number
    honestyScore: number
  }
}

export type UpdateInspectionScoreMutationVariables = Exact<{
  input: UpdateInspectionScoreInput
}>

export type UpdateInspectionScoreMutation = {
  __typename?: 'Mutation'
  updateInspectionScore: {
    __typename?: 'Inspection'
    id: string
    inspectorId: string
    interpretationId: string
    note?: string | null
    createdAt: any
    updatedAt: any
    averageScore?: number | null
    accuracyScore: number
    complianceScore: number
    expressionScore: number
    honestyScore: number
  }
}

export type InterpretationPartsFragment = {
  __typename?: 'Interpretation'
  id: string
  content?: string | null
  contentLength?: number | null
  correctedContent?: string | null
  correctedContentLength?: number | null
  status: InterpretationStatus
  startedAt: any
  finishedAt?: any | null
  savedAt?: any | null
  approvedAt?: any | null
  professionalId: string
  examinationId: string
  createdAt: any
  updatedAt: any
  rejectedAt?: any | null
  inspection?: {
    __typename?: 'Inspection'
    id: string
    note?: string | null
    accuracyScore: number
    expressionScore: number
    complianceScore: number
    honestyScore: number
    averageScore?: number | null
    createdAt: any
    inspector: {
      __typename?: 'Administrator'
      id: string
      user: { __typename?: 'User'; nickname?: string | null; publicId: number }
    }
  } | null
  professional: {
    __typename?: 'Professional'
    id: string
    user: {
      __typename?: 'User'
      id: string
      nickname?: string | null
      email?: string | null
      publicId: number
      profileImage?: { __typename?: 'File'; id: string; key: string } | null
    }
  }
  earning?: { __typename?: 'Earning'; id: string; amounts: number } | null
}

export type CreateInterpretationMutationVariables = Exact<{
  input: CreateInterpretationInput
}>

export type CreateInterpretationMutation = {
  __typename?: 'Mutation'
  createInterpretation: {
    __typename?: 'Interpretation'
    id: string
    content?: string | null
    contentLength?: number | null
    correctedContent?: string | null
    correctedContentLength?: number | null
    status: InterpretationStatus
    startedAt: any
    finishedAt?: any | null
    savedAt?: any | null
    approvedAt?: any | null
    professionalId: string
    examinationId: string
    createdAt: any
    updatedAt: any
    rejectedAt?: any | null
    inspection?: {
      __typename?: 'Inspection'
      id: string
      note?: string | null
      accuracyScore: number
      expressionScore: number
      complianceScore: number
      honestyScore: number
      averageScore?: number | null
      createdAt: any
      inspector: {
        __typename?: 'Administrator'
        id: string
        user: {
          __typename?: 'User'
          nickname?: string | null
          publicId: number
        }
      }
    } | null
    professional: {
      __typename?: 'Professional'
      id: string
      user: {
        __typename?: 'User'
        id: string
        nickname?: string | null
        email?: string | null
        publicId: number
        profileImage?: { __typename?: 'File'; id: string; key: string } | null
      }
    }
    earning?: { __typename?: 'Earning'; id: string; amounts: number } | null
  }
}

export type GetInterpretationCountsQueryVariables = Exact<{
  professionalId: Scalars['String']
}>

export type GetInterpretationCountsQuery = {
  __typename?: 'Query'
  getInterpretationCounts: {
    __typename?: 'InterpretationStatistics'
    total: number
    finished: number
    inProgress: number
    latestDate?: any | null
    averageRating?: number | null
  }
}

export type SubmitInterpretationMutationVariables = Exact<{
  input: SubmitInterpretationInput
}>

export type SubmitInterpretationMutation = {
  __typename?: 'Mutation'
  submitInterpretation: {
    __typename?: 'Interpretation'
    id: string
    content?: string | null
    status: InterpretationStatus
    finishedAt?: any | null
    savedAt?: any | null
    approvedAt?: any | null
    rejectedAt?: any | null
    rejectionReason?: string | null
    updatedAt: any
    examinationId: string
  }
}

export type SaveInterpretationMutationVariables = Exact<{
  input: SaveInterpretationInput
}>

export type SaveInterpretationMutation = {
  __typename?: 'Mutation'
  saveInterpretation: {
    __typename?: 'Interpretation'
    content?: string | null
    id: string
    savedAt?: any | null
  }
}

export type CancelInterpretationMutationVariables = Exact<{
  id: Scalars['String']
}>

export type CancelInterpretationMutation = {
  __typename?: 'Mutation'
  cancelInterpretation: { __typename?: 'CoreOutput'; ok: boolean }
}

export type GetSavedInterpretationsQueryVariables = Exact<{
  input: GetSavedInterpretationsInput
}>

export type GetSavedInterpretationsQuery = {
  __typename?: 'Query'
  savedInterpretations: Array<{
    __typename?: 'Interpretation'
    id: string
    content?: string | null
    savedAt?: any | null
    examinationId: string
  }>
}

export type GetInterpretationsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  filter?: InputMaybe<InterpretationFilter>
  sortBy?: InputMaybe<InterpretationSortBy>
}>

export type GetInterpretationsByOffsetQuery = {
  __typename?: 'Query'
  interpretations: {
    __typename?: 'OffsetPaginatedInterpretations'
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
    edges?: Array<{
      __typename?: 'InterpretationOffsetEdge'
      node: {
        __typename?: 'Interpretation'
        id: string
        status: InterpretationStatus
        startedAt: any
        deadline?: any | null
        finishedAt?: any | null
        savedAt?: any | null
        approvedAt?: any | null
        rejectedAt?: any | null
        rejectionReason?: string | null
        professionalId: string
        examinationId: string
        updatedAt: any
        createdAt: any
        examination: {
          __typename?: 'Examination'
          id: string
          publicId: number
          createdAt: any
          updatedAt: any
          title?: string | null
          description?: string | null
          examinationDate?: any | null
          deletedAt?: any | null
          status: ExaminationStatus
          patientId: string
          validatedAt?: any | null
          invalidatedAt?: any | null
          invalidationReason?: string | null
          hospitalId?: string | null
          hospital?: {
            __typename?: 'Hospital'
            id: string
            name: string
          } | null
          examinationToDiseases: Array<{
            __typename?: 'ExaminationToDisease'
            id: string
            examinationId: string
            diseaseId: number
            createdAt: any
            updatedAt: any
            disease: {
              __typename?: 'Disease'
              id: number
              name: string
              image?: string | null
              isMajor: boolean
            }
          }>
          examinationToMedicalImagingTypes: Array<{
            __typename?: 'ExaminationToMedicalImagingType'
            id: string
            examinationId: string
            medicalImagingTypeId: number
            createdAt: any
            updatedAt: any
            medicalImagingType: {
              __typename?: 'MedicalImagingType'
              id: number
              name: string
              image?: string | null
            }
          }>
          examinationToFiles: Array<{
            __typename?: 'ExaminationToFile'
            file: {
              __typename?: 'File'
              id: string
              originName: string
              key: string
              size?: number | null
            }
          }>
          patient?: {
            __typename?: 'User'
            id: string
            publicId: number
            nickname?: string | null
            profileImage?: {
              __typename?: 'File'
              id: string
              originName: string
              key: string
              size?: number | null
            } | null
          } | null
          interpretation?: {
            __typename?: 'Interpretation'
            id: string
            content?: string | null
            contentLength?: number | null
            correctedContent?: string | null
            correctedContentLength?: number | null
            status: InterpretationStatus
            startedAt: any
            finishedAt?: any | null
            savedAt?: any | null
            approvedAt?: any | null
            professionalId: string
            examinationId: string
            createdAt: any
            updatedAt: any
            rejectedAt?: any | null
            inspection?: {
              __typename?: 'Inspection'
              id: string
              note?: string | null
              accuracyScore: number
              expressionScore: number
              complianceScore: number
              honestyScore: number
              averageScore?: number | null
              createdAt: any
              inspector: {
                __typename?: 'Administrator'
                id: string
                user: {
                  __typename?: 'User'
                  nickname?: string | null
                  publicId: number
                }
              }
            } | null
            professional: {
              __typename?: 'Professional'
              id: string
              user: {
                __typename?: 'User'
                id: string
                nickname?: string | null
                email?: string | null
                publicId: number
                profileImage?: {
                  __typename?: 'File'
                  id: string
                  key: string
                } | null
              }
            }
            earning?: {
              __typename?: 'Earning'
              id: string
              amounts: number
            } | null
          } | null
        }
        professional: {
          __typename?: 'Professional'
          id: string
          user: { __typename?: 'User'; publicId: number }
        }
      }
    }> | null
  }
}

export type GetInterpretationQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetInterpretationQuery = {
  __typename?: 'Query'
  interpretation: {
    __typename?: 'Interpretation'
    id: string
    content?: string | null
    status: InterpretationStatus
    startedAt: any
    finishedAt?: any | null
    savedAt?: any | null
    approvedAt?: any | null
    rejectedAt?: any | null
    rejectionReason?: string | null
    professionalId: string
    createdAt: any
    examinationId: string
    updatedAt: any
    professional: {
      __typename?: 'Professional'
      id: string
      user: { __typename?: 'User'; publicId: number }
    }
    inspection?: {
      __typename?: 'Inspection'
      id: string
      note?: string | null
      accuracyScore: number
      expressionScore: number
      complianceScore: number
      honestyScore: number
      averageScore?: number | null
      createdAt: any
    } | null
    examination: {
      __typename?: 'Examination'
      id: string
      description?: string | null
      status: ExaminationStatus
      examinationDate?: any | null
      patientId: string
      createdAt: any
      patient?: {
        __typename?: 'User'
        email?: string | null
        name?: string | null
        phoneNumber?: string | null
        role: UserRole
        publicId: number
        id: string
        profileImage?: { __typename?: 'File'; id: string; key: string } | null
      } | null
      examinationToDiseases: Array<{
        __typename?: 'ExaminationToDisease'
        id: string
        disease: {
          __typename?: 'Disease'
          id: number
          name: string
          image?: string | null
        }
      }>
      examinationToFiles: Array<{
        __typename?: 'ExaminationToFile'
        id: string
        file: { __typename?: 'File'; id: string; key: string }
      }>
      examinationToMedicalImagingTypes: Array<{
        __typename?: 'ExaminationToMedicalImagingType'
        id: string
        medicalImagingType: {
          __typename?: 'MedicalImagingType'
          id: number
          name: string
          image?: string | null
        }
      }>
    }
  }
}

export type ApproveInterpretationMutationVariables = Exact<{
  input: ApproveInterpretationInput
}>

export type ApproveInterpretationMutation = {
  __typename?: 'Mutation'
  approveInterpretation: {
    __typename?: 'Interpretation'
    id: string
    approvedAt?: any | null
    rejectedAt?: any | null
    status: InterpretationStatus
    rejectionReason?: string | null
    examination: {
      __typename?: 'Examination'
      id: string
      status: ExaminationStatus
    }
  }
}

export type RejectInterpretationMutationVariables = Exact<{
  input: RejectInterpretationInput
}>

export type RejectInterpretationMutation = {
  __typename?: 'Mutation'
  rejectInterpretation: {
    __typename?: 'Interpretation'
    id: string
    rejectedAt?: any | null
    rejectionReason?: string | null
    approvedAt?: any | null
    status: InterpretationStatus
    examination: {
      __typename?: 'Examination'
      id: string
      status: ExaminationStatus
    }
  }
}

export type UpdateInterpretationMutationVariables = Exact<{
  input: UpdateInterpretationInput
}>

export type UpdateInterpretationMutation = {
  __typename?: 'Mutation'
  updateInterpretation: {
    __typename?: 'Interpretation'
    id: string
    content?: string | null
    contentLength?: number | null
    correctedContent?: string | null
    correctedContentLength?: number | null
    status: InterpretationStatus
    startedAt: any
    finishedAt?: any | null
    savedAt?: any | null
    approvedAt?: any | null
    professionalId: string
    examinationId: string
    createdAt: any
    updatedAt: any
    rejectedAt?: any | null
    inspection?: {
      __typename?: 'Inspection'
      id: string
      note?: string | null
      accuracyScore: number
      expressionScore: number
      complianceScore: number
      honestyScore: number
      averageScore?: number | null
      createdAt: any
      inspector: {
        __typename?: 'Administrator'
        id: string
        user: {
          __typename?: 'User'
          nickname?: string | null
          publicId: number
        }
      }
    } | null
    professional: {
      __typename?: 'Professional'
      id: string
      user: {
        __typename?: 'User'
        id: string
        nickname?: string | null
        email?: string | null
        publicId: number
        profileImage?: { __typename?: 'File'; id: string; key: string } | null
      }
    }
    earning?: { __typename?: 'Earning'; id: string; amounts: number } | null
  }
}

export type MedicalImagingTypePartsFragment = {
  __typename?: 'MedicalImagingType'
  id: number
  name: string
  image?: string | null
}

export type GetAllMedicalImagingTypesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAllMedicalImagingTypesQuery = {
  __typename?: 'Query'
  allMedicalImagingTypes: Array<{
    __typename?: 'MedicalImagingType'
    id: number
    name: string
    image?: string | null
  }>
}

export type NoticePartsFragment = {
  __typename?: 'Notice'
  id: string
  title: string
  type: NoticeType
  createdAt: any
  updatedAt: any
}

export type CreateNoticeMutationVariables = Exact<{
  input: CreateNoticeInput
}>

export type CreateNoticeMutation = {
  __typename?: 'Mutation'
  createNotice: {
    __typename?: 'Notice'
    id: string
    title: string
    content: string
    type: NoticeType
    authorId?: string | null
    createdAt: any
    updatedAt: any
  }
}

export type GetNoticesByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<NoticeSortBy>
}>

export type GetNoticesByOffsetQuery = {
  __typename?: 'Query'
  notices: {
    __typename?: 'OffsetPaginatedNotices'
    edges?: Array<{
      __typename?: 'NoticeOffsetEdge'
      node: {
        __typename?: 'Notice'
        id: string
        title: string
        type: NoticeType
        createdAt: any
        updatedAt: any
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
  }
}

export type GetNoticeQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetNoticeQuery = {
  __typename?: 'Query'
  notice: {
    __typename?: 'Notice'
    id: string
    title: string
    content: string
    type: NoticeType
    authorId?: string | null
    createdAt: any
    updatedAt: any
    author?: {
      __typename?: 'Administrator'
      id: string
      user: {
        __typename?: 'User'
        id: string
        email?: string | null
        name?: string | null
        publicId: number
      }
    } | null
  }
}

export type DeleteNoticeMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteNoticeMutation = {
  __typename?: 'Mutation'
  deleteNotice: {
    __typename?: 'CoreOutput'
    ok: boolean
    error?: string | null
  }
}

export type UpdateNoticeMutationVariables = Exact<{
  input: UpdateNoticeInput
}>

export type UpdateNoticeMutation = {
  __typename?: 'Mutation'
  updateNotice: {
    __typename?: 'Notice'
    id: string
    title: string
    content: string
    type: NoticeType
    authorId?: string | null
  }
}

export type OffsetPageInfoPartsFragment = {
  __typename?: 'OffsetPageInfo'
  hasPreviousPage: boolean
  hasNextPage: boolean
  countCurrent: number
  countTotal: number
  countBefore: number
  countNext: number
}

export type CursorPageInfoPartsFragment = {
  __typename?: 'CursorPageInfo'
  hasPreviousPage: boolean
  hasNextPage: boolean
  countCurrent: number
  countTotal: number
  countBefore: number
  countNext: number
  endCursor: string
  startCursor: string
}

export type ProfessionalPartsFragment = {
  __typename?: 'Professional'
  id: string
  userId: string
  createdAt: any
  updatedAt: any
  totalInterpretationCount?: number | null
  sevenDaysInterpretationCount?: number | null
  totalEarnings?: number | null
  spec?: {
    __typename?: 'ProfessionalSpec'
    doctorMajor?: string | null
    doctorPosition?: string | null
    doctorType?: string | null
    nurseType?: string | null
    otherJob?: string | null
    studentGrade?: string | null
    yearsOfService?: string | null
  } | null
  latestInterpretation?: {
    __typename?: 'Interpretation'
    id: string
    createdAt: any
    startedAt: any
  } | null
  certificate?: {
    __typename?: 'Certificate'
    id: string
    status: CertificateStatus
    createdAt: any
    updatedAt: any
    validatedAt?: any | null
    rejectionReason?: string | null
    validator?: {
      __typename?: 'Administrator'
      id: string
      user: {
        __typename?: 'User'
        id: string
        nickname?: string | null
        email?: string | null
      }
    } | null
    certificateToFiles: Array<{
      __typename?: 'CertificateToFile'
      id: string
      file: {
        __typename?: 'File'
        id: string
        key: string
        originName: string
        size?: number | null
      }
    }>
  } | null
  school?: { __typename?: 'School'; id: string; name: string } | null
  hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
  group?: { __typename?: 'ProfessionalGroup'; name: string; id: number } | null
}

export type CreateProfessionalMutationVariables = Exact<{
  input: CreateProfessionalInput
}>

export type CreateProfessionalMutation = {
  __typename?: 'Mutation'
  createProfessional: {
    __typename?: 'Professional'
    id: string
    userId: string
    createdAt: any
    updatedAt: any
    totalInterpretationCount?: number | null
    sevenDaysInterpretationCount?: number | null
    totalEarnings?: number | null
    spec?: {
      __typename?: 'ProfessionalSpec'
      doctorMajor?: string | null
      doctorPosition?: string | null
      doctorType?: string | null
      nurseType?: string | null
      otherJob?: string | null
      studentGrade?: string | null
      yearsOfService?: string | null
    } | null
    latestInterpretation?: {
      __typename?: 'Interpretation'
      id: string
      createdAt: any
      startedAt: any
    } | null
    certificate?: {
      __typename?: 'Certificate'
      id: string
      status: CertificateStatus
      createdAt: any
      updatedAt: any
      validatedAt?: any | null
      rejectionReason?: string | null
      validator?: {
        __typename?: 'Administrator'
        id: string
        user: {
          __typename?: 'User'
          id: string
          nickname?: string | null
          email?: string | null
        }
      } | null
      certificateToFiles: Array<{
        __typename?: 'CertificateToFile'
        id: string
        file: {
          __typename?: 'File'
          id: string
          key: string
          originName: string
          size?: number | null
        }
      }>
    } | null
    school?: { __typename?: 'School'; id: string; name: string } | null
    hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
    group?: {
      __typename?: 'ProfessionalGroup'
      name: string
      id: number
    } | null
  }
}

export type GetAllProfessionalGroupsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAllProfessionalGroupsQuery = {
  __typename?: 'Query'
  allGroups: Array<{
    __typename?: 'ProfessionalGroup'
    id: number
    name: string
  }>
}

export type ProfessionalEarningsPartsFragment = {
  __typename?: 'Professional'
  id: string
  group?: { __typename?: 'ProfessionalGroup'; id: number; name: string } | null
  user: {
    __typename?: 'User'
    id: string
    publicId: number
    email?: string | null
    nickname?: string | null
    bankAccount?: {
      __typename?: 'BankAccount'
      accountNumber: string
      accountHolder: string
      bank: { __typename?: 'Bank'; id: number; name: string }
    } | null
  }
  earnings?: Array<{
    __typename?: 'Earning'
    id: string
    settlementId?: string | null
    amounts: number
  }> | null
}

export type GetProfessionalEarningsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  filter: ProfessionalEarningsFilter
}>

export type GetProfessionalEarningsByOffsetQuery = {
  __typename?: 'Query'
  professionals: {
    __typename?: 'OffsetPaginatedProfessionals'
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
    edges?: Array<{
      __typename?: 'ProfessionalOffsetEdge'
      node: {
        __typename?: 'Professional'
        id: string
        group?: {
          __typename?: 'ProfessionalGroup'
          id: number
          name: string
        } | null
        user: {
          __typename?: 'User'
          id: string
          publicId: number
          email?: string | null
          nickname?: string | null
          bankAccount?: {
            __typename?: 'BankAccount'
            accountNumber: string
            accountHolder: string
            bank: { __typename?: 'Bank'; id: number; name: string }
          } | null
        }
        earnings?: Array<{
          __typename?: 'Earning'
          id: string
          settlementId?: string | null
          amounts: number
        }> | null
      }
    }> | null
  }
}

export type DownloadProfessionalEarningsExcelQueryVariables = Exact<{
  settlementId: Scalars['String']
}>

export type DownloadProfessionalEarningsExcelQuery = {
  __typename?: 'Query'
  downloadProfessionalEarningsExcel: {
    __typename?: 'DownloadProfessionalEarningsOutput'
    downloadUrl: string
  }
}

export type RelationshipPartsFragment = {
  __typename?: 'Relationship'
  id: string
  name: string
  isMajor: boolean
  createdAt: any
  updatedAt: any
}

export type GetRelationshipsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  filter?: InputMaybe<RelationshipFilter>
  offset: Scalars['Int']
}>

export type GetRelationshipsByOffsetQuery = {
  __typename?: 'Query'
  relationships: {
    __typename?: 'OffsetPaginatedRelationships'
    edges?: Array<{
      __typename?: 'RelationshipOffsetEdge'
      node: {
        __typename?: 'Relationship'
        id: string
        name: string
        isMajor: boolean
        createdAt: any
        updatedAt: any
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
  }
}

export type SettlementPartsFragment = {
  __typename?: 'Settlement'
  id: string
  createdAt: any
  startDate: any
  endDate: any
  totalCount?: number | null
  totalAmount?: number | null
  paidAt?: any | null
  updatedAt: any
  dueDate?: any | null
}

export type SettlementWithEarningsPartsFragment = {
  __typename?: 'Settlement'
  id: string
  createdAt: any
  startDate: any
  endDate: any
  totalCount?: number | null
  totalAmount?: number | null
  paidAt?: any | null
  updatedAt: any
  dueDate?: any | null
  numberOfTimes?: number | null
  earnings: Array<{ __typename?: 'Earning'; id: string; amounts: number }>
}

export type GetSettlementQueryVariables = Exact<{
  filter: SettlementFilter
}>

export type GetSettlementQuery = {
  __typename?: 'Query'
  getSettlement: {
    __typename?: 'Settlement'
    id: string
    createdAt: any
    startDate: any
    endDate: any
    totalCount?: number | null
    totalAmount?: number | null
    paidAt?: any | null
    updatedAt: any
    dueDate?: any | null
  }
}

export type GetLatestSettlementQueryVariables = Exact<{ [key: string]: never }>

export type GetLatestSettlementQuery = {
  __typename?: 'Query'
  settlement: {
    __typename?: 'Settlement'
    id: string
    createdAt: any
    startDate: any
    endDate: any
    totalCount?: number | null
    totalAmount?: number | null
    paidAt?: any | null
    updatedAt: any
    dueDate?: any | null
  }
}

export type GetSettlementsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
}>

export type GetSettlementsByOffsetQuery = {
  __typename?: 'Query'
  settlements: {
    __typename?: 'OffsetPaginatedSettlements'
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
    edges?: Array<{
      __typename?: 'SettlementOffsetEdge'
      node: {
        __typename?: 'Settlement'
        id: string
        createdAt: any
        startDate: any
        endDate: any
        totalCount?: number | null
        totalAmount?: number | null
        paidAt?: any | null
        updatedAt: any
        dueDate?: any | null
        numberOfTimes?: number | null
        earnings: Array<{ __typename?: 'Earning'; id: string; amounts: number }>
      }
    }> | null
  }
}

export type CompleteSettlementMutationVariables = Exact<{
  input: CompleteSettlementTransactionInput
}>

export type CompleteSettlementMutation = {
  __typename?: 'Mutation'
  completeSettlement: {
    __typename?: 'Settlement'
    id: string
    endDate: any
    dueDate?: any | null
    numberOfTimes?: number | null
    paidAt?: any | null
    startDate: any
  }
}

export type UpdateSettlementDueDateMutationVariables = Exact<{
  input: UpdateSettlementDueDateInput
}>

export type UpdateSettlementDueDateMutation = {
  __typename?: 'Mutation'
  updateSettlementDueDate: {
    __typename?: 'Settlement'
    id: string
    endDate: any
    dueDate?: any | null
    numberOfTimes?: number | null
    paidAt?: any | null
    startDate: any
  }
}

export type GetAdminHomeStatisticsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAdminHomeStatisticsQuery = {
  __typename?: 'Query'
  diseases: Array<{
    __typename?: 'DiseaseStatisticsOutput'
    id: number
    name: string
    count: number
  }>
  medicalImagingTypes: Array<{
    __typename?: 'ExaminationToMedicalImagingTypesCountOutput'
    id: number
    name: string
    count: number
  }>
  users: {
    __typename?: 'GetUserStatisticsOutput'
    total: number
    patientCount: number
    professionalCount: number
    todaysNewUsersCount: number
    yesterdaysNewUsersCount: number
    rateOfRise: number
    newUsers: Array<{
      __typename?: 'User'
      id: string
      publicId: number
      createdAt: any
    }>
  }
  certificates: {
    __typename?: 'GetCertificateStatisticsOutput'
    total: number
    waiting: number
    certified: number
    rejected: number
  }
  examinations: {
    __typename?: 'GetExaminationStatisticsOutput'
    total: number
    pending: number
    invalid: number
    waiting: number
    interpretingStarted: number
    interpretingFinished: number
    done: number
    exceeded: number
    newExaminationsCount: number
    rateOfRise: number
    todaysNewExaminationsCount: number
    yesterdaysNewExaminationsCount: number
    newExaminations: Array<{
      __typename?: 'Examination'
      id: string
      publicId: number
      createdAt: any
    }>
  }
  inquiries: {
    __typename?: 'GetInquiryStatisticsOutput'
    total: number
    waiting: number
  }
}

export type UserSettingPartsFragment = {
  __typename?: 'UserSetting'
  id: string
  serviceSmsNotification: boolean
  serviceEmailNotification: boolean
  promotionSmsNotification: boolean
  userId: string
  promotionEmailNotification: boolean
}

export type UpdateUserSettingsMutationVariables = Exact<{
  input: UpdateUserSettingsInput
}>

export type UpdateUserSettingsMutation = {
  __typename?: 'Mutation'
  updateUserSettings: { __typename?: 'UserSetting'; id: string }
}

export type GetUserSettingQueryVariables = Exact<{ [key: string]: never }>

export type GetUserSettingQuery = {
  __typename?: 'Query'
  setting: {
    __typename?: 'UserSetting'
    id: string
    serviceSmsNotification: boolean
    serviceEmailNotification: boolean
    promotionSmsNotification: boolean
    userId: string
    promotionEmailNotification: boolean
  }
}

export type UserToDiseasePartsFragment = {
  __typename?: 'UserToDisease'
  id: string
  userId: string
  diseaseId: number
  createdAt: any
  updatedAt: any
  disease: {
    __typename?: 'Disease'
    id: number
    name: string
    image?: string | null
    isMajor: boolean
  }
}

export type SetUserToDiseasesMutationVariables = Exact<{
  input: SetUserToDiseasesInput
}>

export type SetUserToDiseasesMutation = {
  __typename?: 'Mutation'
  setUserToDiseases: Array<{
    __typename?: 'UserToDisease'
    id: string
    userId: string
    diseaseId: number
    createdAt: any
    updatedAt: any
    disease: {
      __typename?: 'Disease'
      id: number
      name: string
      image?: string | null
      isMajor: boolean
    }
  }>
}

export type UserPartsFragment = {
  __typename?: 'User'
  id: string
  publicId: number
  role: UserRole
  email?: string | null
  name?: string | null
  nickname?: string | null
  phoneNumber?: string | null
  professionalId?: string | null
  createdAt: any
  updatedAt: any
  lastLoginAt?: any | null
  blockedAt?: any | null
  deletedAt?: any | null
  profileImage?: { __typename?: 'File'; id: string; key: string } | null
}

export type PatientUserPartsFragment = {
  __typename?: 'User'
  id: string
  publicId: number
  role: UserRole
  email?: string | null
  name?: string | null
  nickname?: string | null
  phoneNumber?: string | null
  professionalId?: string | null
  createdAt: any
  updatedAt: any
  lastLoginAt?: any | null
  blockedAt?: any | null
  deletedAt?: any | null
  userToDiseases?: Array<{
    __typename?: 'UserToDisease'
    id: string
    disease: { __typename?: 'Disease'; id: number; name: string }
  }> | null
  relationship?: {
    __typename?: 'Relationship'
    id: string
    name: string
  } | null
  profileImage?: { __typename?: 'File'; id: string; key: string } | null
}

export type ProfessionalUserPartsFragment = {
  __typename?: 'User'
  id: string
  publicId: number
  role: UserRole
  email?: string | null
  name?: string | null
  nickname?: string | null
  phoneNumber?: string | null
  professionalId?: string | null
  createdAt: any
  updatedAt: any
  lastLoginAt?: any | null
  blockedAt?: any | null
  deletedAt?: any | null
  professional?: {
    __typename?: 'Professional'
    id: string
    userId: string
    createdAt: any
    updatedAt: any
    totalInterpretationCount?: number | null
    sevenDaysInterpretationCount?: number | null
    totalEarnings?: number | null
    spec?: {
      __typename?: 'ProfessionalSpec'
      doctorMajor?: string | null
      doctorPosition?: string | null
      doctorType?: string | null
      nurseType?: string | null
      otherJob?: string | null
      studentGrade?: string | null
      yearsOfService?: string | null
    } | null
    latestInterpretation?: {
      __typename?: 'Interpretation'
      id: string
      createdAt: any
      startedAt: any
    } | null
    certificate?: {
      __typename?: 'Certificate'
      id: string
      status: CertificateStatus
      createdAt: any
      updatedAt: any
      validatedAt?: any | null
      rejectionReason?: string | null
      validator?: {
        __typename?: 'Administrator'
        id: string
        user: {
          __typename?: 'User'
          id: string
          nickname?: string | null
          email?: string | null
        }
      } | null
      certificateToFiles: Array<{
        __typename?: 'CertificateToFile'
        id: string
        file: {
          __typename?: 'File'
          id: string
          key: string
          originName: string
          size?: number | null
        }
      }>
    } | null
    school?: { __typename?: 'School'; id: string; name: string } | null
    hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
    group?: {
      __typename?: 'ProfessionalGroup'
      name: string
      id: number
    } | null
  } | null
  profileImage?: { __typename?: 'File'; id: string; key: string } | null
}

export type AdministratorUserPartsFragment = {
  __typename?: 'User'
  id: string
  publicId: number
  role: UserRole
  email?: string | null
  name?: string | null
  nickname?: string | null
  phoneNumber?: string | null
  professionalId?: string | null
  createdAt: any
  updatedAt: any
  lastLoginAt?: any | null
  blockedAt?: any | null
  deletedAt?: any | null
  administrator?: {
    __typename?: 'Administrator'
    id: string
    role?: AdminRole | null
  } | null
  profileImage?: { __typename?: 'File'; id: string; key: string } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type AdminMeQueryVariables = Exact<{ [key: string]: never }>

export type AdminMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    administrator?: {
      __typename?: 'Administrator'
      id: string
      role?: AdminRole | null
    } | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type PatientMeQueryVariables = Exact<{ [key: string]: never }>

export type PatientMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    userToDiseases?: Array<{
      __typename?: 'UserToDisease'
      id: string
      userId: string
      diseaseId: number
      createdAt: any
      updatedAt: any
      disease: {
        __typename?: 'Disease'
        id: number
        name: string
        image?: string | null
        isMajor: boolean
      }
    }> | null
    relationship?: {
      __typename?: 'Relationship'
      id: string
      name: string
      isMajor: boolean
      createdAt: any
      updatedAt: any
    } | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type ProfessionalMeQueryVariables = Exact<{ [key: string]: never }>

export type ProfessionalMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    professional?: {
      __typename?: 'Professional'
      id: string
      userId: string
      createdAt: any
      updatedAt: any
      totalInterpretationCount?: number | null
      sevenDaysInterpretationCount?: number | null
      totalEarnings?: number | null
      spec?: {
        __typename?: 'ProfessionalSpec'
        doctorMajor?: string | null
        doctorPosition?: string | null
        doctorType?: string | null
        nurseType?: string | null
        otherJob?: string | null
        studentGrade?: string | null
        yearsOfService?: string | null
      } | null
      latestInterpretation?: {
        __typename?: 'Interpretation'
        id: string
        createdAt: any
        startedAt: any
      } | null
      certificate?: {
        __typename?: 'Certificate'
        id: string
        status: CertificateStatus
        createdAt: any
        updatedAt: any
        validatedAt?: any | null
        rejectionReason?: string | null
        validator?: {
          __typename?: 'Administrator'
          id: string
          user: {
            __typename?: 'User'
            id: string
            nickname?: string | null
            email?: string | null
          }
        } | null
        certificateToFiles: Array<{
          __typename?: 'CertificateToFile'
          id: string
          file: {
            __typename?: 'File'
            id: string
            key: string
            originName: string
            size?: number | null
          }
        }>
      } | null
      school?: { __typename?: 'School'; id: string; name: string } | null
      hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
      group?: {
        __typename?: 'ProfessionalGroup'
        name: string
        id: number
      } | null
    } | null
    bankAccount?: {
      __typename?: 'BankAccount'
      id: string
      accountHolder: string
      accountNumber: string
      bankId: number
      createdAt: any
      updatedAt: any
      bank: { __typename?: 'Bank'; id: number; code: string; name: string }
    } | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type GetUsersByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  filter?: InputMaybe<UserFilter>
  offset: Scalars['Int']
  sortBy?: InputMaybe<UserSortBy>
}>

export type GetUsersByOffsetQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'OffsetPaginatedUsers'
    edges?: Array<{
      __typename?: 'UserOffsetEdge'
      node: {
        __typename?: 'User'
        id: string
        publicId: number
        role: UserRole
        email?: string | null
        name?: string | null
        nickname?: string | null
        phoneNumber?: string | null
        professionalId?: string | null
        createdAt: any
        updatedAt: any
        lastLoginAt?: any | null
        blockedAt?: any | null
        deletedAt?: any | null
        userToDiseases?: Array<{
          __typename?: 'UserToDisease'
          id: string
          disease: { __typename?: 'Disease'; id: number; name: string }
        }> | null
        relationship?: {
          __typename?: 'Relationship'
          id: string
          name: string
        } | null
        profileImage?: { __typename?: 'File'; id: string; key: string } | null
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
  }
}

export type GetProfessionalUsersByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  filter?: InputMaybe<UserFilter>
  offset: Scalars['Int']
  sortBy?: InputMaybe<UserSortBy>
}>

export type GetProfessionalUsersByOffsetQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'OffsetPaginatedUsers'
    edges?: Array<{
      __typename?: 'UserOffsetEdge'
      node: {
        __typename?: 'User'
        id: string
        publicId: number
        role: UserRole
        email?: string | null
        name?: string | null
        nickname?: string | null
        phoneNumber?: string | null
        professionalId?: string | null
        createdAt: any
        updatedAt: any
        lastLoginAt?: any | null
        blockedAt?: any | null
        deletedAt?: any | null
        professional?: {
          __typename?: 'Professional'
          id: string
          userId: string
          createdAt: any
          updatedAt: any
          totalInterpretationCount?: number | null
          sevenDaysInterpretationCount?: number | null
          totalEarnings?: number | null
          spec?: {
            __typename?: 'ProfessionalSpec'
            doctorMajor?: string | null
            doctorPosition?: string | null
            doctorType?: string | null
            nurseType?: string | null
            otherJob?: string | null
            studentGrade?: string | null
            yearsOfService?: string | null
          } | null
          latestInterpretation?: {
            __typename?: 'Interpretation'
            id: string
            createdAt: any
            startedAt: any
          } | null
          certificate?: {
            __typename?: 'Certificate'
            id: string
            status: CertificateStatus
            createdAt: any
            updatedAt: any
            validatedAt?: any | null
            rejectionReason?: string | null
            validator?: {
              __typename?: 'Administrator'
              id: string
              user: {
                __typename?: 'User'
                id: string
                nickname?: string | null
                email?: string | null
              }
            } | null
            certificateToFiles: Array<{
              __typename?: 'CertificateToFile'
              id: string
              file: {
                __typename?: 'File'
                id: string
                key: string
                originName: string
                size?: number | null
              }
            }>
          } | null
          school?: { __typename?: 'School'; id: string; name: string } | null
          hospital?: {
            __typename?: 'Hospital'
            id: string
            name: string
          } | null
          group?: {
            __typename?: 'ProfessionalGroup'
            name: string
            id: number
          } | null
        } | null
        profileImage?: { __typename?: 'File'; id: string; key: string } | null
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
  }
}

export type GetAdministratorUsersByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  filter?: InputMaybe<UserFilter>
  offset: Scalars['Int']
  sortBy?: InputMaybe<UserSortBy>
}>

export type GetAdministratorUsersByOffsetQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'OffsetPaginatedUsers'
    edges?: Array<{
      __typename?: 'UserOffsetEdge'
      node: {
        __typename?: 'User'
        id: string
        publicId: number
        role: UserRole
        email?: string | null
        name?: string | null
        nickname?: string | null
        phoneNumber?: string | null
        professionalId?: string | null
        createdAt: any
        updatedAt: any
        lastLoginAt?: any | null
        blockedAt?: any | null
        deletedAt?: any | null
        administrator?: {
          __typename?: 'Administrator'
          id: string
          role?: AdminRole | null
        } | null
        profileImage?: { __typename?: 'File'; id: string; key: string } | null
      }
    }> | null
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
  }
}

export type GetProfessionalRegistrationsByOffsetQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<UserSortBy>
  filter?: InputMaybe<ProfessionalRegistrationFilter>
}>

export type GetProfessionalRegistrationsByOffsetQuery = {
  __typename?: 'Query'
  registrations: {
    __typename?: 'OffsetPaginatedUsers'
    pageInfo?: {
      __typename?: 'OffsetPageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      countCurrent: number
      countTotal: number
      countBefore: number
      countNext: number
    } | null
    edges?: Array<{
      __typename?: 'UserOffsetEdge'
      node: {
        __typename?: 'User'
        id: string
        publicId: number
        role: UserRole
        email?: string | null
        name?: string | null
        nickname?: string | null
        phoneNumber?: string | null
        professionalId?: string | null
        createdAt: any
        updatedAt: any
        lastLoginAt?: any | null
        blockedAt?: any | null
        deletedAt?: any | null
        professional?: {
          __typename?: 'Professional'
          id: string
          userId: string
          createdAt: any
          updatedAt: any
          totalInterpretationCount?: number | null
          sevenDaysInterpretationCount?: number | null
          totalEarnings?: number | null
          spec?: {
            __typename?: 'ProfessionalSpec'
            doctorMajor?: string | null
            doctorPosition?: string | null
            doctorType?: string | null
            nurseType?: string | null
            otherJob?: string | null
            studentGrade?: string | null
            yearsOfService?: string | null
          } | null
          latestInterpretation?: {
            __typename?: 'Interpretation'
            id: string
            createdAt: any
            startedAt: any
          } | null
          certificate?: {
            __typename?: 'Certificate'
            id: string
            status: CertificateStatus
            createdAt: any
            updatedAt: any
            validatedAt?: any | null
            rejectionReason?: string | null
            validator?: {
              __typename?: 'Administrator'
              id: string
              user: {
                __typename?: 'User'
                id: string
                nickname?: string | null
                email?: string | null
              }
            } | null
            certificateToFiles: Array<{
              __typename?: 'CertificateToFile'
              id: string
              file: {
                __typename?: 'File'
                id: string
                key: string
                originName: string
                size?: number | null
              }
            }>
          } | null
          school?: { __typename?: 'School'; id: string; name: string } | null
          hospital?: {
            __typename?: 'Hospital'
            id: string
            name: string
          } | null
          group?: {
            __typename?: 'ProfessionalGroup'
            name: string
            id: number
          } | null
        } | null
        profileImage?: { __typename?: 'File'; id: string; key: string } | null
      }
    }> | null
  }
}

export type GetUserByAdminQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserByAdminQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    latestExamination?: {
      __typename?: 'Examination'
      id: string
      examinationDate?: any | null
      createdAt: any
      examinationToDiseases: Array<{
        __typename?: 'ExaminationToDisease'
        id: string
        disease: { __typename?: 'Disease'; id: number; name: string }
      }>
    } | null
    userToDiseases?: Array<{
      __typename?: 'UserToDisease'
      id: string
      disease: { __typename?: 'Disease'; id: number; name: string }
    }> | null
    relationship?: {
      __typename?: 'Relationship'
      id: string
      name: string
    } | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
  examinationCounts: {
    __typename?: 'ExaminationCounts'
    total: number
    pending: number
    waiting: number
    interpreting: number
    done: number
    invalid: number
  }
}

export type GetProfessionalUserQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetProfessionalUserQuery = {
  __typename?: 'Query'
  professionalUser: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    professional?: {
      __typename?: 'Professional'
      id: string
      userId: string
      createdAt: any
      updatedAt: any
      totalInterpretationCount?: number | null
      sevenDaysInterpretationCount?: number | null
      totalEarnings?: number | null
      spec?: {
        __typename?: 'ProfessionalSpec'
        doctorMajor?: string | null
        doctorPosition?: string | null
        doctorType?: string | null
        nurseType?: string | null
        otherJob?: string | null
        studentGrade?: string | null
        yearsOfService?: string | null
      } | null
      latestInterpretation?: {
        __typename?: 'Interpretation'
        id: string
        createdAt: any
        startedAt: any
      } | null
      certificate?: {
        __typename?: 'Certificate'
        id: string
        status: CertificateStatus
        createdAt: any
        updatedAt: any
        validatedAt?: any | null
        rejectionReason?: string | null
        validator?: {
          __typename?: 'Administrator'
          id: string
          user: {
            __typename?: 'User'
            id: string
            nickname?: string | null
            email?: string | null
          }
        } | null
        certificateToFiles: Array<{
          __typename?: 'CertificateToFile'
          id: string
          file: {
            __typename?: 'File'
            id: string
            key: string
            originName: string
            size?: number | null
          }
        }>
      } | null
      school?: { __typename?: 'School'; id: string; name: string } | null
      hospital?: { __typename?: 'Hospital'; id: string; name: string } | null
      group?: {
        __typename?: 'ProfessionalGroup'
        name: string
        id: number
      } | null
    } | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type GetAdministratorUserQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetAdministratorUserQuery = {
  __typename?: 'Query'
  administratorUser: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    administrator?: {
      __typename?: 'Administrator'
      id: string
      role?: AdminRole | null
    } | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type GetTotalUserCountQueryVariables = Exact<{ [key: string]: never }>

export type GetTotalUserCountQuery = {
  __typename?: 'Query'
  getTotalUserCount: number
}

export type SetRelationshipWithUserMutationVariables = Exact<{
  input: SetRelationshipWithUserInput
}>

export type SetRelationshipWithUserMutation = {
  __typename?: 'Mutation'
  setRelationshipWithUser: {
    __typename?: 'User'
    id: string
    relationship?: {
      __typename?: 'Relationship'
      id: string
      name: string
      isMajor: boolean
      createdAt: any
      updatedAt: any
    } | null
  }
}

export type SetProfileImageMutationVariables = Exact<{
  input: SetProfileImageInput
}>

export type SetProfileImageMutation = {
  __typename?: 'Mutation'
  setProfileImage: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type BlockUserMutationVariables = Exact<{
  userId: Scalars['String']
}>

export type BlockUserMutation = {
  __typename?: 'Mutation'
  blockUser: { __typename?: 'User'; id: string }
}

export type CheckPasswordMutationVariables = Exact<{
  password: Scalars['String']
}>

export type CheckPasswordMutation = {
  __typename?: 'Mutation'
  checkPassword: {
    __typename?: 'CoreOutput'
    ok: boolean
    error?: string | null
  }
}

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String']
}>

export type ChangePasswordMutation = {
  __typename?: 'Mutation'
  changePassword: {
    __typename?: 'CoreOutput'
    error?: string | null
    ok: boolean
  }
}

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  deleteUser: { __typename?: 'CoreOutput'; ok: boolean }
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: { __typename?: 'User'; id: string; email?: string | null }
}

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword: { __typename?: 'ResetPasswordOutput'; ok: boolean }
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'User'
    id: string
    publicId: number
    role: UserRole
    email?: string | null
    name?: string | null
    nickname?: string | null
    phoneNumber?: string | null
    professionalId?: string | null
    createdAt: any
    updatedAt: any
    lastLoginAt?: any | null
    blockedAt?: any | null
    deletedAt?: any | null
    profileImage?: { __typename?: 'File'; id: string; key: string } | null
  }
}

export type RequestEmailVerificationCodeMutationVariables = Exact<{
  input: RequestEmailVerificationCodeInput
}>

export type RequestEmailVerificationCodeMutation = {
  __typename?: 'Mutation'
  requestEmailVerificationCode: {
    __typename?: 'RequestEmailVerificationCodeOutput'
    ok: boolean
  }
}

export type ConfirmEmailVerificationCodeMutationVariables = Exact<{
  input: ConfirmEmailVerificationCodeInput
}>

export type ConfirmEmailVerificationCodeMutation = {
  __typename?: 'Mutation'
  confirmEmailVerificationCode: {
    __typename?: 'ConfirmEmailVerificationCodeOutput'
    ok: boolean
  }
}

export type RequestPhoneVerificationCodeMutationVariables = Exact<{
  input: RequestPhoneVerificationCodeInput
}>

export type RequestPhoneVerificationCodeMutation = {
  __typename?: 'Mutation'
  requestPhoneVerificationCode: {
    __typename?: 'RequestPhoneVerificationCodeOutput'
    ok: boolean
  }
}

export type ConfirmPhoneVerificationCodeMutationVariables = Exact<{
  input: ConfirmPhoneVerificationCodeInput
}>

export type ConfirmPhoneVerificationCodeMutation = {
  __typename?: 'Mutation'
  confirmPhoneVerificationCode: {
    __typename?: 'ConfirmPhoneVerificationCodeOutput'
    ok: boolean
  }
}

export const BankAccountPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bank' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BankAccountPartsFragment, unknown>
export const DiseasePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiseaseParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Disease' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMajor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiseasePartsFragment, unknown>
export const ExaminationToDiseasePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExaminationToDiseaseParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExaminationToDisease' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'examinationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'diseaseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disease' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DiseaseParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...DiseasePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ExaminationToDiseasePartsFragment, unknown>
export const MedicalImagingTypePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MedicalImagingTypeParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MedicalImagingType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MedicalImagingTypePartsFragment, unknown>
export const ExaminationToMedicalImagingTypePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExaminationToMedicalImagingTypeParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExaminationToMedicalImagingType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'examinationId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'medicalImagingTypeId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'medicalImagingType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MedicalImagingTypeParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...MedicalImagingTypePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ExaminationToMedicalImagingTypePartsFragment,
  unknown
>
export const EarningPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EarningParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Earning' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amounts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interpretationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settlement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interpretation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentLength' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'correctedContentLength' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'examination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patient' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nickname' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examinationToFiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examinationToDiseases' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ExaminationToDiseaseParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'examinationToMedicalImagingTypes',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ExaminationToMedicalImagingTypeParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'professional' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nickname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExaminationToDiseasePartsFragmentDoc.definitions,
    ...ExaminationToMedicalImagingTypePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EarningPartsFragment, unknown>
export const FilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilePartsFragment, unknown>
export const InterpretationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterpretationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Interpretation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'correctedContent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'correctedContentLength' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'savedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'examinationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inspection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accuracyScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expressionScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complianceScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'honestyScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inspector' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nickname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publicId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'professional' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nickname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'earning' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amounts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InterpretationPartsFragment, unknown>
export const ExaminationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExaminationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Examination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'examinationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invalidatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidationReason' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hospitalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hospital' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'examinationToDiseases' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExaminationToDiseaseParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'examinationToMedicalImagingTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ExaminationToMedicalImagingTypeParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'examinationToFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FileParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FileParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interpretation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InterpretationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExaminationToDiseasePartsFragmentDoc.definitions,
    ...ExaminationToMedicalImagingTypePartsFragmentDoc.definitions,
    ...FilePartsFragmentDoc.definitions,
    ...InterpretationPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ExaminationPartsFragment, unknown>
export const ExaminationCountPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExaminationCountParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExaminationCounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waiting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interpreting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'done' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExaminationCountPartsFragment, unknown>
export const FaqPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Faq' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FaqPartsFragment, unknown>
export const InquiryPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InquiryParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Inquiry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answeredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questioner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'answererId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answerer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nickname' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inquiryToFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FileParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FilePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<InquiryPartsFragment, unknown>
export const InspectionPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InspectionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Inspection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inspectorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interpretationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accuracyScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'complianceScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expressionScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'honestyScore' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InspectionPartsFragment, unknown>
export const NoticePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NoticeParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Notice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NoticePartsFragment, unknown>
export const OffsetPageInfoPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsetPageInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OffsetPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countCurrent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countBefore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countNext' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OffsetPageInfoPartsFragment, unknown>
export const CursorPageInfoPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CursorPageInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CursorPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countCurrent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countBefore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countNext' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CursorPageInfoPartsFragment, unknown>
export const ProfessionalEarningsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfessionalEarningsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Professional' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bank' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountHolder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'earnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settlementId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'amounts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalEarningsPartsFragment, unknown>
export const RelationshipPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelationshipParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Relationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMajor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RelationshipPartsFragment, unknown>
export const SettlementPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettlementParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settlement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettlementPartsFragment, unknown>
export const SettlementWithEarningsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettlementWithEarningsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settlement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTimes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'earnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amounts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettlementWithEarningsPartsFragment, unknown>
export const UserSettingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSetting' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceSmsNotification' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceEmailNotification' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promotionSmsNotification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promotionEmailNotification' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSettingPartsFragment, unknown>
export const UserToDiseasePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserToDiseaseParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserToDisease' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'diseaseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disease' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DiseaseParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...DiseasePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UserToDiseasePartsFragment, unknown>
export const UserPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastLoginAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPartsFragment, unknown>
export const PatientUserPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PatientUserParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserParts' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userToDiseases' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disease' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relationship' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PatientUserPartsFragment, unknown>
export const CertificatePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Certificate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nickname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateToFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificatePartsFragment, unknown>
export const ProfessionalPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfessionalParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Professional' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalInterpretationCount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sevenDaysInterpretationCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEarnings' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spec' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'doctorMajor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'doctorPosition' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'doctorType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nurseType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'otherJob' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentGrade' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yearsOfService' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestInterpretation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hospital' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    ...CertificatePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProfessionalPartsFragment, unknown>
export const ProfessionalUserPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfessionalUserParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserParts' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'professional' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfessionalParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
    ...ProfessionalPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProfessionalUserPartsFragment, unknown>
export const AdminPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Administrator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminPartsFragment, unknown>
export const AdministratorUserPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdministratorUserParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserParts' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
    ...AdminPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AdministratorUserPartsFragment, unknown>
export const CreateAdministratorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAdministrator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAdministratorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAdministrator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserParts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'administrator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
    ...AdminPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateAdministratorMutation,
  CreateAdministratorMutationVariables
>
export const SetAdministratorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetAdministrator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetAdministratorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setAdministrator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserParts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'administrator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
    ...AdminPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  SetAdministratorMutation,
  SetAdministratorMutationVariables
>
export const UpdateAdministratorRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAdministratorRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAdministratorRoleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAdministratorRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...AdminPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateAdministratorRoleMutation,
  UpdateAdministratorRoleMutationVariables
>
export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoginInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>
export const LogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Logout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logout' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>
export const GetBankAccountByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBankAccountByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'bankAccount' },
            name: { kind: 'Name', value: 'getBankAccountByUserId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankAccountParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BankAccountPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetBankAccountByUserIdQuery,
  GetBankAccountByUserIdQueryVariables
>
export const CreateBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBankAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankAccountParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BankAccountPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateBankAccountMutation,
  CreateBankAccountMutationVariables
>
export const UpdateBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBankAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankAccountParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BankAccountPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateBankAccountMutation,
  UpdateBankAccountMutationVariables
>
export const RemoveBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeBankAccountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removeBankAccountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveBankAccountMutation,
  RemoveBankAccountMutationVariables
>
export const UpsertBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBankAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankAccountParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BankAccountPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpsertBankAccountMutation,
  UpsertBankAccountMutationVariables
>
export const GetAllBanksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllBanks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'banks' },
            name: { kind: 'Name', value: 'getAllBanks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllBanksQuery, GetAllBanksQueryVariables>
export const ApproveCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApproveCertificateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validatorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nickname' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApproveCertificateMutation,
  ApproveCertificateMutationVariables
>
export const RejectCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RejectCertificateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectCertificateMutation,
  RejectCertificateMutationVariables
>
export const ResetCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResetCertificateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetCertificateMutation,
  ResetCertificateMutationVariables
>
export const GetCertificateByProfessionalIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCertificateByProfessionalId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'professionalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCertificateByProfessionalId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'professionalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'professionalId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCertificateByProfessionalIdQuery,
  GetCertificateByProfessionalIdQueryVariables
>
export const GetAllDiseasesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllDiseases' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allDiseases' },
            name: { kind: 'Name', value: 'getAllDiseases' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DiseaseParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...DiseasePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAllDiseasesQuery, GetAllDiseasesQueryVariables>
export const GetDiseasesByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDiseasesByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DiseaseFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DiseaseSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'diseases' },
            name: { kind: 'Name', value: 'getDiseasesByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DiseaseParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OffsetPageInfoPartsFragmentDoc.definitions,
    ...DiseasePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetDiseasesByOffsetQuery,
  GetDiseasesByOffsetQueryVariables
>
export const GetEarningssByCursorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEarningssByCursor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EarningFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEarningsByCursor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CursorPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'EarningParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CursorPageInfoPartsFragmentDoc.definitions,
    ...EarningPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetEarningssByCursorQuery,
  GetEarningssByCursorQueryVariables
>
export const GetEarningTotalAmountsByCursorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEarningTotalAmountsByCursor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EarningFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortKey' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EarningSortKey' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEarningTotalAmountsByCursor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortKey' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CursorPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalAmount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'settlementId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paidAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CursorPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetEarningTotalAmountsByCursorQuery,
  GetEarningTotalAmountsByCursorQueryVariables
>
export const GetEarningTotalAmountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEarningTotalAmount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EarningFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEarningTotalAmount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEarningTotalAmountQuery,
  GetEarningTotalAmountQueryVariables
>
export const GetEarningsStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEarningsStatistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'professionalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEarningsStatistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'professionalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'professionalId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paidAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unpaidAmount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextSettlementDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'unpaidCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weekCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settlementCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEarningsStatisticsQuery,
  GetEarningsStatisticsQueryVariables
>
export const GetEarningsStatisticsForAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEarningsStatisticsForAdmin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEarningsStatisticsForAdmin' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextSettlementDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paidAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unpaidAmount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEarningsStatisticsForAdminQuery,
  GetEarningsStatisticsForAdminQueryVariables
>
export const GetEarningsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEarningsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EarningFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'earnings' },
            name: { kind: 'Name', value: 'getEarningsByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'EarningParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OffsetPageInfoPartsFragmentDoc.definitions,
    ...EarningPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetEarningsByOffsetQuery,
  GetEarningsByOffsetQueryVariables
>
export const CreateExaminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateExamination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateExaminationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createExamination' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExaminationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExaminationPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateExaminationMutation,
  CreateExaminationMutationVariables
>
export const UpdateExaminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateExamination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateExaminationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateExamination' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExaminationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExaminationPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateExaminationMutation,
  UpdateExaminationMutationVariables
>
export const DeleteExaminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteExamination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteExaminationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteExamination' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteExaminationMutation,
  DeleteExaminationMutationVariables
>
export const GetExaminationsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetExaminationsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ExaminationFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ExaminationSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'examinations' },
            name: { kind: 'Name', value: 'getExaminationsByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ExaminationParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExaminationPartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetExaminationsByOffsetQuery,
  GetExaminationsByOffsetQueryVariables
>
export const GetExaminationsInProgressByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetExaminationsInProgressByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ExaminationFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ExaminationSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'examinations' },
            name: { kind: 'Name', value: 'getExaminationsInProgressByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ExaminationParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'counts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waiting' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExaminationPartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetExaminationsInProgressByOffsetQuery,
  GetExaminationsInProgressByOffsetQueryVariables
>
export const GetExaminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetExamination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'examination' },
            name: { kind: 'Name', value: 'getExamination' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExaminationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExaminationPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetExaminationQuery, GetExaminationQueryVariables>
export const GetExaminationOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetExaminationOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allDiseases' },
            name: { kind: 'Name', value: 'getAllDiseases' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allMedicalImagingTypes' },
            name: { kind: 'Name', value: 'getAllMedicalImagingTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetExaminationOptionsQuery,
  GetExaminationOptionsQueryVariables
>
export const ValidateExaminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ValidateExamination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateExamination' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invalidatedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invalidationReason' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidateExaminationMutation,
  ValidateExaminationMutationVariables
>
export const InvalidateExaminationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InvalidateExamination' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InvalidateExaminationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidateExamination' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invalidatedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invalidationReason' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InvalidateExaminationMutation,
  InvalidateExaminationMutationVariables
>
export const GetExaminationCountsByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetExaminationCountsByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'examinationCounts' },
            name: { kind: 'Name', value: 'getExaminationCountsByUserId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiting' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interpreting' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invalid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetExaminationCountsByUserIdQuery,
  GetExaminationCountsByUserIdQueryVariables
>
export const GetAllFaqCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllFaqCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allFaqs' },
            name: { kind: 'Name', value: 'getAllFaqCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'faqs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FaqParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FaqPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetAllFaqCategoriesQuery,
  GetAllFaqCategoriesQueryVariables
>
export const GetFaqsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFaqsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FaqSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'faqs' },
            name: { kind: 'Name', value: 'getFaqsByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FaqParts' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FaqPartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetFaqsByOffsetQuery,
  GetFaqsByOffsetQueryVariables
>
export const GetFaqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFaq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'faq' },
            name: { kind: 'Name', value: 'getFaq' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FaqParts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FaqPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetFaqQuery, GetFaqQueryVariables>
export const CreateFaqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFaq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFaqInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFaq' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FaqParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FaqPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateFaqMutation, CreateFaqMutationVariables>
export const UpdateFaqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFaq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFaqInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFaq' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFaqMutation, UpdateFaqMutationVariables>
export const DeleteFaqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFaq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFaq' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFaqMutation, DeleteFaqMutationVariables>
export const GenerateUploadPathDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateUploadPath' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateUploadPathInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateUploadPath' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateUploadPathMutation,
  GenerateUploadPathMutationVariables
>
export const CreateFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'file' },
            name: { kind: 'Name', value: 'createFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FileParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FilePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateFileMutation, CreateFileMutationVariables>
export const GenerateDownloadPathDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateDownloadPath' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateDownloadPath' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateDownloadPathMutation,
  GenerateDownloadPathMutationVariables
>
export const CreateFileByNonMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFileByNonMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'file' },
            name: { kind: 'Name', value: 'createFileByNonMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FileParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FilePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateFileByNonMemberMutation,
  CreateFileByNonMemberMutationVariables
>
export const GenerateUploadPathByNonMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateUploadPathByNonMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateUploadPathInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'generateUploadPath' },
            name: { kind: 'Name', value: 'generateUploadPathByNonMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateUploadPathByNonMemberMutation,
  GenerateUploadPathByNonMemberMutationVariables
>
export const GetHospitalsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHospitalsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'HospitalFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hospitals' },
            name: { kind: 'Name', value: 'getHospitalsByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isMajor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetHospitalsByOffsetQuery,
  GetHospitalsByOffsetQueryVariables
>
export const CreateIdentificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIdentification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateIdentificationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIdentification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateIdentificationMutation,
  CreateIdentificationMutationVariables
>
export const SaveIdentifiedUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveIdentifiedUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SaveIdentifiedUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveIdentifiedUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'impUid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isIdentified' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'identifiedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveIdentifiedUserMutation,
  SaveIdentifiedUserMutationVariables
>
export const GetInquiriesByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInquiriesByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InquiryFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InquirySortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'inquiries' },
            name: { kind: 'Name', value: 'getInquiriesByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'InquiryParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...InquiryPartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetInquiriesByOffsetQuery,
  GetInquiriesByOffsetQueryVariables
>
export const GetInquiryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInquiry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'inqiury' },
            name: { kind: 'Name', value: 'getInquiry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InquiryParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InquiryPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetInquiryQuery, GetInquiryQueryVariables>
export const CreateAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAnswerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAnswer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answeredAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answererId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answerer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAnswerMutation,
  CreateAnswerMutationVariables
>
export const CreateInquiryByMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInquiryByMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInquiryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInquiryByMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InquiryParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InquiryPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateInquiryByMemberMutation,
  CreateInquiryByMemberMutationVariables
>
export const CreateInquiryByNonMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInquiryByNonMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInquiryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInquiryByNonMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InquiryParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InquiryPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateInquiryByNonMemberMutation,
  CreateInquiryByNonMemberMutationVariables
>
export const CreateInspectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInspection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInspectionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInspection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InspectionParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InspectionPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateInspectionMutation,
  CreateInspectionMutationVariables
>
export const UpdateInspectionScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInspectionScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateInspectionScoreInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInspectionScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InspectionParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InspectionPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateInspectionScoreMutation,
  UpdateInspectionScoreMutationVariables
>
export const CreateInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInterpretationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InterpretationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InterpretationPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateInterpretationMutation,
  CreateInterpretationMutationVariables
>
export const GetInterpretationCountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInterpretationCounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'professionalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInterpretationCounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'professionalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'professionalId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finished' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inProgress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latestDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInterpretationCountsQuery,
  GetInterpretationCountsQueryVariables
>
export const SubmitInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitInterpretationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejectionReason' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'examinationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitInterpretationMutation,
  SubmitInterpretationMutationVariables
>
export const SaveInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SaveInterpretationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveInterpretationMutation,
  SaveInterpretationMutationVariables
>
export const CancelInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelInterpretationMutation,
  CancelInterpretationMutationVariables
>
export const GetSavedInterpretationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSavedInterpretations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetSavedInterpretationsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'savedInterpretations' },
            name: { kind: 'Name', value: 'getSavedInterpretations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'examinationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSavedInterpretationsQuery,
  GetSavedInterpretationsQueryVariables
>
export const GetInterpretationsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInterpretationsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InterpretationFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InterpretationSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interpretations' },
            name: { kind: 'Name', value: 'getInterpretationsByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deadline' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'finishedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'savedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'approvedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rejectedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rejectionReason' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'professionalId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'examinationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'examination' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ExaminationParts',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'professional' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'publicId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OffsetPageInfoPartsFragmentDoc.definitions,
    ...ExaminationPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetInterpretationsByOffsetQuery,
  GetInterpretationsByOffsetQueryVariables
>
export const GetInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interpretation' },
            name: { kind: 'Name', value: 'getInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejectionReason' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'professionalId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'examinationId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'professional' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publicId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inspection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accuracyScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expressionScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'complianceScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'honestyScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'averageScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'examination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examinationDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patient' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phoneNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profileImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examinationToDiseases' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disease' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examinationToFiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'examinationToMedicalImagingTypes',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'medicalImagingType',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInterpretationQuery,
  GetInterpretationQueryVariables
>
export const ApproveInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApproveInterpretationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejectionReason' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'examination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApproveInterpretationMutation,
  ApproveInterpretationMutationVariables
>
export const RejectInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RejectInterpretationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejectionReason' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'examination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectInterpretationMutation,
  RejectInterpretationMutationVariables
>
export const UpdateInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateInterpretationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InterpretationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InterpretationPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateInterpretationMutation,
  UpdateInterpretationMutationVariables
>
export const GetAllMedicalImagingTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllMedicalImagingTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allMedicalImagingTypes' },
            name: { kind: 'Name', value: 'getAllMedicalImagingTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MedicalImagingTypeParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...MedicalImagingTypePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetAllMedicalImagingTypesQuery,
  GetAllMedicalImagingTypesQueryVariables
>
export const CreateNoticeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNotice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateNoticeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNotice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNoticeMutation,
  CreateNoticeMutationVariables
>
export const GetNoticesByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNoticesByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'NoticeSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'notices' },
            name: { kind: 'Name', value: 'getNoticesByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'NoticeParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...NoticePartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetNoticesByOffsetQuery,
  GetNoticesByOffsetQueryVariables
>
export const GetNoticeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'notice' },
            name: { kind: 'Name', value: 'getNotice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publicId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNoticeQuery, GetNoticeQueryVariables>
export const DeleteNoticeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteNotice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNotice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteNoticeMutation,
  DeleteNoticeMutationVariables
>
export const UpdateNoticeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateNoticeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNoticeMutation,
  UpdateNoticeMutationVariables
>
export const CreateProfessionalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProfessional' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProfessionalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProfessional' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfessionalParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProfessionalPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateProfessionalMutation,
  CreateProfessionalMutationVariables
>
export const GetAllProfessionalGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllProfessionalGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allGroups' },
            name: { kind: 'Name', value: 'getAllProfessionalGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllProfessionalGroupsQuery,
  GetAllProfessionalGroupsQueryVariables
>
export const GetProfessionalEarningsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfessionalEarningsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProfessionalEarningsFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'professionals' },
            name: { kind: 'Name', value: 'getProfessionalEarningsByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfessionalEarningsParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OffsetPageInfoPartsFragmentDoc.definitions,
    ...ProfessionalEarningsPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetProfessionalEarningsByOffsetQuery,
  GetProfessionalEarningsByOffsetQueryVariables
>
export const DownloadProfessionalEarningsExcelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadProfessionalEarningsExcel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'settlementId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadProfessionalEarningsExcel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settlementId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'settlementId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadProfessionalEarningsExcelQuery,
  DownloadProfessionalEarningsExcelQueryVariables
>
export const GetRelationshipsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRelationshipsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RelationshipFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'relationships' },
            name: { kind: 'Name', value: 'getRelationshipsByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RelationshipParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...RelationshipPartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetRelationshipsByOffsetQuery,
  GetRelationshipsByOffsetQueryVariables
>
export const GetSettlementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSettlement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SettlementFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSettlement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SettlementParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...SettlementPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetSettlementQuery, GetSettlementQueryVariables>
export const GetLatestSettlementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLatestSettlement' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'settlement' },
            name: { kind: 'Name', value: 'getLatestSettlement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SettlementParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...SettlementPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetLatestSettlementQuery,
  GetLatestSettlementQueryVariables
>
export const GetSettlementsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSettlementsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'settlements' },
            name: { kind: 'Name', value: 'getSettlementsByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SettlementWithEarningsParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OffsetPageInfoPartsFragmentDoc.definitions,
    ...SettlementWithEarningsPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetSettlementsByOffsetQuery,
  GetSettlementsByOffsetQueryVariables
>
export const CompleteSettlementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteSettlement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CompleteSettlementTransactionInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeSettlement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfTimes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteSettlementMutation,
  CompleteSettlementMutationVariables
>
export const UpdateSettlementDueDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSettlementDueDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSettlementDueDateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSettlementDueDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfTimes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSettlementDueDateMutation,
  UpdateSettlementDueDateMutationVariables
>
export const GetAdminHomeStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdminHomeStatistics' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'diseases' },
            name: { kind: 'Name', value: 'getDiseaseStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'medicalImagingTypes' },
            name: { kind: 'Name', value: 'getMedicalImagingTypeStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'users' },
            name: { kind: 'Name', value: 'getUserStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'patientCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'professionalCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'todaysNewUsersCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yesterdaysNewUsersCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rateOfRise' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newUsers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'certificates' },
            name: { kind: 'Name', value: 'getCertificateStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'certified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejected' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'examinations' },
            name: { kind: 'Name', value: 'getExaminationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiting' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interpretingStarted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interpretingFinished' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exceeded' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newExaminationsCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rateOfRise' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'todaysNewExaminationsCount' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'yesterdaysNewExaminationsCount',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newExaminations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'inquiries' },
            name: { kind: 'Name', value: 'getInquiryStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiting' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAdminHomeStatisticsQuery,
  GetAdminHomeStatisticsQueryVariables
>
export const UpdateUserSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>
export const GetUserSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserSetting' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'setting' },
            name: { kind: 'Name', value: 'getUserSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserSettingPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserSettingQuery, GetUserSettingQueryVariables>
export const SetUserToDiseasesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetUserToDiseases' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetUserToDiseasesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setUserToDiseases' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserToDiseaseParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserToDiseasePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  SetUserToDiseasesMutation,
  SetUserToDiseasesMutationVariables
>
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const AdminMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminMe' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserParts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'administrator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
    ...AdminPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AdminMeQuery, AdminMeQueryVariables>
export const PatientMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatientMe' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserParts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userToDiseases' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserToDiseaseParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relationship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RelationshipParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
    ...UserToDiseasePartsFragmentDoc.definitions,
    ...RelationshipPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PatientMeQuery, PatientMeQueryVariables>
export const ProfessionalMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfessionalMe' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserParts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'professional' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProfessionalParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BankAccountParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
    ...ProfessionalPartsFragmentDoc.definitions,
    ...BankAccountPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProfessionalMeQuery, ProfessionalMeQueryVariables>
export const GetUsersByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUsersByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'users' },
            name: { kind: 'Name', value: 'getUsersByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PatientUserParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PatientUserPartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetUsersByOffsetQuery,
  GetUsersByOffsetQueryVariables
>
export const GetProfessionalUsersByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfessionalUsersByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'users' },
            name: { kind: 'Name', value: 'getUsersByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfessionalUserParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProfessionalUserPartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetProfessionalUsersByOffsetQuery,
  GetProfessionalUsersByOffsetQueryVariables
>
export const GetAdministratorUsersByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdministratorUsersByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserSortBy' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'users' },
            name: { kind: 'Name', value: 'getUsersByOffset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AdministratorUserParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AdministratorUserPartsFragmentDoc.definitions,
    ...OffsetPageInfoPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetAdministratorUsersByOffsetQuery,
  GetAdministratorUsersByOffsetQueryVariables
>
export const GetProfessionalRegistrationsByOffsetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfessionalRegistrationsByOffset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserSortBy' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ProfessionalRegistrationFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'registrations' },
            name: {
              kind: 'Name',
              value: 'getProfessionalRegistrationsByOffset',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsetPageInfoParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfessionalUserParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OffsetPageInfoPartsFragmentDoc.definitions,
    ...ProfessionalUserPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetProfessionalRegistrationsByOffsetQuery,
  GetProfessionalRegistrationsByOffsetQueryVariables
>
export const GetUserByAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserByAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'getUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PatientUserParts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestExamination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examinationDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examinationToDiseases' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disease' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'examinationCounts' },
            name: { kind: 'Name', value: 'getExaminationCountsByUserId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiting' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interpreting' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invalid' } },
              ],
            },
          },
        ],
      },
    },
    ...PatientUserPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserByAdminQuery, GetUserByAdminQueryVariables>
export const GetProfessionalUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfessionalUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'professionalUser' },
            name: { kind: 'Name', value: 'getUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfessionalUserParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProfessionalUserPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetProfessionalUserQuery,
  GetProfessionalUserQueryVariables
>
export const GetAdministratorUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdministratorUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'administratorUser' },
            name: { kind: 'Name', value: 'getUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdministratorUserParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...AdministratorUserPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetAdministratorUserQuery,
  GetAdministratorUserQueryVariables
>
export const GetTotalUserCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTotalUserCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'getTotalUserCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTotalUserCountQuery,
  GetTotalUserCountQueryVariables
>
export const SetRelationshipWithUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetRelationshipWithUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetRelationshipWithUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setRelationshipWithUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relationship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RelationshipParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...RelationshipPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  SetRelationshipWithUserMutation,
  SetRelationshipWithUserMutationVariables
>
export const SetProfileImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetProfileImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetProfileImageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setProfileImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  SetProfileImageMutation,
  SetProfileImageMutationVariables
>
export const BlockUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BlockUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockUserMutation, BlockUserMutationVariables>
export const CheckPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CheckPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckPasswordMutation,
  CheckPasswordMutationVariables
>
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResetPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const RequestEmailVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestEmailVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RequestEmailVerificationCodeInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestEmailVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestEmailVerificationCodeMutation,
  RequestEmailVerificationCodeMutationVariables
>
export const ConfirmEmailVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmEmailVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ConfirmEmailVerificationCodeInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmEmailVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmEmailVerificationCodeMutation,
  ConfirmEmailVerificationCodeMutationVariables
>
export const RequestPhoneVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestPhoneVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RequestPhoneVerificationCodeInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPhoneVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestPhoneVerificationCodeMutation,
  RequestPhoneVerificationCodeMutationVariables
>
export const ConfirmPhoneVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmPhoneVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ConfirmPhoneVerificationCodeInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmPhoneVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmPhoneVerificationCodeMutation,
  ConfirmPhoneVerificationCodeMutationVariables
>
